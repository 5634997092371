// const data = require('./data')
var Model = require('./model');

var yup = require('yup');

var loanIntervals = ['annual', 'twice-yearly', 'quarterly', 'monthly'];
var definition = {
  dateOfBirth: yup.date().required(),
  // gender: yup.string().required().lowercase().oneOf(data.genders),
  isSmoker: yup.bool().required(),
  additionalInsuree: yup.bool().default(false),
  dateOfBirth2: yup.date().label('dateOfBirth').when('additionalInsuree', function (g, schema) {
    return g ? schema.required() : schema;
  }),
  // gender2: yup.string().label('gender').when('additionalInsuree', (g, schema) => g ? schema.required() : schema).lowercase().oneOf(data.genders),
  isSmoker2: yup.bool().label('isSmoker').when('additionalInsuree', function (g, schema) {
    return g ? schema.required() : schema;
  }),
  capital: yup.number().min(10000, {
    key: 'capital.min',
    value: 10000
  }).max(1000000, {
    key: 'capital.max',
    value: 1000000
  }).integer().required(),
  interval: yup.string().required().lowercase().oneOf(loanIntervals),
  effectiveDate: yup.date().required(),
  duration: yup.number().min(12, {
    key: 'loan.minInitial'
  }).max(480, {
    key: 'loan.maxInitial',
    value: 480 / 12
  }).required(),
  // max: update models/quotecalculatemodel.js
  interestRate: yup.number().required().min(0, {
    key: 'interestRate.min',
    value: 0
  }).max(15, {
    key: 'interestRate.max',
    value: 15
  }) // dataProcessingCheck: yup.bool().required().oneOf([true])

};
module.exports = new Model(definition);