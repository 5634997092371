import React from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { t, keyExists } from '../../../utils/helpers'

const Seo = (props) => {
  const location = useLocation()
  let title = 'Simplomus'

  if (props.pageName) {
    if (keyExists(`${props.pageName}.title_tag`)) {
      title += ` - ${t(`${props.pageName}.title_tag`)}`
    } else {
      if (keyExists(`${props.pageName}.title`)) {
        title += ` - ${t(`${props.pageName}.title`).replace(/(<([^>]+)>)/ig, '')}`
      }
    }
  }

  return (
    <Helmet>
      <title>{title}</title>
      <link rel='alternate' href={`${process.env.REACT_APP_URL}/`} hreflang='x-default' />
      {['fr', 'en'].map((locale) => {
        const url = location ? location.pathname.replace(/^\/(en|fr)/g, `/${locale}`) : `/${locale}`
        return (
          <link rel='alternate' href={`${process.env.REACT_APP_URL}${url}`} hrefLang={locale} key={locale} />
        )
      })}
      {location && (
        <link rel='canonical' href={`${process.env.REACT_APP_URL}${location.pathname}`} />
      )}
    </Helmet>
  )
}

export default Seo
