import React from 'react'
import { Select } from 'antd'
import { phonePrefixes } from './../../../utils/phonePrefixes'

const PhonePrefixSelect = ({ priority = null, ...props }) => {
  const sortedPrefixes = Object.keys(phonePrefixes).sort((a, b) => priority.includes(b) ? 1 : -1)

  return (
    <Select {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {sortedPrefixes.map(key => (
        <Select.Option key={key.toLowerCase()} value={key.toLowerCase()}>{phonePrefixes[key]}</Select.Option>
      ))}
    </Select>
  )
}

export default PhonePrefixSelect
