import { useState, useEffect, useCallback } from 'react'
import constate from 'constate'

function useSubscribeFormData () {
  const [subscribeFormData, setSubscribeFormData] = useState({})

  return { subscribeFormData, setSubscribeFormData }
}

export const [SubscribeFormProvider, useSubscribeFormContext] = constate(useSubscribeFormData)

function useSubscribeStep () {
  const [currentStep, setCurrentStep] = useState(0)
  const [progress, setProgress] = useState(0)
  const [steps, setSteps] = useState([])

  const next = useCallback(() => {
    setCurrentStep((currentStep) => currentStep + 1)
  }, [])

  const previous = useCallback(() => {
    setCurrentStep((currentStep) => currentStep - 1)
  }, [])

  const reset = () => {
    setCurrentStep(0)
    setProgress(0)
    setSteps([])
  }

  useEffect(() => {
    if (steps.length) {
      setProgress((currentStep) * (100 / (steps.length)))
    }
  }, [
    steps,
    currentStep,
    next,
    previous
  ])

  return { currentStep, steps, setSteps, progress, next, previous, reset }
}

export const [SubscribeStepProvider, useSubscribeStepContext] = constate(useSubscribeStep)
