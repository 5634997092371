import React from 'react'
import { useHistory } from 'react-router-dom'
import { availableLocales } from '../../i18n'
import i18next from 'i18next'

import { Menu, Dropdown, Button, Icon } from 'antd'
import { getCurrentLocale } from 'src/utils/helpers'
import { useLocaleSwitchContext } from '../../context/LocaleSwitchContext'
import { useTrackerContext } from '../../context/TrackerContext'

const LocaleSwitch = ({ ...props }) => {
  const history = useHistory()
  const currentLocale = getCurrentLocale()
  const { setLocaleSwitching } = useLocaleSwitchContext()
  const { trackEvent } = useTrackerContext()

  const handleLocaleChange = ({ key }) => {
    if (key === currentLocale) {
      return
    }

    const url = history.location.pathname
    const newUrl = url.replace(currentLocale, key)

    trackEvent('Language changed', { originalLanguage: currentLocale, newLanguage: key })
    history.push(newUrl)
    i18next.changeLanguage(key)
    setLocaleSwitching(false)
  }

  const handleVisibleChange = (visibility) => {
    setLocaleSwitching(visibility)
  }

  const localesOptions = availableLocales.map((locale) => {
    return <Menu.Item value={locale} key={locale}>{locale === 'fr' ? 'Français' : 'English'}</Menu.Item>
  })

  const menu = (
    <Menu onClick={handleLocaleChange} selectedKeys={currentLocale}>
      {localesOptions}
    </Menu>
  )

  return (
    <Dropdown className='LocaleSwitch' overlay={menu} trigger={['click']} onVisibleChange={handleVisibleChange}>
      <Button type={props.type ? props.type : null}>{currentLocale} <Icon type='down' /></Button>
    </Dropdown>
  )
}

export default LocaleSwitch
