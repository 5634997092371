import React from 'react'
import SubscriptionMedicalDeclaration from './SubscriptionMedicalDeclaration'

const SubscriptionMedicalDeclarationBis = ({ insuree, handlePreviousStep, handleNextStep }) => {
  return (
    <SubscriptionMedicalDeclaration insuree={insuree} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
  )
}

export default SubscriptionMedicalDeclarationBis
