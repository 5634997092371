import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import logo from './../../../images/simplomus.svg'
import { Row, Col, Button } from 'antd'
import { t, isDesktop, getCurrentLocale } from './../../../utils/helpers'
import throttle from 'lodash.throttle'

const Header = () => {
  const [open, setOpen] = useState(isDesktop())
  const currentLocale = getCurrentLocale()

  const handleMenuToggle = () => {
    setOpen(!open)
  }

  const handleOnClick = () => {
    if (!isDesktop()) {
      handleMenuToggle()
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if ((isDesktop() === false) && open) {
        setOpen(false)
      }
      if ((isDesktop() === true)) {
        setOpen(true)
      }
    }

    window.addEventListener('resize', throttle(handleResize, 300))

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [open])

  return (
    <div className='Header'>
      <div className='container'>
        <Row type='flex' align='middle'>
          <Col xs={12} sm={12} md={3} lg={2} xl={2}>
            <NavLink className='logo' to={`/${currentLocale}/`}>
              <img src={logo} alt='Simplomus Logo' />
            </NavLink>
          </Col>
          {open && (
            <Col className='text-right' xs={12} sm={12} md={9} lg={10} xl={10}>
              <nav>
                <NavLink to={`/${currentLocale}/adminus`} onClick={handleOnClick} exact>
                  {t('menu.admin.home')}
                </NavLink>
                <NavLink to={`/${currentLocale}/adminus/quotes`} onClick={handleOnClick} exact>
                  {t('menu.admin.quotes')}
                </NavLink>
                <NavLink to={`/${currentLocale}/adminus/subscriptions`} onClick={handleOnClick} exact>
                  {t('menu.admin.subscriptions')}
                </NavLink>

                <NavLink to={`/${currentLocale}/adminus/signout`}>
                  <Button type='primary' ghost>{t('menu.admin.logout')}</Button>
                </NavLink>
              </nav>
            </Col>
          )}
        </Row>
        <Button id='menuToggle' type='primary' icon='menu' size='large' onClick={handleMenuToggle} />
      </div>
    </div>
  )
}

export default Header
