import auth0 from 'auth0-js'
import moment from 'moment'

const options = Object.freeze({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
  responseType: 'id_token token'
})

const client = new auth0.WebAuth(Object.assign({}, options))

let user
const authToken = {
  expires: undefined,
  token: undefined,
  nonce: undefined
}

export function isAuthenticated () {
  return !!user
}

export function getUser () {
  return Object.freeze(Object.assign({}, user))
}

export function getToken () {
  return Object.freeze(Object.assign({}, authToken))
}

export function signIn () {
  client.authorize({
    redirectUri: getRedirectUri()
  })
}

export function signOut () {
  user = undefined
  Object.keys(authToken).forEach(k => {
    authToken[k] = undefined
  })

  client.logout({
    returnTo: process.env.REACT_APP_URL
  })
}

export function isAllowedUser () {
  let allowedUsers = []
  if (process.env.REACT_APP_ALLOWED_USERS) {
    allowedUsers = process.env.REACT_APP_ALLOWED_USERS.trim().split(',')
  }
  return allowedUsers.includes(user.sub)
}

// window.refreshToken = refreshToken

// export function refreshToken (cb) {

//   if (!user) {
//     cb('requires login')
//   }

//   client.checkSession(
//     Object.assign({ nonce: authToken.nonce, redirectUri: window.location.origin + '/authenticate', prompt: 'none' }, options),
//     function (err, authResult) {
//       console.log('refresh token', err, authResult)
//     })
// }

export function authenticate (cb) {
  client.parseHash({ hash: window.location.hash }, function (err, authResult) {
    if (err) {
      cb(err)
      return
    }

    Object.assign(authToken, {
      expires: moment().add(authResult.expiresIn, 'seconds'),
      token: authResult.accessToken,
      nonce: (authResult.idTokenPayload || {}).nonce
    })

    // console.log(authToken)
    // The contents of authResult depend on which authentication parameters were used.
    // It can include the following:
    // authResult.accessToken - access token for the API specified by `audience`
    // authResult.expiresIn - string with the access token's expiration time in seconds
    // authResult.idToken - ID token JWT containing user profile information

    client.client.userInfo(authResult.accessToken, function (err, usr) {
      if (!err) {
        user = usr
      }

      cb(err)
    })
  })
}

function getRedirectUri () {
  return `${window.location.origin}/authenticate?returnUrl=${window.location.pathname}${window.location.search}${window.location.hash}`
}
