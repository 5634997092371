import React from 'react'
import i18next from 'i18next'
import countries from 'i18n-iso-countries'
import currency from 'currency.js'
import { phonePrefixes } from './phonePrefixes'

export const t = i18next.getFixedT()
export const keyExists = (key) => i18next.exists(key)

export const MarkupEscape = (translationKey, options = null) => {
  const markup = createMarkup(translationKey, options)
  return <span dangerouslySetInnerHTML={markup} />
}

const createMarkup = (translationKey, options) => {
  if (typeof t(translationKey) === 'object') {
    return {
      __html: t(translationKey,
        {
          ...options && { ...options },
          interpolation: { escapeValue: false }
        }
      ).join('')
    }
  } else {
    return {
      __html: t(translationKey,
        {
          ...options && { ...options },
          interpolation: { escapeValue: false }
        }
      )
    }
  }
}

export const getCurrentLocale = () => {
  return i18next.languages[0]
}

export const getBreakpoint = (name = null) => {
  const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }

  if (name) {
    const filterdBreakpoint = Object.keys(breakpoints)
      .filter(key => key === name)
    return breakpoints[filterdBreakpoint]
  } else {
    return breakpoints
  }
}

export const getCurrentBreakpoint = () => {
  const width = document.body.clientWidth
  const breakpoints = getBreakpoint()
  let currentBreakpoint = 'xs'

  Object.values(breakpoints).forEach((value) => {
    if (width >= value) {
      currentBreakpoint = value
    }
  })

  return currentBreakpoint
}

export const isDesktop = () => {
  const currentBreakpoint = getCurrentBreakpoint()
  return currentBreakpoint !== 'xs' && currentBreakpoint !== 'sm'
}

export const generateWidth = (value) => {
  if (typeof value === 'number') {
    value = 'a'.repeat(value)
  }
  const fakeText = document.createElement('p')
  document.body.appendChild(fakeText)
  fakeText.style.fontSize = document.body.clientWidth >= getBreakpoint('sm') ? `${25.6}px` : `${19.2}px`
  fakeText.style.visibility = 'hidden'
  fakeText.style.height = 'auto'
  fakeText.style.width = 'auto'
  fakeText.style.position = 'absolute'
  fakeText.style.whiteSpace = 'no-wrap'
  fakeText.innerHTML = value

  const width = (Math.ceil(fakeText.clientWidth) + 30) + 15
  document.body.removeChild(fakeText)

  return width
}

export const secondsToCountDown = (value) => {
  const sec = parseInt(value, 10)
  let hours = Math.floor(sec / 3600)
  let minutes = Math.floor((sec - (hours * 3600)) / 60)
  let seconds = sec - (hours * 3600) - (minutes * 60)

  let timer = ''
  if (hours > 0) {
    if (hours < 10) {
      hours = `0${hours}`
    }
    timer += `${hours}:`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  timer += `${minutes}:`

  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  timer += `${seconds}`

  return timer
}

export const getCountryName = (countryCode) => {
  const currentLocale = getCurrentLocale()
  countries.registerLocale(require(`i18n-iso-countries/langs/${currentLocale}.json`))
  return countries.getName(countryCode.toUpperCase(), currentLocale)
}

export const getCountryPrefix = (countryCode) => {
  const prefix = phonePrefixes[countryCode.toUpperCase()]
  return prefix ? prefix : countryCode
}

export const currencyFormat = {
  options: {
    format: {
      separator: ' ',
      decimal: ',',
      precision: 2
    }
  },
  format: (input, format = null) => {
    format = format || currencyFormat.options.format
    if (!format) {
      format = currencyFormat.options.format
    }
    return currency(input, format).format()
  },
  raw: (input) => {
    return currency(input).value
  }
}
