import { useState } from 'react'
import constate from 'constate'

function useLocaleSwitch () {
  const [localeSwitching, setLocaleSwitching] = useState(false)

  return { localeSwitching, setLocaleSwitching }
}

export const [LocaleSwitchProvider, useLocaleSwitchContext] = constate(useLocaleSwitch)
