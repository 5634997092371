import React, { useState, useEffect } from 'react'
import { Button, Typography, Form, Radio, Input, Row, Col, Select, Upload, Switch, Icon, List, Spin } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { t, getCurrentLocale } from './../../../utils/helpers'
import ErrorMessage from './../common/ErrorMessage'
import CountrySelect from './CountrySelect'
import GoogleAddress from './GoogleAddress'
import PhonePrefixSelect from './PhonePrefixSelect'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from './../../../utils/subscribeFieldsModel'
import moment from 'moment'
const validationSchema = require('./../../../utils/validation')

const SubscriptionPersonalDetails = ({ insuree, handlePreviousStep, handleNextStep }) => {
  const currentLocale = getCurrentLocale()
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const [loading, setLoading] = useState(true)
  const [hasAddressError, setHasAddressError] = useState(false)
  const [hasFutureAddressError, setHasFutureAddressError] = useState(false)
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />
  const insureeIndex = insuree - 1
  const dateFormat = 'YYYY-MM-DD'
  const defaultValues = {
    ...generateStepData('personalDetails', subscribeFormData.insurees[insureeIndex]),
    personalIdFile: subscribeFormData.personalIdFile[insureeIndex]
  }
  if (insureeIndex === 1 && Object.values(subscribeFormData.insurees[1].address).includes('')) {
    defaultValues.address = subscribeFormData.insurees[0].address
  }
  const { Title } = Typography
  const { Dragger } = Upload

  const stepValidationSchema = insureeIndex === 0 ? validationSchema.subscribeStep3Validation : validationSchema.subscribeStep3BisValidation

  const { register, unregister, setValue, handleSubmit, errors, watch, control } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema: stepValidationSchema,
    defaultValues: defaultValues
  })

  const values = watch({ nest: true })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [])

  useEffect(() => {
    setHasAddressError(errors.address !== undefined)
    setHasFutureAddressError(errors.futureAddress !== undefined)
  }, [errors])

  const handleUpload = ({ file, onError, onSuccess }) => {
    setTimeout(() => {
      onSuccess()
      addFile(file)
    }, 300)

    onError(() => {
      removeFile()
    })
  }

  const addFile = (file) => {
    setValue('personalIdFile', file, true)
  }

  const removeFile = () => {
    setValue('personalIdFile', undefined, false)
  }

  const generateField = (propertyName, type = 'text') => {
    return (
      <>
        <Form.Item label={t(`forms.fields.${propertyName}.label`)}>
          <Controller
            as={Input}
            type={type}
            name={propertyName}
            control={control}
            defaultValue={defaultValues[propertyName]}
          />
        </Form.Item>
        <ErrorMessage className='mt-2' errors={errors} name={propertyName} />
      </>
    )
  }

  useEffect(() => {
    register({ name: 'personalIdFile' })
    register({ name: 'isPolicyHolder' })
    setValue('isPolicyHolder', defaultValues.isPolicyHolder, false)
  }, [
    register,
    setValue,
    defaultValues.isPolicyHolder
  ])

  useEffect(() => {
    if (!values.isMoving) {
      unregister('futureAddressAsOf')
      unregister('futureAddress')
    } else {
      register({ name: 'futureAddressAsOf' })
      register({ name: 'futureAddress' })
    }

    if (!['married', 'partnered'].includes(values.civilStatus)) {
      unregister('partnerName')
    } else {
      register('partnerName')
    }
  }, [
    register,
    unregister,
    values.isMoving,
    values.civilStatus
  ])

  const onSubmit = (values) => {
    setLoading(true)

    // Handling files
    const files = [...subscribeFormData.personalIdFile]
    files[insureeIndex] = values.personalIdFile
    delete values.personalIdFile

    // Handling addresses
    values.address = {
      ...values.address,
      address: `${values.address.streetNumber} ${values.address.streetName}, ${values.address.postalCode} ${values.address.city}`
    }

    if (insureeIndex === 0 && values.isMoving) {
      values.futureAddress = {
        ...values.futureAddress,
        address: `${values.futureAddress.streetNumber} ${values.futureAddress.streetName}, ${values.futureAddress.postalCode} ${values.futureAddress.city}`
      }
    }

    // Handling dates
    values.personalIdValidUntil = moment(values.personalIdValidUntil, dateFormat).utc(true).toISOString()
    if (insureeIndex === 0 && values.isMoving) {
      values.futureAddressAsOf = moment(values.futureAddressAsOf, dateFormat).utc(true).toISOString()
    }

    const insurees = [...subscribeFormData.insurees]
    insurees[insureeIndex] = {
      isPolicyHolder: values.isPolicyHolder,
      ...values
    }

    setSubscribeFormData({
      ...subscribeFormData,
      insurees: insurees,
      personalIdFile: files
    })
    handleNextStep()
  }

  return (
    <form className='SubscribePersonalDetails' onSubmit={handleSubmit(onSubmit)}>
      <div className='frame-content'>
        {loading
          ? (
            <div className='container loader-wrapper'>
              <Row type='flex' justify='center' align='middle'>
                <Spin indicator={icon} tip={t('global.loading')} />
              </Row>
            </div>
          )
          : (
            <>
              <div className='frame-section'>
                <Form.Item label={t('forms.fields.title.label')}>
                  <Controller
                    as={(
                      <Radio.Group>
                        {currentLocale === 'en' && (
                          <Radio.Button value='mrs'>{t('forms.fields.title.options.mrs')}</Radio.Button>
                        )}
                        <Radio.Button value='ms'>{t('forms.fields.title.options.ms')}</Radio.Button>
                        <Radio.Button value='mr'>{t('forms.fields.title.options.mr')}</Radio.Button>
                      </Radio.Group>
                    )}
                    size='small'
                    buttonStyle='solid'
                    defaultValue={defaultValues.title}
                    name='title'
                    onChange={([e]) => e.target.value}
                    control={control}
                  />
                </Form.Item>
                <ErrorMessage className='mt-2' errors={errors} name='title' />

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('lastName')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('firstName')}
                  </Col>
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('placeOfBirth')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.nationality.label')}>
                      <Controller
                        as={CountrySelect}
                        name='nationalityCode'
                        control={control}
                        defaultValue={defaultValues.nationalityCode}
                        priority={['lu', 'be', 'fr', 'de', 'nl']}
                        onChangeName='onSelect'
                        onChange={([e]) => e}
                      />
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} name='nationalityCode' />
                  </Col>
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('personalIdCardNumber')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.personalIdValidUntil.label')}>
                      <Controller
                        as={Input}
                        type='date'
                        name='personalIdValidUntil'
                        control={control}
                        defaultValue={(defaultValues.personalIdValidUntil !== '' && moment(defaultValues.personalIdValidUntil).isValid()) ? moment(defaultValues.personalIdValidUntil).format(dateFormat) : defaultValues.personalIdValidUntil}
                      />
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} name='personalIdValidUntil' />
                  </Col>
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.personalIdFile.label')}>
                      {values.personalIdFile
                        ? (
                          <List
                            className='file-uploaded'
                            itemLayout='horizontal'
                            dataSource={[values.personalIdFile]}
                            renderItem={(item) => (
                              <List.Item
                                actions={
                                  [<span key='delete' onClick={removeFile}><Icon type='delete' /></span>]
                                }
                              >
                                <List.Item.Meta
                                  description={item.name}
                                />
                              </List.Item>
                            )}
                          />
                        )
                        : (
                          <Dragger multiple={false} showUploadList={{ showDownloadIcon: false, showPreviewIcon: false }} customRequest={handleUpload} name='personalIdFile' onRemove={removeFile} defaultValue={defaultValues.personalIdFile} accept='.png, .jpg, .jpeg, .pdf, application/pdf, application/x-pdf, image/jpeg, image/pjpeg, image/x-citrix-jpeg, image/png'>
                            <p className='ant-upload-text'>{t('forms.fields.personalIdFile.hint')}</p>
                          </Dragger>
                        )}
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} name='personalIdFile' />
                  </Col>
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.civilStatus.label')}>
                      <Controller
                        as={(
                          <Select>
                            <Select.Option value='married'>{t('forms.fields.civilStatus.options.married')}</Select.Option>
                            <Select.Option value='partnered'>{t('forms.fields.civilStatus.options.partnered')}</Select.Option>
                            <Select.Option value='divorced'>{t('forms.fields.civilStatus.options.divorced')}</Select.Option>
                            <Select.Option value='single'>{t('forms.fields.civilStatus.options.single')}</Select.Option>
                            <Select.Option value='widowed'>{t('forms.fields.civilStatus.options.widowed')}</Select.Option>
                          </Select>
                        )}
                        name='civilStatus'
                        control={control}
                        defaultValue={defaultValues.civilStatus}
                        onChange={([e]) => e}
                        onChangeName='onSelect'
                        style={{ width: '100%' }}
                        placeholder={t('forms.fields.civilStatus.placeholder')}
                      />
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} name='civilStatus' />
                  </Col>
                  {['married', 'partnered'].includes(values.civilStatus) && (
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      {generateField('partnerName')}
                    </Col>
                  )}
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('profession')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.socialStatus.label')}>
                      <Controller
                        as={(
                          <Radio.Group>
                            <Radio.Button value='employee'>{t('forms.fields.socialStatus.options.employee')}</Radio.Button>
                            <Radio.Button value='self-employed'>{t('forms.fields.socialStatus.options.selfEmployed')}</Radio.Button>
                          </Radio.Group>
                        )}
                        size='small'
                        buttonStyle='solid'
                        defaultValue={defaultValues.socialStatus}
                        name='socialStatus'
                        onChange={([e]) => e.target.value}
                        control={control}
                      />
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} name='socialStatus' />
                  </Col>
                </Row>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('email')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.phoneNumber.label')}>
                      <Input.Group compact>
                        <Controller
                          as={PhonePrefixSelect}
                          defaultValue={defaultValues.phoneNumber.prefix}
                          name='phoneNumber.prefix'
                          onChange={([e]) => e}
                          onChangeName='onSelect'
                          control={control}
                          style={{ width: '30%' }}
                          priority={['lu', 'be', 'fr', 'de', 'nl']}
                        />
                        <Controller
                          as={Input}
                          defaultValue={defaultValues.phoneNumber.number}
                          name='phoneNumber.number'
                          onChange={([e]) => e.target.value}
                          control={control}
                          style={{ width: '70%' }}
                        />
                      </Input.Group>
                      {insuree === 1 && (
                        <p className='hint-info mt-2'><Icon type='warning' className='ant-typography ant-typography-danger mr-2' />{t('forms.fields.phoneNumber.hint')}</p>
                      )}
                    </Form.Item>
                    <ErrorMessage className='mt-2' errors={errors} parent='phoneNumber' name='number' />
                  </Col>
                </Row>
              </div>

              <div className='frame-section'>
                <Title level={4}>{t('forms.steps.subscribe.personalDetails.sections.address')}</Title>
                <Controller
                  as={GoogleAddress}
                  name='address'
                  label={t('forms.fields.googleAddress.label')}
                  control={control}
                  manualMode={hasAddressError}
                  defaultValue={defaultValues.address}
                  value={values.address}
                  restrictions={['lu']}
                  onChangeName='onAddressUpdate'
                  onChange={([e]) => e}
                  errors={errors}
                />
              </div>
              {insuree === 1 && (
                <div className='frame-section'>
                  <Title level={4}>{t('forms.steps.subscribe.personalDetails.sections.futureAddress')}</Title>

                  <Form.Item style={{ display: 'flex', alignItems: 'center' }}>
                    <Controller
                      as={Switch}
                      name='isMoving'
                      control={control}
                      defaultChecked={defaultValues.isMoving}
                      checkedChildren={<Icon type='check' />}
                      unCheckedChildren={<Icon type='close' />}
                      valueName='checked'
                      style={{ marginRight: '15px' }}
                    />
                    <p onClick={() => setValue('isMoving', !values.isMoving, false)} style={{ cursor: 'pointer', display: 'inline-block' }}>{t('forms.fields.moving.label')}</p>
                  </Form.Item>
                  <ErrorMessage className='mt-3' errors={errors} name='isMoving' />

                  {values.isMoving && (
                    <>
                      <Row type='flex' align='top'>
                        <Col xs={12} md={6}>
                          <Form.Item label={t('forms.fields.futureAddressAsOf.label')}>
                            <Controller
                              as={Input}
                              type='date'
                              name='futureAddressAsOf'
                              control={control}
                              defaultValue={defaultValues.futureAddressAsOf === '' ? defaultValues.futureAddressAsOf : moment(defaultValues.futureAddressAsOf).format(dateFormat)}
                            />
                          </Form.Item>
                          <ErrorMessage className='mt-3' errors={errors} name='futureAddressAsOf' />
                        </Col>
                      </Row>
                      <Row type='flex' align='top'>
                        <Col xs={12}>
                          <Controller
                            as={GoogleAddress}
                            name='futureAddress'
                            label={t('forms.fields.googleAddress.label')}
                            control={control}
                            manualMode={hasFutureAddressError}
                            defaultValue={defaultValues.futureAddress}
                            value={values.futureAddress}
                            restrictions={['lu']}
                            onChangeName='onAddressUpdate'
                            onChange={([e]) => e}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button onClick={handlePreviousStep}>{t('global.previous')}</Button>
          <Button type='primary' htmlType='submit' style={{ marginLeft: 15 }}>{t('global.next')}</Button>
        </div>
      )}
    </form>
  )
}

export default SubscriptionPersonalDetails
