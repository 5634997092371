import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { t, getCurrentLocale } from './../../../utils/helpers'

const PageCTA = () => {
  const { Title } = Typography
  const currentLocale = getCurrentLocale()
  return (
    <section>
      <div className='container text-center'>
        <Title level={3} className='mb-4'>{t('commons.pageCTA.title')}</Title>
        <Link to={`/${currentLocale}/quote`}>
          <Button type='primary' size='large' className='mt-3'>{t('commons.pageCTA.link')}</Button>
        </Link>
      </div>
    </section>
  )
}

export default PageCTA
