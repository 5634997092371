import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Icon, Radio, Spin, Input } from 'antd'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import { t, MarkupEscape, keyExists } from './../../../utils/helpers'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from './../../../utils/subscribeFieldsModel'
const validationSchema = require('./../../../utils/validation')

const SubscriptionGoodHealthDeclaration = ({ handlePreviousStep, handleNextStep }) => {
  const [loading, setLoading] = useState(false)
  const [consent, setConsent] = useState(false)
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const hasAdditionalInsuree = subscribeFormData.additionalInsuree
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />
  const defaultValues = {
    goodHealthDeclaration: [
      {
        ...generateStepData('goodHealthDeclaration', subscribeFormData.goodHealthDeclaration[0])
      },
      ...(hasAdditionalInsuree ? [{
        ...generateStepData('goodHealthDeclaration', subscribeFormData.goodHealthDeclaration[1])
      }] : [])
    ]
  }

  const stepValidationSchema = validationSchema.subscribeStep5Validation

  const { control, register, formState, setValue, handleSubmit, errors, watch } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    validationSchema: stepValidationSchema,
    defaultValues: defaultValues
  })

  const values = watch({ nest: true })

  useEffect(() => {

    // Set validation dependency fields
    register({ name: 'additionalInsuree' })
    setValue('additionalInsuree', hasAdditionalInsuree, false)

    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [
    hasAdditionalInsuree,
    register,
    setValue
  ])

  const questionSize = {
    xs: 12,
    sm: 12,
    md: hasAdditionalInsuree ? 7 : 9,
    lg: hasAdditionalInsuree ? 7 : 9,
    xl: hasAdditionalInsuree ? 7 : 9
  }

  const answerSize = {
    xs: 12,
    sm: 12,
    md: hasAdditionalInsuree ? 5 : 3,
    lg: hasAdditionalInsuree ? 5 : 3,
    xl: hasAdditionalInsuree ? 5 : 3
  }

  const radioSize = hasAdditionalInsuree ? 6 : 12

  const onSubmit = (data) => {
    setLoading(true)
    setSubscribeFormData({
      ...subscribeFormData,
      goodHealthDeclaration: data.goodHealthDeclaration
    })
    handleNextStep()
  }

  const generateField = (propertyName) => {
    return (
      <>
        <Row className='mb-4' type='flex' align='middle'>
          <Col xs={questionSize.xs} sm={questionSize.sm} md={questionSize.md} lg={questionSize.lg} xl={questionSize.xl}>
            <p className='mb-0 primary text-small'>{t(`forms.steps.subscribe.goodHealthDeclaration.questions.${propertyName}`)}</p>
          </Col>
          <Col className='mt-3 mt-md-0' xs={answerSize.xs} sm={answerSize.sm} md={answerSize.md} lg={answerSize.lg} xl={answerSize.xl}>
            <Row type='flex' align='middle'>
              <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Controller
                  as={(<Radio.Group buttonStyle='solid' size='small'>
                    <Radio.Button value={true}>
                      {t('global.TRUE')}
                    </Radio.Button>
                    <Radio.Button value={false}>
                      {t('global.FALSE')}
                    </Radio.Button>
                  </Radio.Group>)}
                  value={values.goodHealthDeclaration[0][propertyName]}
                  name={`goodHealthDeclaration[0][${propertyName}]`}
                  onChange={([e]) => e.target.value}
                  control={control}
                />
              </Col>

              {hasAdditionalInsuree && (
                <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Controller
                    as={(<Radio.Group buttonStyle='solid' size='small'>
                      <Radio.Button value={true}>
                        {t('global.TRUE')}
                      </Radio.Button>
                      <Radio.Button value={false}>
                        {t('global.FALSE')}
                      </Radio.Button>
                    </Radio.Group>)}
                    value={values.goodHealthDeclaration[1][propertyName]}
                    name={`goodHealthDeclaration[1][${propertyName}]`}
                    onChange={([e]) => e.target.value}
                    control={control}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <div className='mt-2'>
          <ErrorMessage errors={errors} name={`goodHealthDeclaration[0][${propertyName}]`}>
            {() => <p className='mb-1 ErrorMessage'>{t('forms.errors.mixed.default')}</p>}
          </ErrorMessage>
          <ErrorMessage errors={errors} name={`goodHealthDeclaration[1][${propertyName}]`}>
            {() => <p className='mb-1 ErrorMessage'>{t('forms.errors.mixed.default')}</p>}
          </ErrorMessage>
        </div>
      </>
    )
  }

  useEffect(() => {
    let hasConsent = false
    if (values.goodHealthDeclaration) {
      hasConsent = !Object.values(values.goodHealthDeclaration[0]).includes('')
      if (hasAdditionalInsuree) {
        hasConsent = !Object.values(values.goodHealthDeclaration[1]).includes('')
      }
    }
    setConsent(hasConsent)
  }, [
    watch,
    hasAdditionalInsuree,
    values.goodHealthDeclaration
  ])

  const fields = {
    height: (
      <>
        <Row className='mb-4' type='flex' align='middle'>
          <Col xs={questionSize.xs} sm={questionSize.sm} md={questionSize.md} lg={questionSize.lg} xl={questionSize.xl}>
            <p className='mb-0 primary text-small'>{t('forms.fields.height.label')}</p>
          </Col>
          <Col className='mt-3 mt-md-0' xs={answerSize.xs} sm={answerSize.sm} md={answerSize.md} lg={answerSize.lg} xl={answerSize.xl}>
            <Row type='flex' align='middle'>
              <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Controller
                  as={Input}
                  name='goodHealthDeclaration[0].height'
                  control={control}
                  defaultValue={defaultValues.goodHealthDeclaration[0].height}
                  addonAfter='cm'
                />
              </Col>

              {hasAdditionalInsuree && (
                <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Controller
                    as={Input}
                    name='goodHealthDeclaration[1].height'
                    control={control}
                    defaultValue={defaultValues.goodHealthDeclaration[1].height}
                    addonAfter='cm'
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <ErrorMessage errors={errors} name='goodHealthDeclaration[0].height'>
          {({ message }) => <p className='mb-1 ErrorMessage'>{keyExists(`forms.errors.${message.key}`) ? MarkupEscape(`forms.errors.${message.key}`, { value: message.value }) : t('forms.errors.mixed.default')}</p>}
        </ErrorMessage>
        <ErrorMessage errors={errors} name='goodHealthDeclaration[1].height'>
          {({ message }) => <p className='mb-1 ErrorMessage'>{keyExists(`forms.errors.${message.key}`) ? MarkupEscape(`forms.errors.${message.key}`, { value: message.value }) : t('forms.errors.mixed.default')}</p>}
        </ErrorMessage>
      </>
    ),
    weight: (
      <>
        <Row className='mb-4' type='flex' align='middle'>
          <Col xs={questionSize.xs} sm={questionSize.sm} md={questionSize.md} lg={questionSize.lg} xl={questionSize.xl}>
            <p className='mb-0 primary text-small'>{t('forms.fields.weight.label')}</p>
          </Col>
          <Col className='mt-3 mt-md-0' xs={answerSize.xs} sm={answerSize.sm} md={answerSize.md} lg={answerSize.lg} xl={answerSize.xl}>
            <Row type='flex' align='middle'>
              <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Controller
                  as={Input}
                  name='goodHealthDeclaration[0].weight'
                  control={control}
                  defaultValue={defaultValues.goodHealthDeclaration[0].weight}
                  addonAfter='kg'
                />
              </Col>

              {hasAdditionalInsuree && (
                <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Controller
                    as={Input}
                    name='goodHealthDeclaration[1].weight'
                    control={control}
                    defaultValue={defaultValues.goodHealthDeclaration[1].weight}
                    addonAfter='kg'
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <ErrorMessage errors={errors} name='goodHealthDeclaration[0].weight'>
          {({ message }) => <p className='mb-1 ErrorMessage'>{keyExists(`forms.errors.${message.key}`) ? MarkupEscape(`forms.errors.${message.key}`, { value: message.value }) : t('forms.errors.mixed.default')}</p>}
        </ErrorMessage>
        <ErrorMessage errors={errors} name='goodHealthDeclaration[1].weight'>
          {({ message }) => <p className='mb-1 ErrorMessage'>{keyExists(`forms.errors.${message.key}`) ? MarkupEscape(`forms.errors.${message.key}`, { value: message.value }) : t('forms.errors.mixed.default')}}</p>}
        </ErrorMessage>
      </>
    )
  }

  return (
    <form className='SubscribeGoodHealthDeclaration' onSubmit={handleSubmit(onSubmit)}>
      <div className='frame-content'>
        {loading ? (
          <div className='container loader-wrapper'>
            <Row type='flex' justify='center' align='middle'>
              <Spin indicator={icon} tip={t('global.loading')} />
            </Row>
          </div>
        ) : (
            <>
              <div className='frame-section'>
                {hasAdditionalInsuree && (
                  <Row className='mb-4' type='flex' align='bottom'>
                    <Col xs={questionSize.xs} sm={questionSize.sm} md={questionSize.md} lg={questionSize.lg} xl={questionSize.xl}>&nbsp;</Col>
                    <Col xs={answerSize.xs} sm={answerSize.sm} md={answerSize.md} lg={answerSize.lg} xl={answerSize.xl}>
                      <Row type='flex' align='middle'>
                        <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <h6>{t('global.you')}</h6>
                        </Col>
                        <Col xs={radioSize} sm={radioSize} md={radioSize} lg={radioSize} xl={radioSize} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <h6>{t('global.yourPartner')}</h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {fields.height}
                {fields.weight}
                {generateField('isNotUnderIncapacityOfWork')}
                {generateField('isNotUnderTreatment')}
                {generateField('hasNoSevereDisease')}
                {generateField('hasNoHospitalization')}
              </div>
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button onClick={handlePreviousStep}>{t('global.previous')}</Button>
          <Button type='primary' htmlType='submit' disabled={!consent || formState.isSubmitting} style={{ marginLeft: 15 }}>{t('global.next')}</Button>
        </div>
      )}
    </form>
  )
}

export default SubscriptionGoodHealthDeclaration
