import React, { useState, useEffect } from 'react'
import { AutoComplete, Form, Switch, Icon, Row, Col, Input } from 'antd'
import { t, getCountryName } from './../../../utils/helpers'
import ErrorMessage from './../common/ErrorMessage'

const GoogleAddress = ({
  onAddressUpdate,
  label,
  name,
  errors,
  value = {},
  defaultValue = {},
  manualMode = false,
  restrictions = null
}) => {
  const { Option } = AutoComplete
  const [displayGoogleAddress, setDisplayGoogleAddress] = useState(true)
  const [suggestions, setSuggestions] = useState([])
  const defaultData = {
    address: value.address || defaultValue.address,
    streetNumber: value.streetNumber || defaultValue.streetNumber,
    streetName: value.streetName || defaultValue.streetName,
    city: value.city || defaultValue.city,
    postalCode: value.postalCode || defaultValue.postalCode,
    country: getCountryName(value.countryCode) || getCountryName(defaultValue.countryCode),
    countryCode: value.countryCode || defaultValue.countryCode
  }
  const [data, setData] = useState({ ...defaultData })
  const addressModel = {
    street_number: 'streetNumber',
    route: 'streetName',
    locality: 'city',
    postal_code: 'postalCode',
    country: 'country'
  }

  useEffect(() => {

  }, [errors])

  const onInputChange = (name, value) => {
    const newData = {
      ...data,
      [name]: value
    }

    setData(newData)
    onAddressUpdate(newData)
  }

  const handleSwitchGoogleAddress = () => {
    setDisplayGoogleAddress(!displayGoogleAddress)
  }

  const autocomplete = new window.google.maps.places.AutocompleteService()
  const handleSearch = (value) => {
    const request = {
      input: value,
      types: ['geocode'],
      componentRestrictions: {
        ...restrictions && { country: restrictions }
      }
    }
    autocomplete.getPlacePredictions(request, (results, status) => {
      setSuggestions(results)
    })
  }

  const handleSelect = (value) => {
    const geocoder = new window.google.maps.Geocoder()
    const values = {
      ...defaultData
    }
    geocoder.geocode({ placeId: value }, (results, status) => {
      if (status === 'OK') {
        const result = results[0].address_components
        result.forEach(addressElement => {
          if (Object.keys(addressModel).includes(addressElement.types[0])) {
            values[addressModel[addressElement.types[0]]] = addressElement.long_name

            if (addressElement.types[0] === 'country') {
              values.countryCode = addressElement.short_name
            }
          }
        })
        values.address = results[0].formatted_address
      } else {
        setDisplayGoogleAddress(false)
        values.address = ''
      }
      const newData = {
        ...defaultData,
        ...values
      }

      setData(newData)
      onAddressUpdate(newData)
      if (Object.values(values).includes('')) {
        setDisplayGoogleAddress(false)
      }
    })
  }

  useEffect(() => {
    if (manualMode) {
      setDisplayGoogleAddress(false)
    }
  }, [manualMode])

  useEffect(() => {
    if (!window.google || !window.google.maps.places) {
      throw new Error(
        'Google Maps API library must be loaded.'
      )
    }
  })

  const fields = {
    address: (
      <Form.Item label={label}>
        <AutoComplete onSearch={handleSearch} defaultValue={data.address} onSelect={handleSelect}>
          {suggestions && suggestions.length > 0 && (
            suggestions.map(suggestion => <Option key={suggestion.id} value={suggestion.place_id}>{suggestion.description}</Option>)
          )}
        </AutoComplete>
      </Form.Item>
    ),
    streetNumber: (
      <Form.Item label={t('forms.fields.streetNumber.label')} style={{ display: displayGoogleAddress ? 'none' : 'block' }}>
        <Input name='streetNumber' disabled={displayGoogleAddress} value={data.streetNumber} onChange={e => { onInputChange(e.target.name, e.target.value) }} />
      </Form.Item>
    ),
    streetName: (
      <Form.Item label={t('forms.fields.streetName.label')} style={{ display: displayGoogleAddress ? 'none' : 'block' }}>
        <Input name='streetName' disabled={displayGoogleAddress} value={data.streetName} onChange={e => { onInputChange(e.target.name, e.target.value) }} />
      </Form.Item>
    ),
    postalCode: (
      <Form.Item label={t('forms.fields.postalCode.label')} style={{ display: displayGoogleAddress ? 'none' : 'block' }}>
        <Input name='postalCode' disabled={displayGoogleAddress} value={data.postalCode} onChange={e => { onInputChange(e.target.name, e.target.value) }} />
      </Form.Item>
    ),
    city: (
      <Form.Item label={t('forms.fields.city.label')} style={{ display: displayGoogleAddress ? 'none' : 'block' }}>
        <Input name='city' disabled={displayGoogleAddress} value={data.city} onChange={e => { onInputChange(e.target.name, e.target.value) }} />
      </Form.Item>
    ),
    country: (
      <Form.Item label={t('forms.fields.country.label')} style={{ display: displayGoogleAddress ? 'none' : 'block' }}>
        <Input type='text' name='countryCode' disabled value={data.country} onChange={e => { onInputChange(e.target.name, e.target.value) }} />
      </Form.Item>
    )
  }

  return (
    <>
      <Row type='flex'>
        {displayGoogleAddress && (
          <Col span={12} sm={12} md={12} lg={12} xl={12}>
            <Row type='flex' align='top'>
              <Col span={12} sm={12} md={12} lg={12} xl={12}>
                {fields.address}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={12} sm={12} md={6} lg={6} xl={6}>
          <Row type='flex' align='top'>
            <Col span={12} sm={3} md={3} lg={3} xl={3}>
              {fields.streetNumber}
            </Col>
            <Col span={12} sm={9} md={9} lg={9} xl={9}>
              {fields.streetName}
            </Col>
            {(errors[name]?.streetNumber || errors[name]?.streetName) && (
              <Col span={12}>
                <ul className='errors-wrapper list-unstyled'>
                  {errors[name]?.streetNumber && (<li className='error'><ErrorMessage errors={errors} parent={name} name='streetNumber' /></li>)}
                  {errors[name]?.streetName && (<li className='error'><ErrorMessage errors={errors} parent={name} name='streetName' /></li>)}
                </ul>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={12} sm={12} md={6} lg={6} xl={6}>
          <Row type='flex' align='top'>
            <Col span={12} sm={4} md={4} lg={4} xl={4}>
              {fields.postalCode}
            </Col>
            <Col span={12} sm={8} md={8} lg={8} xl={8}>
              {fields.city}
            </Col>
            {(errors[name]?.postalCode || errors[name]?.city) && (
              <Col span={12}>
                <ul className='errors-wrapper list-unstyled'>
                  {errors[name]?.postalCode && (<li className='error'><ErrorMessage errors={errors} parent={name} name='postalCode' /></li>)}
                  {errors[name]?.city && (<li className='error'><ErrorMessage errors={errors} parent={name} name='city' /></li>)}
                </ul>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={12} sm={12} md={6} lg={6} xl={6}>
          {fields.country}
        </Col>
        {(errors[name]?.countryCode) && (
          <Col span={12}>
            <ul className='errors-wrapper list-unstyled'>
              {errors[name]?.countryCode && (<li className='error'><ErrorMessage errors={errors} parent={name} name='countryCode' /></li>)}
            </ul>
          </Col>
        )}
      </Row>
      {displayGoogleAddress && (
        <Form.Item style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            onChange={handleSwitchGoogleAddress}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            checked={displayGoogleAddress}
            style={{ marginRight: '15px' }}
          />
          <p onClick={handleSwitchGoogleAddress} style={{ cursor: 'pointer', display: 'inline-block' }}>{t('forms.fields.GoogleLookup.label')}</p>
        </Form.Item>
      )}
    </>
  )
}

export default GoogleAddress
