import React from 'react'
import Seo from '../../common/Seo'
import { Row, Col, Typography } from 'antd'
import { t, MarkupEscape } from 'src/utils/helpers'

const LegalPage = () => {
  const { Title, Text } = Typography

  return (
    <>
      <Seo pageName='legal' />
      <section className='pb-0'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Title>{t('legal.title')}</Title>

              <div className='frame pb-5'>
                <div className='frame-inner'>
                  <div className='frame-content'>
                    {Object.values(t('legal.steps', { returnObjects: true })).map((content, index) => (
                      <>
                        <div className={index > 0 ? 'step mt-5' : 'step'} key={index}>
                          {content.title && (
                            <Title level={3}>{MarkupEscape(content.title)}</Title>
                          )}
                          {content.text && (
                            <Text>{MarkupEscape(content.text.join(''))}</Text>
                          )}
                          {content.substeps && Object.values(content.substeps).map(subcontent => (
                            <>
                              {subcontent.title && (
                                <Title level={4}>{t(subcontent.title)}</Title>
                              )}
                              {subcontent.text && (
                                <Text>{MarkupEscape(subcontent.text.join(''))}</Text>
                              )}
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default LegalPage
