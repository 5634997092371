var Model = require('./model');

var yup = require('yup');

var schemas = {
  goodHealthDeclaration: require('./schemas/GoodHealthDeclaration').schema,
  medicalDeclaration: require('./schemas/MedicalDeclaration').schema,
  personal: require('./schemas/PersonalDetails').schema
};
var definition = Object.assign({
  offerId: yup.string().ensure().required(),
  code: yup.string().ensure().required(),
  dataVerifiedConsent: yup.bool().required().default(false).oneOf([true]),
  confirmedIpid: yup.bool().required().default(false).oneOf([true]),
  plan: yup.string().ensure().required().lowercase().oneOf(['once', 'monthly', 'yearly']),
  additionalInsuree: yup.bool().required(),
  insurees: yup.array().required().of(schemas.personal).when('additionalInsuree', minFormsCount),
  // beneficiary
  beneficiary: yup.string().required().lowercase().oneOf(['by-law', 'other']),
  beneficiaryOther: yup.string().max(100).when('beneficiary', function (v, s) {
    return v === 'other' ? s.required() : s;
  }),
  existingContract: yup.bool().required(),
  existingContractPolicyNumber: yup.string().max(100).when('existingContract', function (v, s) {
    return v ? s.required() : s;
  }),
  existingContract2: yup.bool().when('insurees', function (v, s) {
    return v && v.length === 2 ? s.required() : s;
  }),
  existingContract2PolicyNumber: yup.string().max(100).when('existingContract2', function (v, s) {
    return v ? s.required() : s;
  }),
  // benefit
  benefitTransferInstitution: yup.string().max(100).ensure().required(),
  benefitTransferFileNumber: yup.string().max(100).ensure().required(),
  benefitTransferAddress: yup.string().max(200).ensure().required(),
  // bank account details
  bankAccountHolder: yup.string().max(100).ensure().required(),
  bankAccountIban: yup.string().max(34).ensure().required(),
  bankAccountBic: yup.string().min(8).max(11).ensure().required(),
  goodHealthDeclaration: yup.array().required().of(schemas.goodHealthDeclaration).when('additionalInsuree', minFormsCount),
  // medicalDeclaration: yup.array().required().of(schemas.medicalDeclaration).when('additionalInsuree', minFormsCount),
  medicalDeclaration: yup.array().default([]).of(schemas.medicalDeclaration),
  cancelsOtherInsurerContract: yup.bool().required().default(false),
  guaranteesMortgageLoan: yup.bool().required().default(false),
  correctDataCheck: yup.bool().required().default(false).oneOf([true]),
  termsCheck: yup.bool().required().default(false).oneOf([true]),
  dataProtectionCheck: yup.bool().required().default(false).oneOf([true]),
  dataProcessingCheck: yup.bool().required().default(false).oneOf([true]),
  intermediaryMandateCheck: yup.bool().required().default(false).oneOf([true]),
  emailCommunicationCheck: yup.bool().required().default(false).oneOf([true])
});
module.exports = new Model(definition);

function minFormsCount(v, s) {
  return v ? s.min(2).max(2) : s.min(1).max(1);
}