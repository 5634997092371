import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import * as auth from './AuthenticationUtil'

export default function Authenticate () {
  const [state, setState] = useState({
    isAuthenticated: undefined,
    returnUrl: undefined
  })

  useEffect(() => {
    auth.authenticate(function (err) {
      const returnUrl = getReturnUrl()
      if (err || !returnUrl) {
        setState({
          isAuthenticated: false
        })
        return
      }

      setState({
        isAuthenticated: true,
        returnUrl
      })
    })
  }, [])

  switch (state.isAuthenticated) {
    case true:
      return <Redirect to={state.returnUrl} />
    case false:
      return <Redirect to='/' />
    default:
      return <></>
  }

}

function getReturnUrl () {
  return window.location.search.slice(1).split('&').reduce((acc, m) => {
    let val = `${m}`.split('=')
    acc[val.shift()] = val.join('=')
    return acc
  }, {})['returnUrl']
}
