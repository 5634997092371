import React from 'react'
import Seo from '../common/Seo'
import { Link } from 'react-router-dom'
import { Row, Col, Typography, Button } from 'antd'
import PageCTA from './../common/PageCTA'
import { t, MarkupEscape, getCurrentLocale } from '../../../utils/helpers'
import hero from './../../../images/hero.svg'
import cardif from './../../../images/cardif_boxed.svg'
import vrb from './../../../images/vrb_boxed.svg'
import paperLess from './../../../images/paper-free.svg'
import privacy from './../../../images/privacy.svg'
import trust from './../../../images/trust.svg'
import fast from './../../../images/fast.svg'
import price from './../../../images/price.svg'
import offer from './../../../images/offer.svg'
import advices from './../../../images/advices.svg'
import customize from './../../../images/customize.svg'
import user from './../../../images/user.svg'

const Homepage = () => {
  const { Title, Text, Paragraph } = Typography
  const currentLocale = getCurrentLocale()

  const icons = {
    whySimplomus: [
      paperLess,
      offer,
      price,
      fast,
      privacy,
      advices,
      user,
      customize,
      trust
    ]
  }

  const getIcon = (section, index) => {
    return icons[section][index] ? icons[section][index] : null
  }

  return (
    <>
      <Seo pageName='home' />
      <section>
        <div className='container'>
          <Row type='flex' align='middle'>
            <Col xs={12} lg={6} xl={5}>
              <Title className='mb-3'>{MarkupEscape('home.title')}</Title>
              <Paragraph>
                <Text className='hero-lead'>{t('home.subtitle')}</Text>
              </Paragraph>
              <Link to={`/${currentLocale}/quote`}>
                <Button type='primary' size='large' className='mt-3'>{t('home.cta')}</Button>
              </Link>
            </Col>

            <Col xs={12} lg={6} xl={{ span: 6, offset: 1 }} style={{ position: 'relative' }}>
              <img style={{ zIndex: 1, position: 'relative' }} src={hero} alt='Illustration' />
              <div className='blob' />
            </Col>
          </Row>
        </div>
      </section>
      <section className='light'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col md={12} xl={10}>
              <Title level={2} className='text-center mb-4 mb-md-5'>{t('home.sections.0.title')}</Title>
              <Row gutter={{ md: 70 }}>
                {t('home.sections.0.columns', { returnObjects: true }).map((column, index) => (
                  <Col key={index} className='mt-3 mt-md-5' md={12} lg={6}>
                    {column.title && (
                      <h5 className='mb-3 mb-md-4'>{column.title}</h5>
                    )}
                    {column.text && (
                      <Paragraph>
                        <Text className='lead'><span dangerouslySetInnerHTML={{ __html: column.text }} /></Text>
                      </Paragraph>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
      <section className='secondary-light'>
        <div className='container'>
          <Title level={2} className='text-center mb-4 mb-md-5'>{t('home.sections.1.title')}</Title>
          <Row type='flex' style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {t('home.sections.1.columns', { returnObjects: true }).map((column, index) => (
              <Col key={index} className='mt-3 mt-md-5' xs={12} md={6} xl={4}>
                {getIcon('whySimplomus', index) && (
                  <img className='mb-3 mb-md-2' src={getIcon('whySimplomus', index)} alt={column.title ? column.title : ''} />
                )}
                {column.title && (
                  <h5 className='mb-3 mb-md-4'>{column.title}</h5>
                )}
                {column.text && (
                  <Paragraph>
                    <Text><span dangerouslySetInnerHTML={{ __html: column.text }} /></Text>
                  </Paragraph>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <section className='light'>
        <div className='container'>
          <Title level={2} className='text-center mb-4 mb-md-5'>{t('home.sections.2.title')}</Title>
          <Row className='pt-lg-5' type='flex' justify='center'>
            <Col md={12} xl={10}>
              <Row type='flex' align='middle'>
                <Col md={12} lg={8}>
                  <h5>{t('home.sections.2.columns.0.title')}</h5>
                  {MarkupEscape('home.sections.2.columns.0.text', { returnObjects: true })}
                </Col>
                <Col md={12} lg={4}>
                  <img className='mt-3 img-fluid' src={vrb} alt='Vanbreda Risk &amp; Benefits Logo' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mt-5' type='flex' justify='center'>
            <Col md={12} xl={10}>
              <Row type='flex' align='middle'>
                <Col className='order-xs-1 order-lg-2' md={12} lg={8}>
                  <h5>{t('home.sections.2.columns.1.title')}</h5>
                  {MarkupEscape('home.sections.2.columns.1.text', { returnObjects: true })}
                </Col>
                <Col className='order-xs-2 order-lg-1' md={12} lg={4}>
                  <img className='mt-3 img-fluid' src={cardif} alt='Cardif Logo' />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <PageCTA />
    </>
  )
}

export default Homepage
