import React, { useState, useEffect, useCallback } from 'react'
import Api from '../../../utils/Api'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Col, Descriptions, Dropdown, Collapse, Empty, Icon, List } from 'antd'
import { Menu, message, PageHeader, Row, Spin, Table, Tabs, Tag, Timeline, Tooltip, Typography, Upload, Modal } from 'antd'
import { SubscriptionStatusTag } from '../subscription-status-tag'
import ShowMore from '../common/ShowMore'
import { t } from './../../../utils/helpers'
import { isAllowedUser } from './../../../utils/AuthenticationUtil'
import moment from 'moment'

const { confirm } = Modal
const { Panel } = Collapse
const dateFormat = 'DD.MM.YYYY'
const dateTimeFormat = 'DD.MM.YYYY HH:mm'

export default AdminSubscriptionPage

export function AdminSubscriptionPage () {

  //const currentLocale = getCurrentLocale()
  const currentLocale = 'en'
  const history = useHistory()
  const [subscription, setSubscription] = useState(undefined)
  const [hasMedicalData, setHasMedicalData] = useState(false)
  const [values, setValues] = useState({})
  const { subscriptionId } = useParams()

  useEffect(() => {
    Api.get(`admin/subscriptions/${subscriptionId}`)
      .then(res => {
        setSubscription(res)
        setValues(values => ({
          ...values,
          ...(res.policyNumber && { policyNumber: res.policyNumber }),
          ...(res.effectiveDate && { effectiveDate: res.effectiveDate })
        }))
        if (
          res.policyHolder?.medicalDeclaration
          || res.policyHolder?.goodHealthDeclaration
          || res?.additionalInsuree?.medicalDeclaration
          || res?.additionalInsuree?.goodHealthDeclaration
        ) {
          setHasMedicalData(true)
        }
      })
      .catch(() => setSubscription(null))
  }, [subscriptionId])

  const handleFieldUpdate = (field, value, type = null) => {
    let isValid = type === null

    if (type === 'date') {
      isValid = moment(value, dateFormat, true).isValid()

      if (isValid) {
        value = moment(value, dateFormat).utc(true).toISOString()
      }
    }

    isValid = value !== ''

    if (value === values[field]) {
      return
    }

    if (isValid) {
      value = value.replace('#', '')
      Api.put(`admin/subscriptions/${subscriptionId}`, {
        ...subscription,
        [field]: value
      }).then(subscription => {
        message.success(`Field "${field}" has been updated successfully.`, 2)
        setSubscription(subscription)
        setValues(values => ({
          ...values,
          [field]: value
        }))
      })
    } else {
      message.error(`Field "${field}" is not valid`, 2)
    }
  }

  const handleChangeStatus = (subscription, status) => {
    if (status === subscription.status) {
      return
    }

    const prevStatus = subscription.status

    Api.put(`admin/subscriptions/${subscription._id}`, {
      ...subscription,
      status: status
    }).then(subscription => {
      message.success(`Status successfully changed from "${t(`admin.status.${prevStatus}`)}" to "${t(`admin.status.${status}`)}".`, 2)
      setSubscription(subscription)
    })
  }

  return (
    <section>
      <div className='container'>
        <div className="panel">
          {subscription ? (
            <SubscriptionDetails
              subscription={subscription}
              setSubscription={setSubscription}
              subscriptionId={subscriptionId}
              handleFieldUpdate={handleFieldUpdate}
              handleChangeStatus={handleChangeStatus}
              history={history}
              locale={currentLocale}
              setHasMedicalData={setHasMedicalData}
              hasMedicalData={hasMedicalData}
            />
          ) : (
              subscription === undefined ? <Loading /> : <NotFound locale={currentLocale} history={history} />
            )
          }
        </div>
      </div>
    </section>
  )

}

function SubscriptionDetails ({ subscription, subscriptionId, handleFieldUpdate, handleChangeStatus, history, locale, setHasMedicalData, hasMedicalData }) {

  return (
    <>
      <PageHeader
        onBack={() => history.push(`/${locale}/adminus/subscriptions`)}
        className="px-0"
        title={
          <Typography.Title level={3}>
            {t(`forms.fields.title.options.${subscription.policyHolder.title}`)} {subscription.policyHolder.lastName}, {subscription.policyHolder.firstName}
          </Typography.Title>
        }
        extra={<small><b>{t('admin.subscription.header.createdAt')}</b> {moment(subscription.createdAt).format(dateTimeFormat)}</small>}
      >
        <Row className='mb-3'>
          <Col xs={12} md={6} lg={2}>
            <h6>{t('admin.subscription.header.reference')}</h6>
            <Typography.Paragraph style={{ fontSize: '1.1rem' }} className='mb-0' copyable>{subscription.referenceNumber}</Typography.Paragraph>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <h6>{t('admin.subscription.header.policyNumber')}</h6>
            <div style={{ position: 'relative' }}>
              <Typography.Paragraph
                className='mb-0 field-policyNumber'
                style={{ fontSize: '1.1rem', paddingLeft: '1rem' }}
                editable={{ onChange: (value) => handleFieldUpdate('policyNumber', value) }}
              >
                {subscription.policyNumber ? subscription.policyNumber : <span style={{ opacity: '0.5' }}>{t('admin.subscription.header.addPolicyNumber')}</span>}
              </Typography.Paragraph>
            </div>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <h6>{t('admin.subscription.header.effectiveDate')}</h6>
            <Typography.Paragraph style={{ fontSize: '1.1rem' }} className='mb-0' editable={{ onChange: (value) => handleFieldUpdate('effectiveDate', value, 'date') }}>
              {subscription.effectiveDate ? moment(subscription.effectiveDate).format(dateFormat) : 'n/a'}
            </Typography.Paragraph>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <h6>{t('admin.subscription.header.status')}</h6>
            <div className='d-flex align-vertical-center'>
              <SubscriptionStatusTag status={subscription.status} size='small' />
              <SubscriptionChangeStatus subscription={subscription} setStatus={handleChangeStatus} />
            </div>
          </Col>
          {hasMedicalData && (
            <Col xs={12} md={6} lg={2}>
              <h6>{t('admin.subscription.header.actions')}</h6>
              <div className='d-flex align-vertical-center'>
                <SubscriptionActions subscription={subscription} setHasMedicalData={setHasMedicalData} hasMedicalData={hasMedicalData} />
              </div>
            </Col>
          )}
        </Row>
      </PageHeader>
      <Tabs animated={false} >
        <Tabs.TabPane tab={t('admin.subscription.tabs.overview')} key="overview">
          <OverviewTab subscription={subscription} handleFieldUpdate={handleFieldUpdate} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('admin.subscription.tabs.insuree')} key="insuree">
          <InsureeDetailsTab subscriptionId={subscriptionId} subscription={subscription} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('admin.subscription.tabs.documents')} key="documents">
          <DocumentsTab subscriptionId={subscriptionId} subscription={subscription} />
        </Tabs.TabPane>
        {isAllowedUser() && hasMedicalData && (
          <Tabs.TabPane tab={t('admin.subscription.tabs.medical')} key="medical">
            <MedicalTab subscriptionId={subscriptionId} subscription={subscription} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={t('admin.subscription.tabs.events')} key="Events">
          <>
            <Typography.Title level={4}>Events</Typography.Title>
            {!subscription.logs.length && <Empty />}
            {!!subscription.logs.length &&
              <Timeline>
                {subscription.logs.reverse().map(l => <Timeline.Item key={l._id}>{moment(l.createdAt).format(dateTimeFormat)}  {l.message}</Timeline.Item>)}
              </Timeline>
            }
          </>
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

function SubscriptionChangeStatus ({ subscription, setStatus }) {
  const [visible, setVisible] = useState(false)
  const StatusList = () => {

    const availableStatus = [
      'active',
      'pending',
      'suspended',
      'needsMedicalExam',
      'cancelled',
      'rejectedByInsurer'
    ]

    return (
      <Menu onClick={(value) => { setStatus(subscription, value.key); setVisible(false) }} defaultSelectedKeys={subscription.status}>
        {availableStatus.map(status => (
          <Menu.Item key={status}>
            {t(`admin.status.${status}`)}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <Dropdown overlay={<StatusList />} visible={visible} trigger={['click']}>
      <Tooltip placement='top' title='Change status'>
        <Button onClick={() => setVisible(!visible)} size='small'><Icon type='ellipsis' /></Button>
      </Tooltip>
    </Dropdown>
  )
}

function SubscriptionActions ({ subscription, setHasMedicalData, hasMedicalData }) {
  const [loading, setLoading] = useState(false)
  const [medicalConfirmVisible, setMedicalConfirmVisible] = useState(false)
  const isAllowed = isAllowedUser()

  const ref = subscription.referenceNumber || '00000000'

  const handleExport = () => {
    setLoading(true)
    const hide = message.loading('Preparing export...', 0)
    Api.post(`admin/subscriptions/${subscription._id}/download-files`, undefined, { isBlob: true })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `simplomus_forms_${ref}.zip`
        document.body.appendChild(a)
        a.click()
        a.remove()
        hide()
        message.success('The contract was successfully exported.', 3)
      })
      .catch(error => {
        console.log(error)
        hide()
        message.error('There was an error while exporting the file.', 3)
      })
      .finally(() => setLoading(false))
  }

  const handleMedicalDataDeletion = () => {
    if (isAllowed) {
      setLoading(true)
      const hide = message.loading('Deleting medical data...', 0)
      setMedicalConfirmVisible(false)
      Api.post(`admin/subscriptions/${subscription._id}/clear-medical-data`)
        .then(() => {
          hide()
          setTimeout(() => {
            message.success('Medical data was successfully deleted.', 3)
            setHasMedicalData(false)
          }, 500)
        })
        .catch(() => {
          hide()
          setTimeout(() => {
            message.error('There was an error while deleting the medical data.', 3)
          }, 500)
        })
        .finally(() => setLoading(false))
    } else {
      message.error('You are not allowed to perform this action', 3)
    }
  }

  const menu = (
    <Menu>
      <Menu.Item disabled={loading} onClick={handleExport}>Download forms</Menu.Item>
      {isAllowed && hasMedicalData && (
        <Menu.Item disabled={loading} onClick={() => setMedicalConfirmVisible(true)}>Delete medical data</Menu.Item>
      )}
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu}>
        <Button size='small' style={{ fontSize: '.75rem' }}>
          Select action... <Icon type='down' />
        </Button>
      </Dropdown>
      <Modal
        title='Are you sure?'
        visible={medicalConfirmVisible}
        footer={[
          <Button key='cancel' onClick={() => setMedicalConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="ok" type='primary' style={{ backgroundColor: '#FF4D50', borderColor: '#FF4D50', textShadow: 'none' }} onClick={handleMedicalDataDeletion}>
            Delete now
          </Button>,
        ]}
      >
        <p>This action will permanently and irrevocably delete all medical data for this contract. Please make sure that the exported PDFs reflect the client's data exactly as shown here in the backend.</p>
      </Modal>
    </>
  )
}

function OverviewTab ({ subscription, handleFieldUpdate }) {
  return (
    <>
      <Row>
        <Col md={6}>
          <Descriptions title="Insurance" size="small" column={1} bordered className="mb-4">
            <Descriptions.Item label="Capital">{`${subscription.capital} €`}</Descriptions.Item>
            <Descriptions.Item label="Premium">{`${subscription.premium || 'n/a'} €`}</Descriptions.Item>
            <Descriptions.Item label="Premium payment Interval">{`${subscription.plan || 'n/a'}`}</Descriptions.Item>
            <Descriptions.Item label="Effective Date">
              <Typography.Paragraph
                className='mb-0'
                editable={{ onChange: (value) => handleFieldUpdate('effectiveDate', value, 'date') }}
              >
                {`${moment(subscription.effectiveDate).format(dateFormat)}`}
              </Typography.Paragraph>
            </Descriptions.Item>
            <Descriptions.Item label="Coverage end Date">{`${moment(subscription.effectiveDate).add(subscription.duration, 'months').format(dateFormat)}`}</Descriptions.Item>
            <Descriptions.Item label="Product">Hypo Protect {subscription.additionalInsuree ? '2win' : 'Classic'}</Descriptions.Item>
            <Descriptions.Item label="Guarantee">{t('forms.fields.guarantee.value')}</Descriptions.Item>
            <Descriptions.Item label="Premium Type">{t('forms.fields.premiumType.value')}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col md={6}>
          <Descriptions title='Loan' size='small' column={1} bordered className='mb-4'>
            <Descriptions.Item label="Capital">{subscription.capital} €</Descriptions.Item>
            <Descriptions.Item label="Duration">{subscription.duration} months</Descriptions.Item>
            <Descriptions.Item label="Annual interest rate">{subscription.interestRate} %</Descriptions.Item>
            <Descriptions.Item label="Repayment interval">{subscription.interval}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Descriptions title="Other" size="small" column={1} bordered className="mb-4">
            <Descriptions.Item label="Insuree 1 has existing contract with Cardif">{subscription.meta.existingContract ? `yes (policy # ${subscription.meta.existingContractPolicyNumber})` : 'no'}</Descriptions.Item>
            <Descriptions.Item label="Insuree 2 has existing contract with Cardif">{subscription.meta.existingContract2 ? `yes (policy # ${subscription.meta.existingContract2PolicyNumber})` : 'no'}</Descriptions.Item>
            <Descriptions.Item label="This cancels a life insurance with another insurer">{subscription.meta.cancelOtherContract ? 'yes' : 'no'}</Descriptions.Item>
            <Descriptions.Item label="This insurance is used to guarantee a mortgage loan">{subscription.meta.guaranteesMortgageLoan ? 'yes' : 'no'}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col md={6}>
          <Descriptions title="Bank Account" size="small" column={1} bordered className="mb-4">
            <Descriptions.Item label="Bank Account Holder">{`${subscription.meta.bankAccountHolder}`}</Descriptions.Item>
            <Descriptions.Item label="IBAN">{`${subscription.meta.bankAccountIban || 'n/a'}`}</Descriptions.Item>
            <Descriptions.Item label="BIC">{`${subscription.meta.bankAccountBic || 'n/a'}`}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Descriptions title="Beneficiary" size="small" column={1} bordered className="mb-4">
        <Descriptions.Item label="Beneficiary">{t(`forms.fields.beneficiary.options.${subscription.meta.beneficiary === 'by-law' ? 'byLaw' : subscription.meta.beneficiary}`) || 'n/a'}</Descriptions.Item>
        <Descriptions.Item label="Transfer Insitution">{`${subscription.meta.benefitTransferInstitution || 'n/a'}`}</Descriptions.Item>
        <Descriptions.Item label="Transfer Address">{`${subscription.meta.benefitTransferAddress || 'n/a'}`}</Descriptions.Item>
        <Descriptions.Item label="File Number">{`${subscription.meta.benefitTransferFileNumber || 'n/a'}`}</Descriptions.Item>
      </Descriptions>
    </>
  )
}

const SendContract = ({ subscriptionId, subscription }) => {

  const send = () => {
    if (!subscription.contract) {
      return
    }

    const hideLoadingMessage = message.loading('Sending...', 0)
    Api.post(`admin/subscriptions/${subscriptionId}/contract/send`)
      .then(() => {
        setTimeout(() => {
          hideLoadingMessage()
          message.success('Contract sent to the client successfully', 3)
        }, 1000)
      })
      .catch(error => {
        console.log(error)
        setTimeout(() => {
          hideLoadingMessage()
          message.error('There was an error while sending the contract to the client. Please try again.', 3)
        }, 1000)
      })
  }

  const showConfirm = () => {

    if (subscription.policyHolder && subscription.status === 'active') {
      confirm({
        title: t('admin.subscription.documents.contract.modal.title'),
        content: t('admin.subscription.documents.contract.modal.text'),
        onOk: send,
        okText: t('admin.subscription.documents.contract.modal.okBtn'),
        cancelText: t('admin.subscription.documents.contract.modal.cancelBtn')
      })
    } else {
      if (!subscription.policyNumber) {
        message.error('You need to add a Policy number in order to send the contract to the client.', 3)
      } else if (subscription.status !== 'active') {
        message.error('Subscription needs to be active in order to send the contract to the client.', 3)
      }
    }
  }

  let tooltip = t('admin.subscription.documents.contract.actions.send')
  if (!subscription.policyNumber) {
    tooltip = 'Policy Number needed.'
  } else if (subscription.status !== 'active') {
    tooltip = 'Status needs to be active.'
  }

  return (
    <>
      <Tooltip placement='top' title={tooltip}>
        <Button disabled={!subscription.policyNumber || subscription.status !== 'active'} onClick={showConfirm} type='primary' shape='circle' icon='mail' />
      </Tooltip>
    </>
  )
}

const ViewContract = ({ subscriptionId, file }) => {
  const [fileUrl, setFileUrl] = useState(null)

  const fetchUrl = useCallback(async () => {
    return Api.get(`admin/subscriptions/${subscriptionId}/contract`)
      .then(response => response.url)
      .catch(error => {
        console.log(error)
        return null
      })
  }, [subscriptionId])

  useEffect(() => {
    let didCancel = false
    fetchUrl()
      .then(url => (!didCancel ? setFileUrl(url) : null))

    return () => {
      didCancel = true
    }
  }, [
    fetchUrl,
    file
  ])

  return (
    <>
      {fileUrl && (
        <Tooltip placement="top" title={t('admin.subscription.documents.contract.actions.view')}>
          <Button href={fileUrl} target='_blank' rel='noopener noreferrer' type='primary' shape="circle" icon='eye' />
        </Tooltip>
      )}
    </>
  )
}

const UploadContract = ({ subscriptionId, subscription, onUploadComplete, uploading, tooltip = false }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [fileList, setFileList] = useState(subscription.contract ? [{
    uid: subscription.contract._id,
    name: subscription.contract.filename,
    status: 'done'
  }] : [])

  useEffect(() => {
    uploading(isUploading)
    return () => {
      uploading(false)
    }
  }, [
    uploading,
    isUploading
  ])

  const onError = (msg = null) => {
    setIsUploading(false)
    if (msg) {
      message.error(msg, 3)
    }
  }

  const uploadProps = {
    action: `${process.env.REACT_APP_API_URL}/admin/subscriptions/${subscriptionId}/contract/upload`,
    name: 'contract',
    multiple: false,
    headers: {
      'Accept': 'application/json',
      ...Api.getBearerToken()
    },
    beforeUpload: () => {
      setIsUploading(true)
    },
    onChange: ({ file, fileList }) => {
      if (subscription.status === 'active') {
        if (file.status !== 'uploading') {
          if (file.status === 'done') {
            if (fileList.length > 1) {
              fileList = [fileList[fileList.length - 1]]
              message.success('The existing contract has been successfully replaced.', 3)
            } else {
              message.success('The contract has been successfully uploaded.', 3)
            }
            onUploadComplete(file.response.contract)
          } else if (file.status === 'error') {
            if (fileList.length > 1) {
              fileList = [fileList[0]]
              message.error('There was an error while uploading the contract. The existing contract has not been replaced.', 3)
            } else {
              fileList = []
              message.error('There was an error while uploading the contract.', 3)
            }
          }
          setIsUploading(false)
        }
        setFileList([...fileList])
      } else {
        onError('Subscription needs to be active to upload a contract.')
      }
    },
    showUploadList: false,
    defaultFileList: fileList,
    fileList: fileList
  }

  return (
    tooltip ? (
      <Upload {...uploadProps}>
        <Tooltip placement="top" title={subscription.status === 'active' ? t('admin.subscription.documents.contract.actions.replace') : 'Status needs to be active'}>
          <Button disabled={subscription.status !== 'active'} type='primary' shape="circle" icon='upload' />
        </Tooltip>
      </Upload>
    ) : (
        <Upload {...uploadProps}>
          <Button disabled={subscription.status !== 'active'} type='primary' loading={isUploading}>{isUploading ? 'Uploading...' : t('admin.subscription.documents.contract.actions.upload')}</Button>
        </Upload>
      )
  )
}

function DocumentsTab ({ subscriptionId, subscription }) {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(subscription.contract)
  const [hasFile, setHasFile] = useState(file ? true : false)

  const handleLoading = useCallback((loading) => {
    setLoading(loading)
  }, [])

  const handleOnComplete = (file) => {
    setFile(file)
    setTimeout(() => {
      if (!hasFile) {
        setHasFile(true)
      }
    }, 100)
  }

  return (
    <>
      <Typography.Title level={4}>Documents</Typography.Title>

      <div className='mb-4'>
        <h5>Contract</h5>
        {hasFile ? (
          <List
            size='large'
            bordered
            loading={loading}
            dataSource={[file]}
            renderItem={item => (
              <List.Item className='contract-list-item' actions={[
                <ViewContract subscriptionId={subscriptionId} file={file} />,
                <SendContract subscriptionId={subscriptionId} subscription={subscription} />,
                <UploadContract subscriptionId={subscriptionId} subscription={subscription} uploading={handleLoading} onUploadComplete={handleOnComplete} tooltip />
              ]}>
                <List.Item.Meta
                  title={<><Icon className='mr-2' type='file-protect' /> {item.filename}</>}
                  description={<small>Updated at: {moment(item.updatedAt).format(dateTimeFormat)}</small>}
                />
              </List.Item>
            )}
          />
        ) : (
            <List
              size='large'
              bordered>
              <List.Item actions={[
                <UploadContract subscriptionId={subscriptionId} subscription={subscription} uploading={handleLoading} onUploadComplete={handleOnComplete} />
              ]}>
                {subscription.status === 'active' ? 'There is no contract for this subscription' : 'Subscription needs to be active to upload a contract.'}
              </List.Item>
            </List>
          )}
      </div>
      <div>
        <h5>Medical Exams</h5>
        <Row>
          <Col xs={12} lg={6}>
            <InsureeMedicalDocumentsTable title="Insuree 1" insuree={subscription.policyHolder} />
          </Col>
          <Col xs={12} lg={6}>
            <InsureeMedicalDocumentsTable title="Insuree 2" insuree={subscription.additionalInsuree} />
          </Col>
        </Row>
      </div>
    </>
  )

}

function InsureeMedicalDocumentsTable ({ title, insuree }) {
  if (!insuree) {
    return null
  }

  const exams = Object.keys(insuree.medicalExams || {}).reduce((acc, key) => {
    acc.push(Object.assign({
      key
    }, insuree.medicalExams[key]))
    return acc
  }, [])

  const columns = [{
    title: 'Medical exam',
    dataIndex: 'label'
  }, {
    title: 'Status',
    key: 'status',
    render: exam => <MedicalExamStatusTag isCompleted={exam.isCompleted} isRequired={exam.required} />
  }]

  return <Table title={() => title} rowKey="key" size="small" pagination={false} dataSource={exams} columns={columns} />

}

function InsureeDetailsTab ({ subscription, subscriptionId }) {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <InsureeDetails title="Insuree 1" subscriptionId={subscriptionId} insuree={subscription.policyHolder} policyHolder />
      </Col>
      {subscription.additionalInsuree && (
        <Col xs={12} lg={6}>
          <InsureeDetails title="Insuree 2" subscriptionId={subscriptionId} insuree={subscription.additionalInsuree} />
        </Col>
      )}
    </Row>
  )
}

function InsureeDetails ({ title, insuree, subscriptionId, policyHolder = false }) {
  const [idCardFileUrl, setIdCardFileUrl] = useState()

  useEffect(() => {
    let isCanceled = false
    if (!isCanceled && subscriptionId) {
      Api.get(`admin/subscriptions/${subscriptionId}/${policyHolder ? 'policy-holder' : 'additional-insuree'}/id-card`)
        .then(({ url }) => setIdCardFileUrl(url))
        .catch(error => console.log(error))
    }
    return (() => {
      isCanceled = true
    })
  }, [
    policyHolder,
    subscriptionId
  ])

  return (
    <>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Descriptions title="Personal Details" column={1} size="small" bordered className="mb-4">
        <Descriptions.Item label="Title">{insuree.title}</Descriptions.Item>
        <Descriptions.Item label="First Name">{insuree.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{insuree.lastName}</Descriptions.Item>
        <Descriptions.Item label="Date Of Birth">{moment(insuree.dateOfBirth).format(dateFormat)}</Descriptions.Item>
        <Descriptions.Item label="Civil Status">{insuree.civilStatus}</Descriptions.Item>
        <Descriptions.Item label="Profession">{insuree.profession}</Descriptions.Item>
        <Descriptions.Item label="Social Status">{insuree.socialStatus}</Descriptions.Item>
        <Descriptions.Item label="Nationality">{insuree.nationality}</Descriptions.Item>
        <Descriptions.Item label="Place of Birth">{insuree.placeOfBirth}</Descriptions.Item>
        <Descriptions.Item label="Smoker">{insuree.isSmoker ? 'Yes' : 'No'}</Descriptions.Item>
      </Descriptions>

      <Descriptions title="Contact details" column={1} size="small" bordered className="mb-4">
        <Descriptions.Item label="Email">{insuree.email ? <a href={`mailto:${insuree.email}`}>{insuree.email}</a> : 'n/a'}</Descriptions.Item>
        <Descriptions.Item label="Phone">{insuree.phoneNumber || 'n/a'}</Descriptions.Item>
      </Descriptions>

      <Descriptions title="Address" column={1} size="small" bordered className="mb-4">
        <Descriptions.Item label="Street Name">{insuree.streetName}</Descriptions.Item>
        <Descriptions.Item label="Street Number">{insuree.streetNumber}</Descriptions.Item>
        <Descriptions.Item label="Postal Code">{insuree.postalCode}</Descriptions.Item>
        <Descriptions.Item label="City">{insuree.city}</Descriptions.Item>
        <Descriptions.Item label="Country">{insuree.country}</Descriptions.Item>
      </Descriptions>

      {insuree.futureAddress &&
        <Descriptions
          className="mb-4"
          title={
            insuree.futureAddressAsOf
              ? `Future address as of ${moment(insuree.futureAddressAsOf).format(dateFormat)}`
              : "Future address"
          }
          column={1}
          size="small"
          bordered
        >
          <Descriptions.Item label="Street Name">{insuree.futureAddress.streetName}</Descriptions.Item>
          <Descriptions.Item label="Street Number">{insuree.futureAddress.streetNumber}</Descriptions.Item>
          <Descriptions.Item label="Postal Code">{insuree.futureAddress.postalCode}</Descriptions.Item>
          <Descriptions.Item label="City">{insuree.futureAddress.city}</Descriptions.Item>
          <Descriptions.Item label="Country">{insuree.futureAddress.country}</Descriptions.Item>
        </Descriptions>
      }

      <Descriptions title="Identification" column={1} size="small" bordered className="mb-4">
        {idCardFileUrl && (
          <Descriptions.Item label="Id Card">
            <a href={idCardFileUrl} target='_blank' rel='noopener noreferrer'><Icon type="file-image" className="mr-1" theme="twoTone" /> Id card</a>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Id Card Number">{insuree.personalIdCardNumber}</Descriptions.Item>
        <Descriptions.Item label="Id Valid Until">{moment(insuree.personalIdValidUntil).format(dateFormat)}</Descriptions.Item>
      </Descriptions>
    </>
  )
}

const GoodHealthDeclaration = ({ insuree, additionalInsuree = false }) => {
  if (!insuree.goodHealthDeclaration) {
    return null
  }

  const questions = Object.entries(insuree.goodHealthDeclaration).map(([key, value]) => {
    if (key === 'height') {
      return (
        <Descriptions.Item key={additionalInsuree ? `additionalInsuree-${key}` : `policyHolder-${key}`} label={t(`forms.steps.subscribe.goodHealthDeclaration.questions.${key}`)}>{value.toString().toUpperCase()} cm</Descriptions.Item>
      )
    } else if (key === 'weight') {
      return (
        <Descriptions.Item key={additionalInsuree ? `additionalInsuree-${key}` : `policyHolder-${key}`} label={t(`forms.steps.subscribe.goodHealthDeclaration.questions.${key}`)}>{value.toString().toUpperCase()} kg</Descriptions.Item>
      )
    } else {
      return (
        <Descriptions.Item key={additionalInsuree ? `additionalInsuree-${key}` : `policyHolder-${key}`} label={t(`forms.steps.subscribe.goodHealthDeclaration.questions.${key}`)}>{t(`global.${value.toString().toUpperCase()}`)}</Descriptions.Item>
      )
    }
  })

  return (
    <Descriptions title={additionalInsuree ? 'Insuree 2' : 'Insuree 1'} size='small' column={1} className='mb-4' bordered>{questions}</Descriptions>
  )
}

const MedicalDeclaration = ({ insuree, additionalInsuree = false }) => {

  return (
    <List
      className='medical-declaration-list'
      header={(additionalInsuree ? <h6>Insuree 2</h6> : <h6>Insuree 1</h6>)}
      itemLayout='horizontal'
      dataSource={[insuree.medicalDeclaration]}
      renderItem={(item) => {
        return Object.entries(item)
          .filter(([key]) => !key.includes('Explanation') && !key.includes('StartDate'))
          .map(([key, value]) => (
            <List.Item
              key={additionalInsuree ? `additionalInsuree-${key}` : `policyHolder-${key}`}
            >
              <List.Item.Meta
                title={t(`forms.fields.${key}.pdfLabel`)}
                description={insuree.medicalDeclaration[`${key}Explanation`] ? <ShowMore text={insuree.medicalDeclaration[`${key}Explanation`]} previewLength={200} /> : null}
              />
              {value === true
                ? insuree.medicalDeclaration[`${key}StartDate`]
                  ? `${t('global.since')} ${moment(insuree.medicalDeclaration[`${key}StartDate`]).format(dateFormat)}`
                  : t('global.true')
                : typeof value === 'boolean' ? t(`global.${value.toString()}`) : value}
            </List.Item>
          ))
      }}
    />
  )
}

const MedicalTab = ({ subscription }) => {

  const activeKeys = ['1']
  if (subscription.policyHolder?.medicalDeclaration || subscription.additionalInsuree?.medicalDeclaration) {
    activeKeys.push('2')
  }

  return (
    <Collapse defaultActiveKey={activeKeys}>
      {(subscription.policyHolder?.goodHealthDeclaration || subscription.additionalInsuree?.goodHealthDeclaration) && (
        <Panel header={t('admin.subscription.medical.goodHealthDeclaration.title')} key="1">
          <Row>
            {subscription.policyHolder?.goodHealthDeclaration && (
              <Col md={subscription.additionalInsuree ? 6 : 12}>
                <GoodHealthDeclaration insuree={subscription.policyHolder} />
              </Col>
            )}

            {subscription.additionalInsuree?.goodHealthDeclaration && (
              <Col md={6}>
                <GoodHealthDeclaration insuree={subscription.additionalInsuree} additionalInsuree />
              </Col>
            )}
          </Row>
        </Panel>
      )}
      {(subscription.policyHolder?.medicalDeclaration || subscription.additionalInsuree?.medicalDeclaration) && (
        <Panel header={t('admin.subscription.medical.medicalDeclaration.title')} key="2">
          <Row>
            {subscription.policyHolder?.medicalDeclaration && (
              <Col md={subscription.additionalInsuree ? 6 : 12}>
                <MedicalDeclaration insuree={subscription.policyHolder} />
              </Col>
            )}

            {(subscription.additionalInsuree?.medicalDeclaration) && (
              <Col md={subscription.policyHolder?.medicalDeclaration ? 6 : 12}>
                <MedicalDeclaration insuree={subscription.additionalInsuree} additionalInsuree />
              </Col>
            )}
          </Row>
        </Panel>
      )}
    </Collapse>
  )
}

function Loading () {
  return (
    <div className='text-center'>
      <Spin tip="Finding the subscription..." />
    </div>
  )
}

function NotFound ({ locale, history }) {
  return <>
    <Alert
      className="mb-4"
      message="Subscription not found"
      description="We could not find the subscription details"
      type="error"
      showIcon
    />
    <Button type="primary" onClick={() => history.push(`/${locale}/adminus/subscriptions`)} >
      <Icon type="left" />
      Go back to subscriptions
      </Button>
  </>
}

function MedicalExamStatusTag ({ isRequired, isCompleted }) {

  if (isCompleted) {
    return <Tag color="green">{t('admin.status.completed')}</Tag>
  }

  return isRequired ? <Tag color="red">{t('admin.status.notCompleted')}</Tag> : <Tag>{t('admin.status.notRequired')}</Tag>

}
