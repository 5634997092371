import React from 'react'
import HeaderContent from './Header'
import { Layout } from 'antd'
import i18next from 'i18next'

const { Header, Content } = Layout

const AppLayout = ({ i18n, children, ...rest }) => {

  // Force English for backend
  if (i18next.languages[0] !== 'en') {
    i18next.changeLanguage('en')
  }

  return (
    <Layout {...rest} className='AppLayout PrivateLayout'>
      <Header>
        <div className='container'>
          <HeaderContent />
        </div>
      </Header>
      <Content>
        <main className="container">
          {children}
        </main>
      </Content>
    </Layout>
  )
}

export default AppLayout
