import React from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import QuoteForm from './../forms/QuoteForm'
import { MarkupEscape } from '../../../utils/helpers'

const QuoteResultPage = () => {
  const { Title } = Typography
  const { offerId } = useParams()

  return (
    <div className='container'>
      <Title>{MarkupEscape('plans.title')}</Title>

      <QuoteForm id={offerId} />
    </div>
  )
}

export default QuoteResultPage
