import React from 'react'
import { Input, Button, Icon } from 'antd'

export default {
  searchableColumn
}

export function searchableColumn (dataIndex, options) {
  options = Object.assign({
    placeholder: `Filter by ${dataIndex}`,
    handleSearch: function (_selectedKeys, confirm) {
      confirm()
    },
    handleReset: function (clearFilters) {
      clearFilters()
    },
    isValid: function (value, searchValue) {
      return (value || '').toString().toLowerCase().includes(searchValue.toLowerCase())
    }
  }, options)

  let searchInput
  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={options.placeholder}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => options.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => options.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
      </Button>
        <Button onClick={() => options.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
      </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const parts = dataIndex.split('||')

      for (const i in parts) {
        const part = parts[i]

        var recordValue = part.split('.').reduce((acc, key) => {
          if (acc && typeof (acc) === 'object' && acc.hasOwnProperty(key)) {
            return acc[key]
          }
          return acc
        }, record)

        if (options.isValid(recordValue, value)) {
          return true
        }
      }

      return false
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text.toString()}
    //     />
    //   ) : (
    //     text
    //   )
  })
}


// function formatName (name) {
//   if (typeof name !== 'string') {
//     return name
//   }

//   return name.split('').reduce((acc, char) => {

//     char = char.replace(' ', '')

//     if (!char) {
//       return acc
//     }

//     if (!acc.length) {
//       char = char.toLowerCase()
//     }

//     acc.push(char)
//     return acc;
//   }, []).join('')
// }
