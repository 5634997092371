import React, { useState, useEffect } from 'react'
import Api from '../../../utils/Api'
import { Link } from 'react-router-dom'
import { Table, Descriptions, Spin, message, Drawer, Tag, Typography, Button, Tooltip } from 'antd'
import moment from 'moment'
import { searchableColumn } from '../../../utils/table-helpers'
import { truncate } from '../../../utils/string-utils'
import { t, keyExists } from '../../../utils/helpers'

export default AdminOffersPage

function AdminOffersPage () {
  const [isLoading, setIsLoading] = useState(true)
  const [offers, setOffers] = useState([])

  const [selectedOffer, setSelectedOffer] = useState(undefined)
  const [offer, setOffer] = useState(undefined)

  const currentLocale = 'en' // getCurrentLocale() for multi-languages admin

  useEffect(() => {
    setIsLoading(true)
    Api.get('admin/offers')
      .then(res => setOffers(res))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (!selectedOffer) {
      setOffer(undefined)
      return
    }

    Api.get(`admin/offers/${selectedOffer._id}`)
      .then(res => setOffer(res))
      .catch(() => {
        message.error('Failed to get offer')
        setSelectedOffer(undefined)
      })
  }, [selectedOffer])

  const columns = [
    {
      title: 'Id',
      key: '_id',
      dataIndex: '_id',
      className: 'nowrap',
      width: 110,
      render: id => truncate(id, 2, 6)
    },
    {
      title: 'Email',
      key: 'email',
      className: 'nowrap',
      ...searchableColumn('email'),
      render: offer => offer.email
    },
    {
      title: 'Capital amount',
      key: 'capital',
      className: 'nowrap',
      render: offer => offer.model.capital // todo: add formatter
    },
    {
      title: 'Status',
      key: 'status',
      // dataIndex: 'status',
      width: 120,
      filters: offers.reduce((acc, offer) => {
        if (!~acc.findIndex(a => a.value === offer.status)) {
          acc.push({
            text: offer.status.substr(0, 1).toUpperCase() + offer.status.substr(1),
            value: offer.status
          })
        }

        return acc
      }, []),
      onFilter: (value, record) => record.status === value,
      render: ({ status, subscriptionId = null }) => (
        <>
          <StatusTag status={status} />
          {status === 'subscribed' && subscriptionId && (
            <Tooltip placement='top' title='View subscription'>
              <Link to={`/${currentLocale}/adminus/subscriptions/${subscriptionId}`}>
                <Button size='small' icon='eye' style={{ height: '22px', fontSize: '.9rem', lineHeight: '1rem' }} />
              </Link>
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: 'Created at',
      key: 'createdAt',
      align: 'center',
      className: 'nowrap',
      defaultSortOrder: 'descend',
      width: 150,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: d => moment(d.createdAt).format('DD.MM.YYYY HH:MM')
    }
  ]

  return (
    <>
      <section>
        <div className='container'>
          <Typography.Title level={3}>{t('admin.offers.title')}</Typography.Title>

          <Table
            loading={isLoading}
            rowKey='_id'
            rowClassName='clickable'
            dataSource={offers}
            columns={columns}
            onRow={offer => {
              return {
                onClick: () => setSelectedOffer(offer)
              }
            }}
          />
        </div>
      </section>
      <Drawer
        title='Offer'
        visible={!!selectedOffer}
        width='inherit'
        className='drawer-md'
        closable
        destroyOnClose
        onClose={() => setSelectedOffer(undefined)}
      >
        {
          !offer
            ? (
              <div className='text-center'>
                <Spin spinning={!offer} className='text-center' tip='Getting offer...' />
              </div>
            )
            : (
              <>
                <DrawerHeadline offer={offer} />
                {offer.model && (
                  <Descriptions bordered size='small' column={1}>
                    <Descriptions.Item label='Capital'>{offer.model.capital} €</Descriptions.Item>
                    <Descriptions.Item label='Interest Rate'>{offer.model.interestRate} %</Descriptions.Item>
                    <Descriptions.Item label='Duration'>{offer.model.duration} months</Descriptions.Item>
                    <Descriptions.Item label='Effective Date'>{moment(offer.model.effectiveDate).format('DD.MM.YYYY')}</Descriptions.Item>
                    <Descriptions.Item label='Created At'>{moment(offer.model.createdAt).format('DD.MM.YYYY')}</Descriptions.Item>
                    <Descriptions.Item label='Interval'>{offer.model.interval}</Descriptions.Item>
                    <Descriptions.Item label='Smoker'>{offer.model.isSmoker ? 'yes' : 'no'}</Descriptions.Item>
                    <Descriptions.Item label='Date of Birth'>{moment(offer.model.dateOfBirth).format('DD.MM.YYYY')}</Descriptions.Item>
                  </Descriptions>
                )}
                {offer.model && offer.model.additionalInsuree && (
                  <Descriptions bordered size='small' title='Additional Insuree' column={1} className='mt-3'>
                    <Descriptions.Item label='First Name'>{offer.model.firstName2} €</Descriptions.Item>
                    <Descriptions.Item label='Last Name'>{offer.model.lastName2} %</Descriptions.Item>
                    <Descriptions.Item label='Smoker'>{offer.model.isSmoker2 ? 'yes' : 'no'}</Descriptions.Item>
                    <Descriptions.Item label='Date of Birth'>{moment(offer.model.dateOfBirth2 || '').format('DD.MM.YYYY')}</Descriptions.Item>
                  </Descriptions>
                )}

                {offer.codes && (
                  <>
                    {offer.codes.map(code =>
                      <Descriptions key={code._id} bordered size='small' title='Verification Codes' column={1} className='mt-3'>
                        <Descriptions.Item label='Phone Number'>{code.phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label='Code'>{code.code}</Descriptions.Item>
                        <Descriptions.Item label='Sent'>{moment(code.createdAt || '').format('DD.MM.YYYY HH:mm')}</Descriptions.Item>
                      </Descriptions>
                    )}
                  </>
                )}
              </>
            )
        }
      </Drawer>
    </>
  )
}

function DrawerHeadline ({ offer }) {
  if (!offer.model || !offer.model.email) {
    return null
  }

  return offer.model && offer.model.lastName
    ? <Typography.Paragraph><strong>{offer.model.lastName.toUpperCase()}, {offer.model.firstName}</strong> - <StatusTag status={offer.status} /><br />{offer.email}</Typography.Paragraph>
    : (<Typography.Paragraph><strong>{offer.email}</strong> - <StatusTag status={offer.status} /></Typography.Paragraph>)
}

function StatusTag ({ status }) {
  return ({
    subscribed: <Tag color='green'>{t('admin.status.subscribed')}</Tag>,
    calculated: <Tag color='orange'>{t('admin.status.calculated')}</Tag>,
    saved: <Tag color='lime'>{t('admin.status.saved')}</Tag>
  })[status] || <Tag color='purple'>{keyExists(`admin.status.${status}`) ? t(`admin.status.${status}`) : status}</Tag>
}
