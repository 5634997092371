import React from 'react'
import i18next from 'i18next'
import { t, MarkupEscape } from './../../../utils/helpers'

const setErrorMessage = (error) => {
  if (error === 'default') {
    return t('forms.errors.mixed.default')
  }

  const options = {}
  if (error.message.field) {
    options.field = i18next.exists(`forms.fields.${error.message.field}.label`) ? t(`forms.fields.${error.message.field}.label`) : error.message.field
  }
  if (error.message.value) {
    if (error.message.value === "true") {
      options.value = t('global.true')
    } else if (error.message.value === "false") {
      options.value = t('global.no')
    } else {
      options.value = error.message.value
    }
  }
  return i18next.exists(`forms.errors.${error.message.key}`) ? MarkupEscape(`forms.errors.${error.message.key}`, options) : t('forms.errors.mixed.default')
}

const ErrorMessage = ({ errors, name, parent = null, className = '' }) => {
  const error = parent && errors[parent] ? errors[parent][name] : errors[name]

  if (!error) {
    return null
  }

  let message = setErrorMessage('default')

  if (typeof error.message === 'object') {
    if (!error.message.field) {
      error.message.field = name
    }
    message = setErrorMessage(error)
  }
  return (
    <p className={`${className} ErrorMessage`}>{message}</p>
  )
}

export default ErrorMessage
