var yup = require('yup');

var definition = {};
var q1 = ['alcoholConsumption', 'drugConsumption', 'hasTreatment', 'hasMedicalAppointment', 'hasHospitalAppointment', 'hasIncapacityOfWork'];
q1.forEach(function (question) {
  definition[question] = yup.bool().required();
  definition["".concat(question, "Explanation")] = yup.string({
    key: 'medicalQuestions.explanation',
    field: "".concat(question, "Explanation")
  }).when("".concat(question), requireIfTrue);
});
var q2 = ['hasCardiovascularDisease', 'hasMetabolismDisease', 'hasDiabetesDisease', 'hasCancerDisease', 'hasPsychologicalDisease', 'hasRespiratoryDisease', 'hasDigestiveSystemDisease', 'hasNervousSystemDisease', 'hasBloodDisease', 'hasMusclesDisease', 'hasInfectionDisease', 'hasSkinDisease', 'hasKidneysDisease', 'hasGenitalsDisease', 'hasEntDisease', 'hasEyesDisease', 'hasOtherDisease']; // a: bool | a1: past date | a2: text

q2.forEach(function (question) {
  definition[question] = yup.bool().required();
  definition["".concat(question, "StartDate")] = yup.date().when(question, function (v, s) {
    return v ? s.required({
      key: 'affections.startDate',
      field: "".concat(question, "StartDate")
    }) : s.nullable().default(null);
  }).when(question, isPastDate);
  definition["".concat(question, "Explanation")] = yup.string().max(2000).when(question, requireIfTrue);
});
module.exports = {
  definition: definition,
  schema: yup.object(definition).camelCase()
};

function requireIfTrue(value, schema, builder) {
  return value ? schema.ensure().required({
    key: 'affections.explanation'
  }) : schema.default('');
}

function isPastDate(v, schema) {
  return !v ? schema : schema.test('date.max', 'date.max', function (val) {
    if (val === undefined) {
      return true;
    }

    var vDate = new Date(val);
    return vDate instanceof Date && !isNaN(vDate) ? vDate < today() : false;
  });
}

function today() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}