import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import logo from './../../../images/simplomus.svg'
import { Row, Col, Button } from 'antd'
import { t, isDesktop, getCurrentLocale } from './../../../utils/helpers'
import throttle from 'lodash.throttle'

import { useTrackerContext } from './../../../context/TrackerContext'

import LocaleSwitch from './../../common/LocaleSwitch'

const Header = ({ minimalLayout }) => {
  const [open, setOpen] = useState(isDesktop())
  const currentLocale = getCurrentLocale()
  const target = minimalLayout ? '_blank' : '_self'
  const { trackEvent } = useTrackerContext()

  const handleMenuToggle = () => {
    setOpen(!open)
  }

  const handleOnClick = (name = null) => {
    if (minimalLayout && name) {
      trackEvent(`Clicked: ${name}`)
    }
    if (!isDesktop()) {
      handleMenuToggle()
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if ((isDesktop() === false) && open) {
        setOpen(false)
      }
      if ((isDesktop() === true)) {
        setOpen(true)
      }
    }

    window.addEventListener('resize', throttle(handleResize, 300))

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [open])

  return (
    <div className='Header'>
      <div className='container'>
        <Row type='flex' align='middle'>
          <Col xs={12} sm={12} md={3} lg={2} xl={2}>
            <NavLink className='logo' to={`/${currentLocale}/`}>
              <img src={logo} alt='' />
            </NavLink>
          </Col>
          {open && (
            <Col className='text-right' xs={12} sm={12} md={9} lg={10} xl={10}>
              <nav>
                {!minimalLayout && (
                  <NavLink to={`/${currentLocale}`} onClick={() => handleOnClick('Home')} exact>
                    {t('menu.home')}
                  </NavLink>
                )}
                <NavLink to={`/${currentLocale}/faq`} onClick={() => handleOnClick('FAQ')} target={target}>
                  {t('menu.faq')}
                </NavLink>
                <NavLink to={`/${currentLocale}/contact`} onClick={() => handleOnClick('Contact')} target={target} className={minimalLayout ? 'mr-md-3' : ''}>
                  {t('menu.contact')}
                </NavLink>

                {!minimalLayout && (
                  <NavLink className='button-wrapper' to={`/${currentLocale}/quote`} onClick={handleOnClick}>
                    <Button type='primary' onClick={() => trackEvent('Clicked: Get a quote')} ghost>{t('menu.quote')}</Button>
                  </NavLink>
                )}

                <LocaleSwitch />
              </nav>
            </Col>
          )}
        </Row>
        <Button id='menuToggle' type='primary' icon='menu' size='large' onClick={handleMenuToggle} />
      </div>
    </div>
  )
}

export default Header
