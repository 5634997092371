const generateMedicalFields = (property, hasExplanation = false, hasStartDate = false) => {
  return {
    [property]: '',
    ...hasExplanation && {
      [`${property}Explanation`]: ''
    },
    ...hasStartDate && {
      [`${property}StartDate`]: null
    }
  }
}

const medicalFields = {
  ...generateMedicalFields('alcoholConsumption', true),
  ...generateMedicalFields('drugConsumption', true),
  ...generateMedicalFields('hasTreatment', true),
  ...generateMedicalFields('hasMedicalAppointment', true),
  ...generateMedicalFields('hasHospitalAppointment', true),
  ...generateMedicalFields('hasIncapacityOfWork', true),
  ...generateMedicalFields('hasCardiovascularDisease', true, true),
  ...generateMedicalFields('hasMetabolismDisease', true, true),
  ...generateMedicalFields('hasDiabetesDisease', true, true),
  ...generateMedicalFields('hasCancerDisease', true, true),
  ...generateMedicalFields('hasPsychologicalDisease', true, true),
  ...generateMedicalFields('hasRespiratoryDisease', true, true),
  ...generateMedicalFields('hasDigestiveSystemDisease', true, true),
  ...generateMedicalFields('hasNervousSystemDisease', true, true),
  ...generateMedicalFields('hasBloodDisease', true, true),
  ...generateMedicalFields('hasMusclesDisease', true, true),
  ...generateMedicalFields('hasInfectionDisease', true, true),
  ...generateMedicalFields('hasSkinDisease', true, true),
  ...generateMedicalFields('hasKidneysDisease', true, true),
  ...generateMedicalFields('hasGenitalsDisease', true, true),
  ...generateMedicalFields('hasEntDisease', true, true),
  ...generateMedicalFields('hasEyesDisease', true, true),
  ...generateMedicalFields('hasOtherDisease', true, true)
}

const addressFields = {
  address: '',
  streetNumber: '',
  streetName: '',
  postalCode: '',
  city: '',
  countryCode: 'lu'
}

const phoneFields = {
  prefix: 'lu',
  number: ''
}

const goodHealthFields = {
  height: '',
  weight: '',
  isNotUnderIncapacityOfWork: '',
  isNotUnderTreatment: '',
  hasNoSevereDisease: '',
  hasNoHospitalization: ''
}

const fields = {
  global: {
    offerId: '',
    plan: '',
    additionalInsuree: false,
    personalIdFile: []
  },
  recap: {
    dataVerifiedConsent: false,
    confirmedIpid: false
  },
  // stepsToFollow: {
  //   confirmedIpid: false
  // },
  personalDetails: {
    isPolicyHolder: false,
    title: '',
    firstName: '',
    lastName: '',
    placeOfBirth: '',
    nationalityCode: 'lu',
    personalIdCard: '',
    personalIdCardNumber: '',
    personalIdValidUntil: '',
    civilStatus: '',
    partnerName: '',
    profession: '',
    socialStatus: '',
    email: '',
    phoneNumber: {
      ...phoneFields
    },
    address: {
      ...addressFields
    },
    isMoving: false,
    futureAddress: {
      ...addressFields
    },
    futureAddressAsOf: ''
  },
  loanDetails: {
    beneficiary: '',
    beneficiaryOther: '',
    benefitTransferInstitution: '',
    benefitTransferFileNumber: '',
    benefitTransferAddress: '',
    bankAccountHolder: '',
    bankAccountIban: '',
    bankAccountBic: '',
    existingContract: false,
    existingContractPolicyNumber: '',
    existingContract2: false,
    existingContract2PolicyNumber: ''
  },
  goodHealthDeclaration: {
    ...goodHealthFields
  },
  medicalDeclaration: {
    ...medicalFields
  },
  lastStep: {
    code: '',
    cancelsOtherInsurerContract: '',
    guaranteesMortgageLoan: '',
    correctDataCheck: false,
    termsCheck: false,
    dataProtectionCheck: false,
    dataProcessingCheck: false,
    intermediaryMandateCheck: false,
    emailCommunicationCheck: false
  }
}

export const generateStepData = (step, dataContext) => {
  const data = {
    // ...fields.global,
    ...fields[step]
  }
  Object.keys(data).forEach(key => {
    if (dataContext[key] !== undefined) {
      data[key] = dataContext[key]
    }
  })
  return data
}

export const generateSubscribeData = (offer) => {
  const additionalInsuree = offer.additionalInsuree

  return {
    ...generateStepData('global', offer),
    ...fields.recap,
    // ...fields.stepsToFollow,
    insurees: [
      {
        ...fields.personalDetails,
        isPolicyHolder: true
      },
      ...(additionalInsuree ? [
        fields.personalDetails
      ] : [])
    ],
    ...fields.loanDetails,
    goodHealthDeclaration: [
      fields.goodHealthDeclaration,
      ...(additionalInsuree ? [
        fields.goodHealthDeclaration
      ] : [{}])
    ],
    medicalDeclaration: [
      ...(offer.medicalDeclarations.policyHolder ? [
        fields.medicalDeclaration
      ] : [{}]),
      ...(additionalInsuree && offer.medicalDeclarations.additionalInsuree ? [
        fields.medicalDeclaration
      ] : [{}])
    ],
    ...fields.lastStep
  }
}
