import React from 'react'
import { MarkupEscape } from '../../../utils/helpers'
import Seo from '../common/Seo'
import { Link } from 'react-router-dom'
import { Typography, Button } from 'antd'
import hero from './../../../images/hero.svg'
import { t, getCurrentLocale } from './../../../utils/helpers'

const NotFoundPage = () => {
  const { Title } = Typography
  const currentLocale = getCurrentLocale() || 'en'

  return (
    <>
      <Seo pageName='notFound' />
      <section>
        <div className='container text-center'>
          <img src={hero} alt='Illustration' />
          <Title className='mt-5'>{MarkupEscape('notFound.title')}</Title>
          <Link to={`/${currentLocale}`}>
            <Button type='primary' size='large' className='mt-3'>{t('global.backHome')}</Button>
          </Link>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
