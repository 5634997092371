import React, { lazy } from 'react'
import { Router, Redirect, Switch, Route } from 'react-router-dom'

import * as auth from './utils/AuthenticationUtil'
import Authenticate from './utils/Authenticate'
import { availableLocales } from './i18n'
import history from './utils/history'
import PublicRoute from './utils/publicRoute'
import PrivateRoute from './utils/privateRoute'

import HomePage from './components/frontend/views/HomePage'
import FiscalityPage from './components/frontend/views/FiscalityPage'
import FAQPage from './components/frontend/views/FAQPage'
import QuotePage from './components/frontend/views/QuotePage'
import QuoteResultPage from './components/frontend/views/QuoteResultPage'
import ContactPage from './components/frontend/views/ContactPage'
import SubscribePage from './components/frontend/views/SubscribePage'
import NotFoundPage from './components/frontend/views/NotFoundPage'
import LegalPage from './components/frontend/views/legal/LegalPage'
import TermsPage from './components/frontend/views/legal/TermsPage'
import PrivacyPage from './components/frontend/views/legal/PrivacyPage'
import MandatePage from './components/frontend/views/legal/MandatePage'
import CookiesPage from './components/frontend/views/legal/CookiesPage'

import AdminDashboardPage from './components/backend/views/AdminDashboardPage'
import AdminSubscriptionsPage from './components/backend/views/AdminSubscriptionsPage'
import AdminSubscriptionPage from './components/backend/views/AdminSubscriptionPage'
import AdminNotFoundPage from './components/backend/views/AdminNotFoundPage'
import AdminOffersPage from './components/backend/views/AdminOffersPage'

import ScrollToTop from './components/frontend/common/ScrollToTop'

import { getAppInsights } from './services/TelemetryService'
import TelemetryProvider from './context/TelemetryProvider'
import { useTrackerContext } from './context/TrackerContext'

const SubscribeSuccessPage = lazy(() => import('./components/frontend/views/SubscribeSuccessPage'))

const Routes = () => {
  const { initTracker } = useTrackerContext()

  return (
    <Router history={history}>
      <TelemetryProvider instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY} after={() => { initTracker(getAppInsights()) }}>
        <ScrollToTop />
        <Switch>
          <Redirect exact from='/' to={`/${availableLocales[0]}`} />
          <PublicRoute exact path={`/:locale(${availableLocales.join('|')})`} component={HomePage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/faq`} component={FAQPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/contact`} component={ContactPage} />
          <PublicRoute exact path={`/:locale(${availableLocales.join('|')})/quote`} component={QuotePage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/quote/:offerId`} component={QuoteResultPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/subscribe/:offerId/:selectedPlan`} component={SubscribePage} minimalLayout />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/subscribe/success`} component={SubscribeSuccessPage} minimalLayout />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/tax`} component={FiscalityPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/legal`} component={LegalPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/terms`} component={TermsPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/privacy`} component={PrivacyPage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/mandate`} component={MandatePage} />
          <PublicRoute path={`/:locale(${availableLocales.join('|')})/cookies`} component={CookiesPage} />

          <Route exact path='/authenticate' component={Authenticate} />
          <Redirect exact from='/adminus' to={`/${availableLocales[0]}/adminus`} />

          <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus/signout`} render={() => { auth.signOut() }} />

          <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus`} component={AdminDashboardPage} />
          {/* <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus/quotes`} component={AdminDashboardPage} /> */}
          <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus/quotes`} component={AdminOffersPage} />
          <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus/subscriptions`} component={AdminSubscriptionsPage} />
          <PrivateRoute exact path={`/:locale(${availableLocales.join('|')})/adminus/subscriptions/:subscriptionId`} component={AdminSubscriptionPage} />
          <PrivateRoute path={`/:locale(${availableLocales.join('|')})/adminus`} component={AdminNotFoundPage} />
          <PublicRoute component={NotFoundPage} />
        </Switch>
      </TelemetryProvider>
    </Router>
  )
}

export default Routes
