import React from 'react'
import { Tag } from 'antd'
import { t, keyExists } from './../../utils/helpers'

export default {
  SubscriptionStatusTag
}

export function SubscriptionStatusTag ({ status, size = null }) {
  const classNames = []

  if (size) {
    classNames.push(size)
  }

  return ({
    'active': <Tag className={classNames.join(' ')} color="green">{t('admin.status.active')}</Tag>,
    'suspended': <Tag className={classNames.join(' ')} color="volcano">{t('admin.status.suspended')}</Tag>,
    'needsMedicalExam': <Tag className={classNames.join(' ')} color="orange">{t('admin.status.needsMedicalExam')}</Tag>,
    'cancelled': <Tag className={classNames.join(' ')} color="red">{t('admin.status.cancelled')}</Tag>,
    'subscribed': <Tag className={classNames.join(' ')} color="green">{t('admin.status.subscribed')}</Tag>,
    'calculated': <Tag className={classNames.join(' ')} color="orange">{t('admin.status.calculated')}</Tag>,
    'saved': <Tag className={classNames.join(' ')} color="lime">{t('admin.status.saved')}</Tag>,
    'pending': <Tag className={classNames.join(' ')} color="gold">{t('admin.status.pending')}</Tag>,
  })[status] || <Tag className={classNames.join(' ')} color="purple">{keyExists(`admin.status.${status}`) ? t(`admin.status.${status}`) : status}</Tag>
}
