import React, { useState, useEffect } from 'react'
import Seo from '../common/Seo'
import { Typography, Row, Col, Collapse, Button, Input } from 'antd'
import { t, MarkupEscape, getCurrentLocale } from '../../../utils/helpers'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'

const FAQPage = () => {
  const [query, setQuery] = useState(null)
  const [fuse, setFuse] = useState()
  const { Title } = Typography
  const { Panel } = Collapse
  const { Search } = Input
  const currentLocale = getCurrentLocale()

  const generateIndex = (array, section) => {
    return array.map((item, index) => {
      return {
        question: item.question,
        answerEscaped: item.answer.join('').replace(/(<([^>]+)>)/ig, ''),
        answer: item.answer,
        index: index,
        section: section
      }
    })
  }

  useEffect(() => {
    const index = [
      ...generateIndex(t('faq.sections.1.questions'), 1),
      ...generateIndex(t('faq.sections.2.questions'), 2),
      ...generateIndex(t('faq.sections.3.questions'), 3)
    ]

    setFuse(new Fuse(index, {
      includeScore: true,
      useExtendedSearch: true,
      threshold: 0.3,
      keys: [{
        name: 'question',
        weight: 0.8
      }, {
        name: 'answer',
        weight: 0.8
      }]
    }))
  }, [
    currentLocale,
    setFuse
  ])

  const SearchBar = ({ query, children }) => {
    let results = []

    if (query) {
      results = fuse.search(`'${query}`)
    }

    return (
      <div className='frame-section'>
        {query
          ? (
            <>
              <Title level={3}>{t('faq.search.title', { count: results.length })}</Title>
              {results.length
                ? (
                  <Collapse>
                    {results.map((result, key) => {
                      return (
                        <Panel header={result.item.question} key={`result-${key}`}>
                          <span dangerouslySetInnerHTML={{ __html: t(result.item.answer.join('').replace(new RegExp(query, 'ig'), `<mark>${query}</mark>`)) }} />
                        </Panel>
                      )
                    })}
                  </Collapse>
                )
                : (
                  <p>{t('faq.search.noResult', { query: query })}</p>
                )}
            </>
          ) : (
            children
          )}
      </div>
    )
  }

  return (
    <>
      <Seo pageName='faq' />
      <section className='pb-0'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Title>{MarkupEscape('faq.title')}</Title>
              <Search
                className='mb-5'
                placeholder={t('faq.search.placeholder')}
                allowClear
                onSearch={value => setQuery(value === '' ? null : value)}
              />
              <div className='frame pb-5'>
                <div className='frame-inner'>
                  <div className='frame-content'>
                    <SearchBar query={query}>
                      <>
                        <div className='frame-section'>
                          <Title level={3}>{t('faq.sections.1.title')}</Title>
                          <Collapse>
                            {t('faq.sections.1.questions').map((item, key) => {
                              return (
                                <Panel header={item.question} key={`section1-${key}`}>
                                  <span dangerouslySetInnerHTML={{ __html: item.answer.join('') }} />
                                </Panel>
                              )
                            })}
                          </Collapse>
                        </div>

                        <div className='frame-section'>
                          <Title level={3}>{t('faq.sections.2.title')}</Title>
                          <Collapse>
                            {t('faq.sections.2.questions').map((item, key) => {
                              return (
                                <Panel header={item.question} key={`section2-${key}`}>
                                  <span dangerouslySetInnerHTML={{ __html: item.answer.join('') }} />
                                </Panel>
                              )
                            })}
                          </Collapse>
                        </div>

                        <div className='frame-section'>
                          <Title level={3}>{t('faq.sections.3.title')}</Title>
                          <Collapse>
                            {t('faq.sections.3.questions').map((item, key) => {
                              return (
                                <Panel header={item.question} key={`section3-${key}`}>
                                  <span dangerouslySetInnerHTML={{ __html: item.answer.join('') }} />
                                </Panel>
                              )
                            })}
                          </Collapse>
                        </div>
                      </>
                    </SearchBar>
                  </div>
                  <div className='frame-footer'>
                    <div className='text-center'>
                      <Title level={4}>{t('faq.sections.contact.title')}</Title>
                      <Button type='primary' className='mt-2'><Link to={`/${currentLocale}/contact`}>{t('faq.sections.contact.button.label')}</Link></Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default FAQPage
