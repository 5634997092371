import React, { useState, useEffect } from 'react'
import { Icon, Switch, Row, Col, Button, Alert, Typography, Radio, Spin } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { t, MarkupEscape } from '../../../utils/helpers'
import VerificationCode from './../common/VerificationCode'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from './../../../utils/subscribeFieldsModel'
import ErrorMessage from './../common/ErrorMessage'
const validationSchema = require('./../../../utils/validation')

const SubscriptionLastStep = ({ offer, offerId, handlePreviousStep, handleProceedSubscription }) => {
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasConsent, setHasConsent] = useState(false)
  const { Title } = Typography
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />
  const stepValidationSchema = validationSchema.subscibeStep7Validation
  const defaultValues = generateStepData('lastStep', subscribeFormData)

  const { watch, control, register, setValue, handleSubmit, errors } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    validationSchema: stepValidationSchema,
    defaultValues: defaultValues
  })

  const values = watch({ nest: true })

  useEffect(() => {
    // Set validation dependency fields
    register({ name: 'code' })

    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [register])

  const toggle = (field, value = null, event = null) => {
    const newValue = value !== null ? value : !values[field]

    if (event && event.target.tagName !== 'A') {
      setValue(field, newValue, false)
    }
  }

  const handleVerificationComplete = (code) => {
    setValue('code', code, false)
  }

  const onSubmit = (values) => {
    setLoading(true)
    setSubscribeFormData({
      ...subscribeFormData,
      ...values
    })
    handleProceedSubscription({
      ...subscribeFormData,
      ...values
    }, offer)
    setLoading(false)
  }

  useEffect(() => {
    const radios = [
      values.cancelsOtherInsurerContract,
      values.guaranteesMortgageLoan
    ]

    const switches = [
      values.correctDataCheck,
      values.termsCheck,
      values.dataProtectionCheck,
      values.dataProcessingCheck,
      values.intermediaryMandateCheck,
      values.emailCommunicationCheck
    ]

    if (!radios.includes('') && !switches.includes(false)) {
      setHasConsent(true)
    } else {
      setHasConsent(false)
    }
  }, [
    watch,
    values.cancelsOtherInsurerContract,
    values.correctDataCheck,
    values.dataProcessingCheck,
    values.dataProtectionCheck,
    values.emailCommunicationCheck,
    values.guaranteesMortgageLoan,
    values.intermediaryMandateCheck,
    values.termsCheck
  ])

  const generateRadio = (propertyName) => {
    return (
      <Row className='mb-4' type='flex' align='middle'>
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <p className='mb-0 primary text-small' style={{ cursor: 'pointer' }}>{t(`forms.steps.subscribe.lastStep.sections.lastQuestions.questions.${propertyName}`)}</p>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Controller as={(
            <Radio.Group buttonStyle='solid' size='small'>
              <Radio.Button value={true}>
                {t('global.yes')}
              </Radio.Button>
              <Radio.Button value={false}>
                {t('global.false')}
              </Radio.Button>
            </Radio.Group>
          )}
            defaultValue={defaultValues[propertyName]}
            value={values[propertyName]}
            name={propertyName}
            onChange={([e]) => e.target.value}
            control={control}
          />
        </Col>
        <Col xs={12}>
          <ErrorMessage className='mb-1' errors={errors} name={propertyName} />
        </Col>
      </Row>
    )
  }

  const generateSwitch = (propertyName, options = null) => {
    if (!options) {
      options = {
        customLabel: null
      }
    }
    return (
      <Row className='mb-4' type='flex' align='middle'>
        <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            as={Switch}
            name={propertyName}
            control={control}
            defaultChecked={defaultValues[propertyName]}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            valueName='checked'
          />
          <p className='mb-0 ml-3 primary text-small' onClick={(e) => toggle(propertyName, null, e)} style={{ cursor: 'pointer' }}>{options.customLabel ? options.customLabel : MarkupEscape(`forms.steps.subscribe.lastStep.sections.pleaseConfirm.questions.${propertyName}`)}</p>
        </Col>
        <ErrorMessage className='mb-1' errors={errors} name={propertyName} />
      </Row>
    )
  }

  return (
    <form className='SubscribeMedicalDeclaration' onSubmit={handleSubmit(onSubmit)}>
      <div className='frame-content'>
        {loading ? (
          <div className='container loader-wrapper'>
            <Row type='flex' justify='center' align='middle'>
              <Spin indicator={icon} tip={t('global.loading')} />
            </Row>
          </div>
        ) : (
            <>
              <div className='frame-section'>
                <Title level={3}>{t('forms.steps.subscribe.lastStep.sections.lastQuestions.title')}</Title>
                {generateRadio('cancelsOtherInsurerContract')}
                {generateRadio('guaranteesMortgageLoan')}
              </div>
              <div className='frame-section'>
                <Title level={3}>{t('forms.steps.subscribe.lastStep.sections.pleaseConfirm.title')}</Title>
                {generateSwitch('correctDataCheck')}
                {generateSwitch('termsCheck', {
                  customLabel: MarkupEscape('forms.steps.subscribe.lastStep.sections.pleaseConfirm.questions.termsCheck', { productName: subscribeFormData.additionalInsuree ? '2Win' : 'Classic' })
                })}
                {generateSwitch('dataProtectionCheck')}
                {generateSwitch('dataProcessingCheck')}
                {generateSwitch('intermediaryMandateCheck')}
                {generateSwitch('emailCommunicationCheck')}
              </div>

              {verified && (
                <div className='frame-section'>
                  <Alert
                    message={t('verification.alert.verified.title')}
                    description={t('verification.alert.verified.text')}
                    type='success'
                    showIcon
                  />
                </div>
              )}
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button onClick={handlePreviousStep}>{t('global.previous')}</Button>
          {verified ? (
            <Button type='primary' htmlType='submit' style={{ marginLeft: 15 }} disabled={!hasConsent} loading={loading} onClick={handleSubmit}>{t('forms.buttons.subscribe')}</Button>
          ) : (
              <VerificationCode offerId={offerId} isVerified={verified} setVerified={setVerified} isFormValidated={hasConsent} onComplete={handleVerificationComplete} />
            )}
        </div>
      )}
    </form>
  )
}

export default SubscriptionLastStep
