import React from 'react'
import { NavLink } from 'react-router-dom'
import { t, getCurrentLocale } from './../../../utils/helpers'

import LocaleSwitch from './../../common/LocaleSwitch'
import { Row, Col } from 'antd'

import VRB from './../../../images/vrb_white.svg'
import Cardif from './../../../images/cardif-white.svg'

const Footer = ({ minimalLayout }) => {
  const currentLocale = getCurrentLocale()
  const target = minimalLayout ? '_blank' : '_self'

  return (
    <div className='Footer'>
      {!minimalLayout && (
        <div className='Footer-1'>
          <div className='container'>
            <Row type='flex' align='middle'>
              <Col xs={12} sm={8} md={8} lg={10} xl={10}>
                <nav>
                  <NavLink to={`/${currentLocale}`} exact>
                    {t('menu.home')}
                  </NavLink>
                  <NavLink to={`/${currentLocale}/faq`}>
                    {t('menu.faq')}
                  </NavLink>
                  <NavLink to={`/${currentLocale}/tax`}>
                    {t('menu.fiscality')}
                  </NavLink>
                  <NavLink to={`/${currentLocale}/contact`}>
                    {t('menu.contact')}
                  </NavLink>
                  <NavLink to={`/${currentLocale}/quote`}>
                    {t('menu.quote')}
                  </NavLink>
                </nav>
              </Col>
              <Col xs={6} sm={4} md={4} lg={2} xl={2} align='end'>
                <LocaleSwitch type='primary' />
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className='Footer-2'>
        <div className='container'>
          <Row type='flex' align='middle'>
            <Col xs={12} sm={12} md={8} lg={8} xl={9}>
              <p>&copy; {new Date().getFullYear()} Vanbreda Risk &amp; Benefits S.A.</p>
              <nav>
                <NavLink to={`/${currentLocale}/legal`} target={target}>
                  {t('legal.title')}
                </NavLink>
                <NavLink to={`/${currentLocale}/terms`} target={target}>
                  {t('terms.title')}
                </NavLink>
                <NavLink to={`/${currentLocale}/privacy`} target={target}>
                  {t('privacy.title')}
                </NavLink>
                <NavLink to={`/${currentLocale}/cookies`} target={target}>
                  {t('cookies.title')}
                </NavLink>
              </nav>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={3} align='end'>
              <div className='mt-4 mt-md-0 ml-md-auto'>
                <img src={VRB} height={30} alt='Vanbreda Risk &amp; Benefits S.A. Logo' />
                <img className='mt-2 mt-lg-0 ml-4 ml-md-0 ml-lg-4' src={Cardif} height={30} alt='Cardif Logo' />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Footer
