import React from 'react'
import Seo from '../../common/Seo'
import { Row, Col, Typography } from 'antd'
import { t, MarkupEscape } from 'src/utils/helpers'

const TermsPage = () => {
  const { Title, Text } = Typography

  return (
    <>
      <Seo pageName='terms' />
      <section className='pb-0'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Title>{t('terms.title')}</Title>
              <p>{t('terms.subtitle')}</p>

              <div className='frame pb-5'>
                <div className='frame-inner'>
                  <div className='frame-content'>
                    <div className='step'>
                      <Title level={3}>{t('terms.steps.1.title')}</Title>
                      <Title level={4}>{t('terms.steps.1.subtitle')}</Title>
                      <Text>{MarkupEscape('terms.steps.1.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.2.title')}</Title>
                      <Title level={4}>{t('terms.steps.2.subtitle')}</Title>
                      <Text>{MarkupEscape('terms.steps.2.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.3.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.3.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.4.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.4.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.5.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.5.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.6.title')}</Title>
                      <Title level={4}>{t('terms.steps.6.subtitle1')}</Title>
                      <Text>{MarkupEscape('terms.steps.6.text1')}</Text>

                      <Title level={4}>{t('terms.steps.6.subtitle2')}</Title>
                      <Text>{MarkupEscape('terms.steps.6.text2')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.7.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.7.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.8.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.8.text1')}</Text>
                      <Title level={4}>{t('terms.steps.8.subtitle2')}</Title>
                      <Text>{MarkupEscape('terms.steps.8.text2')}</Text>

                      <Title level={4}>{t('terms.steps.8.subtitle3')}</Title>
                      <Text>{MarkupEscape('terms.steps.8.text3')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.9.title')}</Title>
                      <Title level={4}>{t('terms.steps.9.subtitle1')}</Title>
                      <Text>{MarkupEscape('terms.steps.9.text1')}</Text>

                      <Title level={4}>{t('terms.steps.9.subtitle2')}</Title>
                      <Text>{MarkupEscape('terms.steps.9.text2')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.10.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.10.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.11.title')}</Title>
                      <Title level={4}>{t('terms.steps.11.subtitle1')}</Title>
                      <Text>{MarkupEscape('terms.steps.11.text1')}</Text>

                      <Title level={4}>{t('terms.steps.11.subtitle2')}</Title>
                      <Text>{MarkupEscape('terms.steps.11.text2')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.12.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.12.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.13.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.13.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.14.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.14.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.15.title')}</Title>
                      <Title level={4}>{t('terms.steps.15.subtitle')}</Title>
                      <Text>{MarkupEscape('terms.steps.15.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.16.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.16.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.17.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.17.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.18.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.18.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.19.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.19.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.20.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.20.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.21.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.21.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.22.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.22.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.23.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.23.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.24.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.24.text')}</Text>
                    </div>

                    <div className='step mt-5'>
                      <Title level={3}>{t('terms.steps.25.title')}</Title>
                      <Text>{MarkupEscape('terms.steps.25.text')}</Text>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default TermsPage
