import React from 'react'
import Seo from '../common/Seo'
import { Row, Col, Typography, Table } from 'antd'
import { t, MarkupEscape, currencyFormat } from 'src/utils/helpers'

const FiscalityPage = () => {
  const { Title } = Typography

  const getHeaderTitle = (key) => t(`fiscality.table.header.${key}`, { returnObjects: true })

  const columns = [
    {
      title: getHeaderTitle(0),
      dataIndex: 'age',
      key: 'age',
      align: 'left',
      render: index => (<b>{t(`fiscality.table.body.${index}`, { returnObjects: true })}</b>)
    },
    {
      title: getHeaderTitle(1),
      dataIndex: 'noChildren',
      key: 'noChildren',
      align: 'center',
      render: amount => `${currencyFormat.format(amount, { separator: ' ', precision: 0 })} €`
    },
    {
      title: getHeaderTitle(2),
      dataIndex: 'oneChild',
      key: 'oneChild',
      align: 'center',
      render: amount => `${currencyFormat.format(amount, { separator: ' ', precision: 0 })} €`
    },
    {
      title: getHeaderTitle(3),
      dataIndex: 'twoChildren',
      key: 'twoChildren',
      align: 'center',
      render: amount => `${currencyFormat.format(amount, { separator: ' ', precision: 0 })} €`
    },
    {
      title: getHeaderTitle(4),
      dataIndex: 'threeChildren',
      key: 'threeChildren',
      align: 'center',
      render: amount => `${currencyFormat.format(amount, { separator: ' ', precision: 0 })} €`
    },
    {
      title: getHeaderTitle(5),
      dataIndex: 'extraChild',
      key: 'extraChild',
      align: 'center',
      render: amount => `+ ${currencyFormat.format(amount, { separator: ' ', precision: 0 })} €`
    }
  ]

  const generateData = (key) => ({
    key: key,
    age: key,
    noChildren: 6000 + (key * 480),
    oneChild: 7200 + (key * 576),
    twoChildren: 8400 + (key * 672),
    threeChildren: 9600 + (key * 768),
    extraChild: 1200 + (key * 96)
  })

  const data = [...Array(21).keys()].map(index => generateData(index))

  return (
    <>
      <Seo pageName='fiscality' />
      <section className='pb-0'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Title>{t('fiscality.title')}</Title>

              <div className='frame pb-5'>
                <div className='frame-inner'>
                  <div className='frame-content'>
                    {MarkupEscape('fiscality.text', { joinArrays: true })}
                    <h5 className='my-4'>{t('fiscality.table.title')}</h5>
                    <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 'max-content' }} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default FiscalityPage
