import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/scss/app.scss'
import Loader from './components/common/Loader'
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </I18nextProvider>,
    rootElement)
} else {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </I18nextProvider>,
    rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
