var _objectSpread = require("/home/vsts/work/1/s/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread2");

var generateMessage = function generateMessage(key, field) {
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return _objectSpread({
    key: key,
    field: field
  }, value && {
    value: value
  });
};

var customMessage = {
  mixed: {
    default: function _default(_ref) {
      var path = _ref.path;
      return generateMessage('default', path);
    },
    required: function required(_ref2) {
      var path = _ref2.path;
      return generateMessage('required', path);
    },
    oneOf: function oneOf(_ref3) {
      var path = _ref3.path,
          values = _ref3.values;
      return generateMessage('oneOf', path, values);
    },
    notOneOf: function notOneOf(_ref4) {
      var path = _ref4.path,
          values = _ref4.values;
      return generateMessage('notOneOf', path, values);
    },
    typeError: function typeError(_ref5) {
      var path = _ref5.path,
          type = _ref5.type;
      return generateMessage('typeError', path, type);
    }
  },
  string: {
    length: function length(_ref6) {
      var path = _ref6.path,
          _length = _ref6.length;
      return generateMessage('string.length', path, _length);
    },
    min: function min(_ref7) {
      var path = _ref7.path,
          _min = _ref7.min;
      return generateMessage('string.min', path, _min);
    },
    max: function max(_ref8) {
      var path = _ref8.path,
          _max = _ref8.max;
      return generateMessage('string.max', path, _max);
    },
    email: function email(_ref9) {
      var path = _ref9.path;
      return generateMessage('string.email', path);
    },
    url: function url(_ref10) {
      var path = _ref10.path;
      return generateMessage('string.url', path);
    },
    trim: function trim(_ref11) {
      var path = _ref11.path;
      return generateMessage('string.trim', path);
    },
    lowercase: function lowercase(_ref12) {
      var path = _ref12.path;
      return generateMessage('string.lowercase', path);
    },
    uppercase: function uppercase(_ref13) {
      var path = _ref13.path;
      return generateMessage('string.uppercase', path);
    }
  },
  number: {
    min: function min(_ref14) {
      var path = _ref14.path,
          _min2 = _ref14.min;
      return generateMessage('number.min', path, _min2);
    },
    max: function max(_ref15) {
      var path = _ref15.path,
          _max2 = _ref15.max;
      return generateMessage('number.max', path, _max2);
    },
    lessThan: function lessThan(_ref16) {
      var path = _ref16.path,
          less = _ref16.less;
      return generateMessage('number.lessThan', path, less);
    },
    moreThan: function moreThan(_ref17) {
      var path = _ref17.path,
          more = _ref17.more;
      return generateMessage('number.moreThan', path, more);
    },
    notEqual: function notEqual(_ref18) {
      var path = _ref18.path,
          _notEqual = _ref18.notEqual;
      return generateMessage('number.notEqual', path, _notEqual);
    },
    positive: function positive(_ref19) {
      var path = _ref19.path;
      return generateMessage('number.positive', path);
    },
    negative: function negative(_ref20) {
      var path = _ref20.path;
      return generateMessage('number.negative', path);
    },
    integer: function integer(_ref21) {
      var path = _ref21.path;
      return generateMessage('number.integer', path);
    }
  },
  date: {
    min: function min(_ref22) {
      var path = _ref22.path,
          _min3 = _ref22.min;
      return generateMessage('date.min', path, _min3);
    },
    max: function max(_ref23) {
      var path = _ref23.path,
          _max3 = _ref23.max;
      return generateMessage('date.max', path, _max3);
    }
  },
  array: {
    min: function min(_ref24) {
      var path = _ref24.path,
          _min4 = _ref24.min;
      return generateMessage('array.min', path, _min4);
    },
    max: function max(_ref25) {
      var path = _ref25.path,
          _max4 = _ref25.max;
      return generateMessage('array.max', path, _max4);
    }
  }
};
module.exports = customMessage;