import React from 'react'
import Seo from '../common/Seo'
import { Typography, Row, Col, Button } from 'antd'
import { t, MarkupEscape, getCurrentLocale } from '../../../utils/helpers'
import { Link } from 'react-router-dom'

const ContactPage = () => {
  const { Title } = Typography
  const currentLocale = getCurrentLocale()

  return (
    <>
      <Seo pageName='contact' />
      <section className='pb-0'>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Title>{MarkupEscape('contact.title')}</Title>

              <div className='frame pb-5'>
                <div className='frame-inner'>
                  <div className='frame-content'>
                    <div className='frame-section'>
                      <Title level={3}>{t('contact.sections.address.title')}</Title>
                      <p>{MarkupEscape('contact.sections.address.value')}</p>
                    </div>
                    <div className='frame-section'>
                      <Title level={3}>{t('contact.sections.contactInformation.title')}</Title>
                      <p>
                        {t('contact.sections.contactInformation.value.phone.label')}: <a href={`tel:${t('contact.sections.contactInformation.value.phone.value').replace(/\s/g, '')}`}>{t('contact.sections.contactInformation.value.phone.value')}</a><br />
                        {t('contact.sections.contactInformation.value.fax.label')}: {t('contact.sections.contactInformation.value.fax.value')}<br />
                        {t('contact.sections.contactInformation.value.email.label')}: <a href={`mailto:${t('contact.sections.contactInformation.value.email.value')}`}>{t('contact.sections.contactInformation.value.email.value')}</a><br />
                      </p>
                    </div>

                    <div className='frame-section'>
                      <Title level={3}>{t('contact.sections.openingHours.title')}</Title>
                      <p>{t('contact.sections.openingHours.value')}</p>
                    </div>
                  </div>
                  <div className='frame-footer'>
                    <div className='text-center'>
                      <Title level={4}>{t('contact.sections.faq.title')}</Title>
                      <Button type='primary' className='mt-2'><Link to={`/${currentLocale}/faq`}>{t('contact.sections.faq.button.label')}</Link></Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default ContactPage
