var yup = require('yup');

var definition = {
  height: yup.number().required().min(100).max(225),
  weight: yup.number().required().min(40).max(150),
  isNotUnderIncapacityOfWork: yup.bool().required({
    key: 'consent'
  }),
  isNotUnderTreatment: yup.bool().required({
    key: 'consent'
  }),
  hasNoSevereDisease: yup.bool().required({
    key: 'consent'
  }),
  hasNoHospitalization: yup.bool().required({
    key: 'consent'
  })
};
module.exports = {
  definition: definition,
  schema: yup.object(definition).camelCase()
};