import React from 'react'
import { Spin } from 'antd'

const Loader = () => {

  const style = {
    textAlign: 'center',
    height: '100vh'
  }

  return (
    <div className='Loader' style={style}>
      <Spin />
    </div>
  )
}

export default Loader
