import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { SubscribeStepProvider, SubscribeFormProvider } from './context/SubscribeContext'
import { LocaleSwitchProvider } from './context/LocaleSwitchContext'
import { TrackerProvider } from './context/TrackerContext'
import { Helmet } from 'react-helmet'

import Routes from './routes'

const App = ({ i18n }) => {
  useEffect(() => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      return
    }
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5zevbaEKYvWhh-QhjxRaUjg3kZtJB_t8&libraries=places&language=${i18n.language}`
    document.head.appendChild(script)
  }, [i18n.language])

  const title = `Simplomus - ${i18n.t('home.title').replace(/(<([^>]+)>)/ig, '')}`
  const description = i18n.t('home.subtitle')

  return (
    <div className='App'>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{title}</title>
        <meta name='description' content={description} />
        {/* OpenGraph tags */}
        <meta name='og:url' content={process.env.REACT_APP_URL} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description} />
        {/* Twitter Card tags */}
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
      </Helmet>
      <SubscribeStepProvider>
        <SubscribeFormProvider>
          <LocaleSwitchProvider>
            <TrackerProvider>
              <Routes />
            </TrackerProvider>
          </LocaleSwitchProvider>
        </SubscribeFormProvider>
      </SubscribeStepProvider>
    </div>
  )
}

export default withTranslation()(App)
