import React from 'react'
import HeaderContent from './Header'
import FooterContent from './Footer'
import { Layout, Alert } from 'antd'
import { detect } from 'detect-browser'
import { MarkupEscape } from 'src/utils/helpers'

const { Header, Footer, Content } = Layout

const AppLayout = ({ children, minimalLayout, ...props }) => {
  const browser = detect()

  const classNames = [
    'AppLayout',
    'PublicLayout',
    browser && browser.name === 'ie' ? 'ie' : ''
  ]

  return (
    <Layout {...props} className={classNames.join(' ')}>
      <Header>
        {browser && browser.name === 'ie' && (
          <Alert
            showIcon={false}
            className='text-center'
            message={MarkupEscape('global.unsupportedBrowser')}
            banner
          />
        )}
        <HeaderContent minimalLayout={minimalLayout} />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        <FooterContent minimalLayout={minimalLayout} />
      </Footer>
    </Layout>
  )
}

export default AppLayout
