var _require = require('./QuoteSubscribeModel.files'),
    mimetypes = _require.mimetypes,
    fileSize = _require.fileSize;

var minAge = 18;
var maxAge = 75;
var maxDuration = 480;

var moment = function moment(_moment) {
  if (!_moment) {
    return;
  }

  var today = function today() {
    return _moment({
      hour: 0,
      minute: 0,
      seconds: 0
    });
  };

  var isValidMinAge = function isValidMinAge(value) {
    return value === undefined ? true : _moment().diff(_moment(value), 'year') >= minAge;
  };

  var isValidMaxAge = function isValidMaxAge(value) {
    return value === undefined ? true : _moment().diff(_moment(value), 'year') < maxAge;
  };

  var isFuture = function isFuture(value) {
    return _moment(value) > today();
  };

  var isTodayOrFuture = function isTodayOrFuture(value) {
    return _moment(value) >= today();
  };

  var isValidEffectiveDate = function isValidEffectiveDate(value) {
    return _moment().diff(_moment(value), 'month', true) <= 18; // max 18 month ahead today
  };

  var isMaxDuration = function isMaxDuration(dateOfBirth, dateOfBirth2, effectiveDate, schema) {
    var start = _moment(effectiveDate);

    var end = _moment(start).add(maxDuration, 'month');

    var dateOfBirthMoment = _moment(dateOfBirth);

    var dateOfBirth2Moment = _moment(dateOfBirth2);

    var calculateFrom = dateOfBirth2Moment.isValid() && dateOfBirth2Moment.isBefore(dateOfBirthMoment) ? dateOfBirth2Moment : dateOfBirthMoment;
    var diff = end.diff(calculateFrom, 'year', true);

    if (diff >= maxAge) {
      var max = end.subtract(diff - maxAge, 'year').diff(start, 'month');
      return schema.max(max, {
        key: 'loan.max',
        value: max / 12 < 0 ? 0 : Math.floor(max / 12)
      });
    }

    return schema;
  };

  return {
    today: today,
    isValidMinAge: isValidMinAge,
    isValidMaxAge: isValidMaxAge,
    isFuture: isFuture,
    isTodayOrFuture: isTodayOrFuture,
    isValidEffectiveDate: isValidEffectiveDate,
    isMaxDuration: isMaxDuration
  };
};

var iban = function iban(_iban) {
  if (!_iban) {
    return;
  }

  var isValidIban = function isValidIban(value) {
    return _iban.isValid(value.replace(/\s/g, ''));
  };

  return {
    isValidIban: isValidIban
  };
};

var file = function file() {
  var isValidFile = function isValidFile(file) {
    if (Array.isArray(file)) {
      file = file[0];
    }

    return file instanceof File;
  };

  var isValidFileSize = function isValidFileSize(file) {
    var fileSizeLimit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : fileSize;

    if (Array.isArray(file)) {
      file = file[0];
    }

    return file.size < fileSizeLimit;
  };

  var isValidMimeType = function isValidMimeType(file) {
    var mimetypesArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : mimetypes;

    if (Array.isArray(file)) {
      file = file[0];
    }

    return mimetypesArray.includes(file.type);
  };

  return {
    isValidFile: isValidFile,
    isValidFileSize: isValidFileSize,
    isValidMimeType: isValidMimeType
  };
};

module.exports = {
  moment: moment,
  iban: iban,
  file: file
};