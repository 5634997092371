import React, { useState, useEffect } from 'react'
import Api from '../../../utils/Api'
import { Table, Typography } from 'antd'
import moment from 'moment'
import { searchableColumn } from '../../../utils/table-helpers'
import { truncate } from '../../../utils/string-utils'
import { useHistory } from 'react-router-dom'
import { SubscriptionStatusTag } from '../subscription-status-tag'
import { t, getCurrentLocale } from 'src/utils/helpers'

export default AdminSubscriptionsPage

function AdminSubscriptionsPage () {
  const currentLocale = getCurrentLocale()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    setIsLoading(true)
    Api.get('admin/subscriptions')
      .then(res => setSubscriptions(res))
      .finally(() => setIsLoading(false))
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: '_id',
      width: 1,
      className: 'nowrap',
      render: id => truncate(id, 2, 6)
    },
    {
      title: 'Name',
      dataIndex: 'policyHolder',
      ...searchableColumn('policyHolder', {
        placeholder: 'Filter by first and lastname',
        isValid: (value, searchValue) => {
          return `${searchValue}`.split(' ').reduce((acc, curr) => {
            if (acc) {
              return acc
            }

            const toLower = (value) => typeof value !== 'string' ? '' : value.toLowerCase()
            curr = toLower(curr)
            return toLower(value.firstName).includes(curr) || toLower(value.lastName).includes(curr)
          }, false)
        }
      }
      ),
      render: (v) => `${v.firstName} ${`${v.lastName}`.toUpperCase()}`
    },
    {
      title: 'Email',
      dataIndex: 'policyHolder.email',
      ...searchableColumn('policyHolder.email')
    },
    {
      title: 'Reference Number',
      dataIndex: 'referenceNumber',
      ...searchableColumn('referenceNumber')
    },
    {
      title: 'Policy Number',
      dataIndex: 'policyNumber',
      ...searchableColumn('policyNumber')
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 1,
      className: 'nowrap',
      filters: subscriptions.reduce((acc, subscription) => {
        if (!~acc.findIndex(a => a.value === subscription.status)) {
          acc.push({
            text: subscription.status.substr(0, 1).toUpperCase() + (subscription.status.substr(1)).split('').reduce((acc, char) =>
              acc + (char === char.toUpperCase() ? ' ' : '') + char
              , ''),
            value: subscription.status
          })
        }

        return acc
      }, []),
      onFilter: (value, record) => record.status === value,
      render: status => <SubscriptionStatusTag status={status} />
    },
    {
      title: 'Created at',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 1,
      className: 'nowrap',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: d => moment(d).format('DD.MM.YYYY HH:mm')
    },

  ]

  return (
    <>
      <section>
        <div className='container'>
          <Typography.Title level={3}>{t('admin.subscriptions.title')}</Typography.Title>

          <Table loading={isLoading} rowKey="_id" rowClassName="clickable"
            dataSource={subscriptions} columns={columns} onRow={(record) => {
              return {
                onClick: () => {
                  history.push(`/${currentLocale}/adminus/subscriptions/${record._id}`)
                }
              }
            }} />
        </div>
      </section>
    </>
  )
}
