import React, { useState } from 'react'
import JSONPretty from 'react-json-prettify'
import { github } from 'react-json-prettify/dist/themes'
import { Button, Drawer } from 'antd'

function CodeHighlight (code) {
  const [showOutput, setShowOutput] = useState(false)
  if (!code) {
    return false
  }

  return (
    <>
      <Button type='primary' style={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 10 }} onClick={() => setShowOutput(true)}>[dev] Show Values</Button>
      <Drawer
        title='Output'
        width='40%'
        placement='right'
        closable={true}
        onClose={() => setShowOutput(false)}
        visible={showOutput}
      >
        <JSONPretty json={code.code} theme={github} padding={4} />
      </Drawer>
    </>
  )
}

export default CodeHighlight
