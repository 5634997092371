import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal, Typography, message, Alert, Icon } from 'antd'
import { t, secondsToCountDown, MarkupEscape, getCountryPrefix } from '../../../utils/helpers'
import { postCall } from '../../../utils/ApiCall'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import ReactCodeInput from 'react-verification-code-input'

const VerificationCode = ({ offerId, isVerified, setVerified, isFormValidated, onComplete }) => {
  const { subscribeFormData } = useSubscribeFormContext()
  const { Title } = Typography
  const [counter, setCounter] = useState(0)
  const [requestCounter, setRequestCounter] = useState(0)
  const [hasCodeError, setHasCodeError] = useState(false)
  const [retryLeft, setRetryLeft] = useState(1)
  const [needsToRetry, setNeedsToRetry] = useState(false)
  const [modal, setModal] = useState({
    visible: false,
    confirmLoading: false
  })
  const codeInputRef = useRef(null)
  const phoneNumber = `${getCountryPrefix(subscribeFormData.insurees[0].phoneNumber.prefix)}${subscribeFormData.insurees[0].phoneNumber.number}`

  useEffect(() => {
    let isCancelled = false

    if (!counter) {
      return
    }

    const intervalId = setInterval(() => {
      if (!isCancelled) {
        setCounter(counter - 1)
      }
    }, 1000)

    return () => {
      isCancelled = true
      clearInterval(intervalId)
    }
  }, [counter])

  const requestCode = () => {
    setHasCodeError(false)

    if (counter > 0 && requestCounter > 12 && isVerified && isFormValidated) {
      return
    }

    setCounter(120) // Start countdown
    setRequestCounter(requestCounter + 1) // Add request to request counter
    sendCode()
      .then(() => {
        message.success(`${t('verification.notification.codeSent')} ${phoneNumber}`)
        setModal({
          ...modal,
          loading: true,
          visible: true
        })
      }).catch(() => {
        message.error(t('verification.notification.codeNotSent'))
      })
  }

  const sendCode = () => {
    return postCall(`${process.env.REACT_APP_API_URL}/quote/create-sign-token`, {
      offerId: offerId,
      phoneNumber: phoneNumber
    })
  }

  const validateCode = (code) => {
    if (!code) {
      return
    }

    setNeedsToRetry(false)
    code = code.match(/.{1,3}/g).join('-')
    setModal({
      ...modal,
      confirmLoading: true
    })
    postCall(`${process.env.REACT_APP_API_URL}/quote/sign`, {
      offerId: offerId,
      phoneNumber: phoneNumber,
      code: code
    })
      .then(() => {
        setTimeout(() => {
          setVerified(true)
          setModal({
            ...modal,
            visible: false,
            confirmLoading: false
          })
          onComplete(code)
        }, 1500)
      })
      .catch(() => {
        if (retryLeft > 0) {
          codeInputRef.current.__clearvalues__()
          setNeedsToRetry(true)
          setModal({
            ...modal,
            confirmLoading: false
          })
          setRetryLeft(retryLeft - 1)
        } else {
          setHasCodeError(true)
          setTimeout(() => {
            setModal({
              ...modal,
              visible: false,
              confirmLoading: false
            })
          }, 5000)
        }
      })
  }

  const handleToggleModal = (visibility) => {
    setModal({
      ...modal,
      visible: visibility
    })
  }

  return (
    <>
      {!isVerified && (
        <>
          {requestCounter < 12 && (
            <Button type='primary' style={{ marginLeft: 15 }} onClick={requestCode} disabled={counter > 0 || !isFormValidated}>{counter > 0 ? `${t('forms.buttons.requestNewCode')} ${secondsToCountDown(counter)}` : t('forms.buttons.requestCode')}</Button>
          )}
          <Modal
            className='SendVerificationCodeModal'
            visible={modal.visible}
            onCancel={() => { handleToggleModal(false) }}
            confirmLoading={modal.confirmLoading}
            footer={[
              <Button key='cancel' onClick={() => { handleToggleModal(false) }}>
                {t('forms.buttons.cancel')}
              </Button>
            ]}
          >
            <Title level={4}>{t('verification.modal.title')}</Title>
            <p>{MarkupEscape('verification.modal.text', { phoneNumber: phoneNumber })}</p>
            {hasCodeError
              ? (
                <Alert message={MarkupEscape('verification.modal.wrongCode')} type='error' showIcon />
              )
              : (
                <>
                  {needsToRetry && (
                    <p><Icon type='close-circle' theme='filled' style={{ color: 'red', marginRight: '10px' }} />{MarkupEscape('verification.modal.retry', { count: (retryLeft + 1), retryCount: (retryLeft + 1).toString() })}</p>
                  )}
                  <ReactCodeInput
                    type='number'
                    fields={6}
                    onComplete={validateCode}
                    className='input-code'
                    autoFocus={modal.visible}
                    loading={modal.confirmLoading}
                    ref={codeInputRef}
                  />
                  <p className='mb-0 mt-4' style={{ lineHeight: 1.3 }}><small>{MarkupEscape('verification.modal.codeNotReceived')}</small></p>
                </>
              )}
          </Modal>
        </>
      )}
    </>
  )
}

export default VerificationCode
