var Model = require('./model');

var yup = require('yup');

var definition = {
  offerId: yup.string().ensure().required(),
  firstName: yup.string().max(100).ensure().required(),
  lastName: yup.string().max(100).ensure().required(),
  email: yup.string().max(100).ensure().email().required(),
  additionalInsuree: yup.bool().default(false),
  firstName2: yup.string().max(100).label('firstName').when('additionalInsuree', function (g, schema) {
    return g ? schema.ensure().required() : schema;
  }),
  lastName2: yup.string().max(100).label('lastName').when('additionalInsuree', function (g, schema) {
    return g ? schema.ensure().required() : schema;
  })
};
module.exports = new Model(definition);