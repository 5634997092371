import React, { useState } from 'react'
import { Button } from 'antd'

const ShowMore = ({ text, previewLength = 200 }) => {
  const [expanded, setExpanded] = useState(false)
  let intro = null

  if (text.length > previewLength) {
    intro = `${text.slice(0, previewLength)}...`
  }

  return (
    intro && !expanded
      ? (
        <>
          {intro} <Button size='small' className='ml-2' style={{ fontSize: '0.8rem' }} onClick={() => setExpanded(expanded => !expanded)}>Read more</Button>
        </>
      )
      : text
  )
}

export default ShowMore
