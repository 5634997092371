var yup = require('yup');

var data = require('../data');

var _require = require('../common/country-codes'),
    countries = _require.countries;

var countryCodes = Object.keys(countries);
var definition = {
  isPolicyHolder: yup.bool().required(),
  // insurees
  title: yup.string().required().oneOf(['mr', 'mrs', 'ms']),
  firstName: yup.string().max(100).ensure().required(),
  lastName: yup.string().max(100).ensure().required(),
  placeOfBirth: yup.string().max(100).ensure().required(),
  nationalityCode: yup.string().required().lowercase().oneOf(countryCodes),
  personalIdCardNumber: yup.string().max(100).ensure().required(),
  personalIdValidUntil: yup.date().required(),
  civilStatus: yup.string().required().lowercase().oneOf(data.civilStatuses),
  partnerName: yup.string().max(100).when('civilStatus', partnerNameRequired),
  profession: yup.string().max(100).ensure().required(),
  socialStatus: yup.string().required().lowercase().oneOf(['employee', 'self-employed']),
  email: yup.string().max(100).ensure().email().lowercase().trim().when('isPolicyHolder', function (v, s) {
    return v ? s.required() : s;
  }),
  phoneNumber: yup.object({
    prefix: yup.string().required(),
    number: yup.number().required()
  }).default(undefined).required(),
  address: yup.object({
    streetNumber: yup.string().max(100).ensure().required(),
    streetName: yup.string().max(200).ensure().required(),
    postalCode: yup.string().ensure().required(),
    city: yup.string().max(100).ensure().required(),
    countryCode: yup.string().ensure().required().lowercase().oneOf(['be', 'fr', 'de', 'lu'])
  }).default(undefined).required(),
  isMoving: yup.bool().default(false).when('isPolicyHolder', function (v, s) {
    return v ? s.required() : s;
  }),
  futureAddressAsOf: yup.date().when('isMoving', function (v, s) {
    return v ? s.required() : s;
  }),
  futureAddress: yup.object({
    streetNumber: yup.string().max(100).ensure().required(),
    streetName: yup.string().max(200).ensure().required(),
    postalCode: yup.string().ensure().required(),
    city: yup.string().max(100).ensure().required(),
    countryCode: yup.string().lowercase().oneOf(['be', 'fr', 'de', 'lu']).ensure().required()
  }).default(undefined).when('isMoving', function (a, s) {
    return a ? s.required() : s;
  })
};
module.exports = {
  definition: definition,
  schema: yup.object(definition).camelCase()
};

function partnerNameRequired(val, schema) {
  if (val === data.civilStatus.married || val === data.civilStatus.partnered) {
    return schema.ensure().required();
  }

  return schema;
}