import React from 'react'
import SubscriptionPersonalDetail from './SubscriptionPersonalDetails'

const SubscriptionPersonalDetailsBis = ({ insuree, handlePreviousStep, handleNextStep }) => {
  return (
    <SubscriptionPersonalDetail insuree={insuree} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
  )
}

export default SubscriptionPersonalDetailsBis
