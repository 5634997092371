import React, { useState } from 'react'
import { Button, Select } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { t, getCurrentLocale, generateWidth, isDesktop } from './../../../utils/helpers'
import { postCall } from './../../../utils/ApiCall'
import moment from 'moment'
import MaskedInput from 'antd-mask-input'
import NumberFormat from 'react-number-format'
import ErrorMessage from './../../frontend/common/ErrorMessage'
import CodeHighlight from './../../frontend/common/CodeHighlight'
import { useTrackerContext } from './../../../context/TrackerContext'
const validationSchema = require('./../../../utils/validation')

const dateFormat = 'DD/MM/YYYY'
const QuoteForm = ({ setOffers, handleLoading, loading }) => {
  const { trackEvent } = useTrackerContext()
  const currentLocale = getCurrentLocale()
  const [fieldWidth, setFieldWidth] = useState({
    dateOfBirth: generateWidth(8),
    dateOfBirth2: generateWidth(8),
    isSmoker: generateWidth(6),
    isSmoker2: generateWidth(6),
    additionalInsuree: generateWidth(6),
    capital: generateWidth(8),
    interestRate: generateWidth(4),
    duration: generateWidth(2),
    interval: generateWidth(5),
    effectiveDate: generateWidth(8)
  })

  const actions = isDesktop() ? ['focus', 'click'] : ['click']

  const defaultValues = {
    dateOfBirth: '',
    dateOfBirth2: '',
    isSmoker: '',
    isSmoker2: '',
    additionalInsuree: '',
    capital: '',
    interestRate: '',
    duration: '',
    interval: '',
    effectiveDate: moment().utc(true).add(1, 'days').format(dateFormat)
  }

  const { watch, setValue, handleSubmit, errors, formState, setError, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validateCriteriaMode: 'firstErrorDetected',
    validationSchema: validationSchema.quoteValidation,
    submitFocusError: true,
    defaultValues: defaultValues
  })
  const values = watch()

  const needsAdditionalFields = watch('additionalInsuree') === 'true'

  const options = {
    additionalInsuree: [
      {
        value: 'false',
        label: t('forms.fields.additionalInsuree.options.false')
      },
      {
        value: 'true',
        label: t('forms.fields.additionalInsuree.options.true')
      }
    ],
    isSmoker: [
      {
        value: 'false',
        label: t('forms.fields.isSmoker.options.false')
      },
      {
        value: 'true',
        label: t('forms.fields.isSmoker.options.true')
      }
    ],
    isSmoker2: [
      {
        value: 'false',
        label: t('forms.fields.isSmoker2.options.false')
      },
      {
        value: 'true',
        label: t('forms.fields.isSmoker2.options.true')
      }
    ],
    interval: [
      {
        value: 'annual',
        label: t('forms.fields.interval.options.annual').toLowerCase()
      },
      {
        value: 'twice-yearly',
        label: t('forms.fields.interval.options.twice-yearly').toLowerCase()
      },
      {
        value: 'quarterly',
        label: t('forms.fields.interval.options.quarterly').toLowerCase()
      },
      {
        value: 'monthly',
        label: t('forms.fields.interval.options.monthly').toLowerCase()
      }
    ]
  }

  const setFormNumberValue = (name, values, limit = null) => {
    setValue(name, values.floatValue, false)

    if (limit) {
      if (limit.min) {
        checkMin(name, values, limit.min)
      }

      if (limit.max) {
        checkMax(name, values, limit.max)
      }
    }
  }

  const checkMax = (name, values, max) => {
    if (values.floatValue > max) {
      values.floatValue = Number(max)
      setFormNumberValue(name, values)
    }
  }

  const checkMin = (name, values, min) => {
    if (values.floatValue < min) {
      values.floatValue = min
      setFormNumberValue(name, values)
    }
  }

  const onSelectChange = (name, e) => {
    const newWidth = generateWidth(e.props.children)
    setFieldWidth({
      ...fieldWidth,
      [name]: newWidth
    })
  }

  const onSubmit = data => {
    handleLoading(true)
    const value = { ...data }

    postCall(`${process.env.REACT_APP_API_URL}/quote/calculate`, value)
      .then(response => {
        setOffers(value, response)
        trackEvent('Quote calculated', { ...value, language: currentLocale })
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 400) {
            for (const [key, value] of Object.entries(error.response.data.validationState)) {
              setError(key, value.message)
            }
          }
        }
      }).finally(() => {
        handleLoading(false)
      })
  }

  const generateOptions = (name) => {
    return options[name].reduce((acc, item) => {
      return acc.concat(<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
    }, [])
  }

  const fields = {
    dateOfBirth: (
      <Controller
        as={MaskedInput}
        name='dateOfBirth'
        tabIndex='0'
        control={control}
        onChange={([e]) => e.target.value}
        mask='11/11/1111'
        inputMode='numeric'
        placeholderChar=' '
        placeholder='  /  /    '
        style={{ width: fieldWidth.dateOfBirth }}
        className='narative-input'
        defaultValue={defaultValues.dateOfBirth}
        value={values.dateOfBirth}
      />
    ),
    dateOfBirth2: (
      <Controller
        as={MaskedInput}
        name='dateOfBirth2'
        control={control}
        onChange={([e]) => e.target.value}
        mask='11/11/1111'
        inputMode='numeric'
        placeholderChar=' '
        placeholder='  /  /    '
        style={{ width: fieldWidth.dateOfBirth2 }}
        className='narative-input'
        defaultValue={defaultValues.dateOfBirth2}
        value={values.dateOfBirth2}
      />
    ),
    isSmoker: (
      <Controller
        as={(<Select>{generateOptions('isSmoker')}</Select>)}
        name='isSmoker'
        control={control}
        onChange={([e, target]) => {
          onSelectChange('isSmoker', target)
          return e
        }}
        style={{ width: fieldWidth.isSmoker }}
        className='narative-input'
        defaultValue={defaultValues.isSmoker}
        value={values.isSmoker}
        showAction={actions}
      />
    ),
    isSmoker2: (
      <Controller
        as={(<Select>{generateOptions('isSmoker2')}</Select>)}
        name='isSmoker2'
        control={control}
        onChange={([e, target]) => {
          onSelectChange('isSmoker2', target)
          return e
        }}
        style={{ width: fieldWidth.isSmoker2 }}
        className='narative-input'
        defaultValue={defaultValues.isSmoker2}
        value={values.isSmoker2}
        showAction={actions}
      />
    ),
    additionalInsuree: (
      <Controller
        as={(<Select>{generateOptions('additionalInsuree')}</Select>)}
        name='additionalInsuree'
        control={control}
        onChange={([e, target]) => {
          onSelectChange('additionalInsuree', target)
          return e
        }}
        style={{ width: fieldWidth.additionalInsuree }}
        className='narative-input'
        defaultValue={defaultValues.additionalInsuree}
        value={values.additionalInsuree}
        showAction={actions}
      />
    ),
    capital: (
      <Controller
        as={NumberFormat}
        name='capital'
        control={control}
        onChange={([e]) => e.floatValue}
        onChangeName='onValueChange'
        style={{ width: fieldWidth.capital }}
        className='narative-input'
        defaultValue={defaultValues.capital}
        value={values.capital}
        inputMode='numeric'
        thousandSeparator=' '
        decimalScale={2}
        decimalSeparator=','
      />
    ),
    interestRate: (
      <Controller
        as={NumberFormat}
        name='interestRate'
        control={control}
        onChange={([e]) => e.floatValue}
        onChangeName='onValueChange'
        style={{ width: fieldWidth.interestRate }}
        className='narative-input'
        defaultValue={defaultValues.interestRate}
        value={values.interestRate}
        inputMode='numeric'
        decimalScale={2}
        decimalSeparator=','
        maxLength={5}
      />
    ),
    duration: (
      <Controller
        as={NumberFormat}
        name='duration'
        control={control}
        onChange={([e]) => e.floatValue}
        onChangeName='onValueChange'
        style={{ width: fieldWidth.duration }}
        className='narative-input'
        defaultValue={defaultValues.duration}
        value={values.duration}
        inputMode='numeric'
        decimalScale={0}
        maxLength={2}
      />
    ),
    interval: (
      <Controller
        as={(<Select>{generateOptions('interval')}</Select>)}
        name='interval'
        control={control}
        onChange={([e, target]) => {
          onSelectChange('interval', target)
          return e
        }}
        style={{ width: fieldWidth.interval }}
        className='narative-input'
        defaultValue={defaultValues.interval}
        value={values.interval}
        showAction={actions}
      />
    ),
    effectiveDate: (
      <Controller
        as={MaskedInput}
        name='effectiveDate'
        control={control}
        onChange={([e]) => e.target.value}
        mask='11/11/1111'
        inputMode='numeric'
        placeholderChar=' '
        placeholder='  /  /    '
        style={{ width: fieldWidth.effectiveDate }}
        className='narative-input'
        defaultValue={defaultValues.effectiveDate}
        value={values.effectiveDate}
      />
    )
  }

  return (
    <>
      <form className='QuoteForm' onSubmit={handleSubmit(onSubmit)}>
        <div className='narative-sentences'>
          <div>{t('forms.quote.policyHolder', { returnObject: true })[0]} {fields.dateOfBirth} {t('forms.quote.policyHolder', { returnObject: true })[1]} {fields.isSmoker}.</div>
          {(errors.dateOfBirth || errors.isSmoker) && (
            <ul className='errors-wrapper mt-3'>
              {errors.dateOfBirth && (<li className='error'><ErrorMessage errors={errors} name='dateOfBirth' /></li>)}
              {errors.isSmoker && (<li className='error'><ErrorMessage errors={errors} name='isSmoker' /></li>)}
            </ul>
          )}
          <div>{t('forms.quote.forWhom', { returnObject: true })[0]} {fields.additionalInsuree}.</div>
          {errors.additionalInsuree && (
            <ul className='errors-wrapper mt-3'>
              <li className='error'><ErrorMessage errors={errors} name='additionalInsuree' /></li>
            </ul>
          )}
        </div>
        {needsAdditionalFields && (
          <div className='narative-sentences'>
            <div>{t('forms.quote.additionalInsuree', { returnObject: true })[0]} {fields.dateOfBirth2} {t('forms.quote.additionalInsuree', { returnObject: true })[1]} {fields.isSmoker2}.</div>
            {(errors.dateOfBirth2 || errors.isSmoker2) && (
              <ul className='errors-wrapper mt-3'>
                {errors.dateOfBirth2 && (<li className='error'><ErrorMessage errors={errors} name='dateOfBirth2' /></li>)}
                {errors.isSmoker2 && (<li className='error'><ErrorMessage errors={errors} name='isSmoker2' /></li>)}
              </ul>
            )}
          </div>
        )}
        <div className='narative-sentences'>
          <div>{t('forms.quote.loan', { returnObject: true })[0]} {fields.capital} {t('forms.quote.loan', { returnObject: true })[1]} {fields.interestRate} {t('forms.quote.loan', { returnObject: true })[2]} {fields.duration} {t('forms.quote.loan')[3]}</div>
          {(errors.capital || errors.interestRate || errors.duration) && (
            <ul className='errors-wrapper mt-3'>
              {errors.capital && (<li className='error'><ErrorMessage errors={errors} name='capital' /></li>)}
              {errors.interestRate && (<li className='error'><ErrorMessage errors={errors} name='interestRate' /></li>)}
              {errors.duration && (<li className='error'><ErrorMessage errors={errors} name='duration' /></li>)}
            </ul>
          )}
        </div>
        <div className='narative-sentences'>
          <div>{t('forms.quote.reimbursment', { returnObject: true })[0]} {fields.interval}{currentLocale === 'en' ? (<>{t('forms.quote.reimbursment', { returnObject: true })[1]}</>) : '.'}</div>
          {errors.interval && (
            <ul className='errors-wrapper mt-3'>
              <li className='error'><ErrorMessage errors={errors} name='interval' /></li>
            </ul>
          )}
        </div>
        <div className='narative-sentences'>
          <div>{t('forms.quote.effectiveDate', { returnObject: true })[0]} {fields.effectiveDate}.</div>
          {errors.effectiveDate && (
            <ul className='errors-wrapper mt-3'>
              <li className='error'><ErrorMessage errors={errors} name='effectiveDate' /></li>
            </ul>
          )}
        </div>
        <Button className='narative-submit' type='primary' htmlType='submit' size='large' loading={loading}>{loading ? t('forms.buttons.calculating') : formState.submitCount > 1 && Object.keys(errors).length === 0 ? t('forms.buttons.recalculate') : t('forms.buttons.calculate')}</Button>
      </form>
      {process.env.NODE_ENV === 'development' && (
        <CodeHighlight code={values} />
      )}
    </>
  )
}

export default QuoteForm
