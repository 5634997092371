import React from 'react'
import { Route } from 'react-router-dom'
import PublicLayout from '../components/frontend/common/AppLayout'

const PublicRoute = ({ component: Component, minimalLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => (
        <PublicLayout minimalLayout={minimalLayout}>
          <Component {...routeProps} />
        </PublicLayout>
      )}
    />
  )
}

export default PublicRoute
