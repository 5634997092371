import React, { useState, useRef } from 'react'
import Seo from '../common/Seo'
import { Link } from 'react-router-dom'
import { Typography, Row, Col, Card, Button } from 'antd'
import { t, MarkupEscape, getCurrentLocale, currencyFormat } from '../../../utils/helpers'
import QuoteForm from '../forms/QuoteForm'
import SendQuoteByEmailForm from './../../frontend/forms/SendQuoteByEmailForm'
import scrollToComponent from 'react-scroll-to-component'
import * as moment from 'moment'
import 'moment/locale/fr'

const QuotePage = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [offers, setOffers] = useState({
    offerId: '',
    paymentDuration: '',
    offers: []
  })
  const offerEl = useRef(null)
  const { Title } = Typography
  const currentLocale = getCurrentLocale()
  moment.locale(currentLocale)

  const handleLoading = (newLoadingState) => {
    setLoading(newLoadingState)
  }

  const handleGetOffers = (data, response) => {
    const newOffersObj = {
      offerId: response.offerId,
      paymentDuration: response.paymentDuration,
      offers: []
    }

    response.offers.forEach(offer => {
      newOffersObj.offers.push(offer)
    })
    setOffers(newOffersObj)
    setData(data)
    setLoading(false)
    scrollToComponent(offerEl.current, { offset: 0, align: 'top', duration: 800 })
  }

  const Plan = ({ plan, offerId, paymentDuration }, props) => {
    return (
      <div className='Plan'>
        <div className='banner'><span>{t(`plans.${plan.label}.plus`)}</span></div>
        <Card {...props} className='light' bordered={false}>
          <Title level={3} className='mb-2'>{MarkupEscape(`plans.${plan.label}.name`)}</Title>
          {plan.isValid && (
            <>
              {plan.premium && (
                <p className='lead'>{MarkupEscape(`plans.${plan.label}.price`, { price: currencyFormat.format(plan.premium) })}</p>
              )}
              <div className='mt-4 mb-5'>
                {plan.label === 'once'
                  ? (
                    <p>{MarkupEscape(`plans.${plan.label}.reimbursment`)}</p>
                  )
                  : (
                    <p>{MarkupEscape(`plans.${plan.label}.reimbursment`, { premium: currencyFormat.format(plan.premiumTotal), paymentDuration: paymentDuration })}</p>
                  )}
                <p>{MarkupEscape(`plans.${plan.label}.coverPeriod`, { coverageEndDate: moment(plan.coverageEndDate).format('MMMM YYYY') })}</p>
                {plan.label === 'once' && (
                  <p>{MarkupEscape(`plans.${plan.label}.fiscality`)}</p>
                )}
              </div>
            </>
          )}
          {plan.isValid
            ? (
              <Link to={`/${currentLocale}/subscribe/${offerId}/${plan.label}`}>
                <Button type='primary' size='large' block>{t('quote.offers.subscribe')}</Button>
              </Link>
            ) : (
              <small className='text-center mt-auto'>{t(`plans.${plan.label}.notAvailable`)}</small>
            )}
        </Card>
      </div>
    )
  }

  let plans = null
  if (offers.offers.length > 0) {
    plans = (
      <div className='offers' ref={offerEl}>
        <div className='section-title mb-5'>
          <Title level={2}>{MarkupEscape('quote.offers.title')}</Title>
          <SendQuoteByEmailForm values={data} offers={offers} />
        </div>
        <Row type='flex'>
          {offers.offers.map((plan, index) => {
            return (
              <Col className={index > 0 ? 'mt-5 mt-md-0' : null} key={index} xs={12} md={4}>
                <Plan plan={plan} offerId={offers.offerId} paymentDuration={offers.paymentDuration} />
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }

  return (
    <>
      <Seo pageName='quote' />
      <section>
        <div className='container'>
          <Row type='flex' justify='center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <span className='upper-title'>{t('menu.quote')}</span>
              <Title>{MarkupEscape('quote.title')}</Title>

              <QuoteForm handleLoading={handleLoading} loading={loading} setOffers={(data, response) => { handleGetOffers(data, response) }} />
            </Col>
          </Row>
        </div>
      </section>
      {!loading && offers.offers.length > 0 && (
        <section className='secondary-light'>
          <div className='container'>
            <Row type='flex' justify='center'>
              <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                {plans}
              </Col>
            </Row>
          </div>
        </section>
      )}
    </>
  )
}

export default QuotePage
