import React, { useState, useEffect } from 'react'
import {
  Typography,
  Form,
  Radio,
  Input,
  Row,
  Col,
  Icon,
  Button,
  Spin
} from 'antd'
import { useForm, Controller } from 'react-hook-form'
import ErrorMessage from './../common/ErrorMessage'
import { t, keyExists } from './../../../utils/helpers'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from './../../../utils/subscribeFieldsModel'
import moment from 'moment'
const validationSchema = require('./../../../utils/validation')

const SubscriptionMedicalDeclaration = ({
  insuree,
  handlePreviousStep,
  handleNextStep
}) => {
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const [loading, setLoading] = useState(true)
  const stepValidationSchema = validationSchema.subscibeStep6Validation
  const insureeIndex = insuree - 1
  const { Title } = Typography
  const defaultValues = generateStepData(
    'medicalDeclaration',
    subscribeFormData.medicalDeclaration[insureeIndex]
  )
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />

  const { unregister, formState, handleSubmit, errors, watch, control } = useForm(
    {
      validateCriteriaMode: 'firstErrorDetected',
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      validationSchema: stepValidationSchema,
      defaultValues: defaultValues
    }
  )

  const values = watch({ nest: true })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [])

  const onSubmit = values => {
    setLoading(true)
    Object.keys(values).forEach(key => {
      if (key.includes('StartDate')) {
        values[key] = moment(values[key], 'YYYY-MM-DD')
          .utc(true)
          .toISOString()
      }
    })

    const medicalDeclaration = [...subscribeFormData.medicalDeclaration]
    medicalDeclaration[insureeIndex] = {
      ...values
    }

    setSubscribeFormData({
      ...subscribeFormData,
      medicalDeclaration: medicalDeclaration
    })

    handleNextStep()
  }

  const handleRadioChange = (name, value) => {
    if (!value) {
      if (`${name}Explanation` in values) {
        unregister(`${name}Explanation`)
      }

      if (`${name}StartDate` in values) {
        unregister(`${name}StartDate`)
      }
    }
  }

  const generateField = (propertyName, options = null) => {
    if (!options) {
      options = {
        startDate: false,
        hint: false
      }
    }
    return (
      <Row className='mb-4' type='flex' align='middle'>
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <p className='mb-0 primary text-small'>
            {t(`forms.fields.${propertyName}.label`)}
          </p>
          {options.hint && (
            <p className='hint mb-0 mt-2'>
              <small>{t(`forms.fields.${propertyName}.hint`)}</small>
            </p>
          )}
        </Col>
        <Col
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Controller
            as={
              <Radio.Group buttonStyle='solid' size='small'>
                <Radio.Button value>{t('global.yes')}</Radio.Button>
                <Radio.Button value={false}>{t('global.false')}</Radio.Button>
              </Radio.Group>
            }
            defaultValue={defaultValues[propertyName]}
            value={values[propertyName]}
            name={propertyName}
            onChange={([e]) => {
              handleRadioChange(propertyName, e.target.value)
              return e.target.value
            }}
            control={control}
          />
        </Col>
        <Col xs={12}>
          <ErrorMessage
            className='mt-3'
            errors={errors}
            name={`${propertyName}`}
          />
        </Col>
        {values[propertyName] && (
          <div className='mt-3' style={{ width: '100%' }}>
            {options.startDate && (
              <Col xs={12} sm={6} md={6} lg={5} xl={5}>
                <Form.Item
                  label={
                    keyExists(`forms.fields.${propertyName}StartDate.label`)
                      ? t(`forms.fields.${propertyName}StartDate.label`)
                      : t('forms.fields.affections.startDate.label')
                  }
                  {...(values[propertyName] ? { required: true } : {})}
                >
                  <Controller
                    as={Input}
                    type='date'
                    name={`${propertyName}StartDate`}
                    control={control}
                    defaultValue={
                      defaultValues[`${propertyName}StartDate`] !== '' &&
                        moment(
                          defaultValues[`${propertyName}StartDate`]
                        ).isValid()
                        ? moment(
                          defaultValues[`${propertyName}StartDate`]
                        ).format('YYYY-MM-DD')
                        : defaultValues[`${propertyName}StartDate`]
                    }
                  />
                </Form.Item>
                <ErrorMessage
                  className='mt-3'
                  errors={errors}
                  name={`${propertyName}StartDate`}
                />
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={10} xl={10}>
              <Form.Item
                label={
                  keyExists(`forms.fields.${propertyName}Explanation.label`)
                    ? t(`forms.fields.${propertyName}Explanation.label`)
                    : t('forms.fields.affections.explanation.label')
                }
                {...(values[propertyName] ? { required: true } : {})}
              >
                <Controller
                  as={Input.TextArea}
                  maxLength={2000}
                  autoSize={{ minRows: 2 }}
                  name={`${propertyName}Explanation`}
                  control={control}
                  defaultValue={defaultValues[`${propertyName}Explanation`]}
                />
                <ErrorMessage
                  className='mt-3'
                  errors={errors}
                  name={`${propertyName}Explanation`}
                />
              </Form.Item>
            </Col>
          </div>
        )}
      </Row>
    )
  }

  return (
    <form
      className='SubscribeMedicalDeclaration'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='frame-content'>
        {loading ? (
          <div className='container loader-wrapper'>
            <Row type='flex' justify='center' align='middle'>
              <Spin indicator={icon} tip={t('global.loading')} />
            </Row>
          </div>
        )
          : (
            <>
              <div className='frame-section'>
                <Title level={3}>
                  {t(
                    'forms.steps.subscribe.medicalDeclaration.sections.general.title'
                  )}
                </Title>
                {generateField('alcoholConsumption')}
                {generateField('drugConsumption')}
                {generateField('hasTreatment', { hint: true })}
                {generateField('hasMedicalAppointment')}
                {generateField('hasHospitalAppointment', { hint: true })}
                {generateField('hasIncapacityOfWork')}
              </div>
              <div className='frame-section'>
                <Title level={3}>
                  {t(
                    'forms.steps.subscribe.medicalDeclaration.sections.affections.title'
                  )}
                </Title>
                <p>
                  {t(
                    'forms.steps.subscribe.medicalDeclaration.sections.affections.subtitle'
                  )}
                </p>
                <p className='mb-4'>
                  {t(
                    'forms.steps.subscribe.medicalDeclaration.sections.affections.hint'
                  )}
                </p>
                {generateField('hasCardiovascularDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasMetabolismDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasDiabetesDisease', { startDate: true })}
                {generateField('hasCancerDisease', { startDate: true })}
                {generateField('hasPsychologicalDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasRespiratoryDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasDigestiveSystemDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasNervousSystemDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasBloodDisease', { startDate: true, hint: true })}
                {generateField('hasMusclesDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasInfectionDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasSkinDisease', { startDate: true, hint: true })}
                {generateField('hasKidneysDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasGenitalsDisease', {
                  startDate: true,
                  hint: true
                })}
                {generateField('hasEntDisease', { startDate: true, hint: true })}
                {generateField('hasEyesDisease', { startDate: true, hint: true })}
                {generateField('hasOtherDisease', { startDate: true })}
              </div>
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button onClick={handlePreviousStep}>{t('global.previous')}</Button>
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginLeft: 15 }}
            loading={formState.isSubmitting}
            disabled={loading}
          >
            {t('global.next')}
          </Button>
        </div>
      )}
    </form>
  )
}

export default SubscriptionMedicalDeclaration
