import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory, Prompt } from 'react-router-dom'
import { Typography, Row, Col, message, Progress } from 'antd'
import { postCall } from '../../../utils/ApiCall'
import SubscriptionRecap from '../forms/SubscriptionRecap'
import SubscriptionPersonalDetails from '../forms/SubscriptionPersonalDetails'
import SubscriptionPersonalDetailsBis from '../forms/SubscriptionPersonalDetailsBis'
import SubscriptionLoanDetails from '../forms/SubscriptionLoanDetails'
import SubscriptionMedicalDeclaration from '../forms/SubscriptionMedicalDeclaration'
import SubscriptionMedicalDeclarationBis from '../forms/SubscriptionMedicalDeclarationBis'
import SubscriptionGoodHealthDeclaration from '../forms/SubscriptionGoodHealthDeclaration'
import SubscriptionLastStep from '../forms/SubscriptionLastStep'
import CodeHighlight from '../common/CodeHighlight'
import { t, getCurrentLocale, getCountryPrefix } from '../../../utils/helpers'
import { generateSubscribeData } from '../../../utils/subscribeFieldsModel'
import { useSubscribeFormContext, useSubscribeStepContext } from '../../../context/SubscribeContext'
import { useLocaleSwitchContext } from '../../../context/LocaleSwitchContext'
import scrollToComponent from 'react-scroll-to-component'
import Seo from '../common/Seo'
import { objectToFormData } from 'object-to-formdata'
import cleanDeep from 'clean-deep'
import { useTrackerContext } from './../../../context/TrackerContext'

const Subscription = () => {
  const { trackEvent } = useTrackerContext()
  const { offerId, selectedPlan } = useParams()
  const [offer, setOffer] = useState({})
  const [submited, setSubmited] = useState(false)
  const [callError, setCallError] = useState(false)
  const [lastCurrentStep, setLastCurrentStep] = useState(null)
  const { currentStep, steps, setSteps, progress, next, previous, reset } = useSubscribeStepContext()
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const { localeSwitching } = useLocaleSwitchContext()
  const { Title } = Typography
  const topEl = useRef(null)
  const history = useHistory()
  const currentLocale = getCurrentLocale()

  const handleNextStep = useCallback(
    () => {
      scrollToComponent(topEl.current, { offset: 0, align: 'top', duration: 400 })
      next()
    },
    [next]
  )

  const handlePreviousStep = useCallback(
    () => {
      scrollToComponent(topEl.current, { offset: 0, align: 'top', duration: 400 })
      previous()
    },
    [previous]
  )

  useEffect(() => {
    if (currentStep > 0) {
      if (currentStep <= lastCurrentStep) {
        trackEvent(`Subscription - Going back to step ${lastCurrentStep}`)
      } else {
        trackEvent(`Subscription - Completed step ${currentStep}`)
      }
    } else {
      if (currentStep < lastCurrentStep) {
        trackEvent('Subscription - Going back to first step')
      }
    }
    setLastCurrentStep(currentStep)
  }, [
    currentStep
  ])

  const handleProceedSubscription = useCallback(
    (data, offer) => {
      data.insurees[0].phoneNumber.prefix = getCountryPrefix(data.insurees[0].phoneNumber.prefix)
      if (data.additionalInsuree) {
        data.insurees[1].phoneNumber.prefix = getCountryPrefix(data.insurees[1].phoneNumber.prefix)
      }
      const values = new FormData()
      data.personalIdFile.forEach(file => {
        values.append('personalIdFile', file)
      })
      delete data.personalIdFile

      postCall(`${process.env.REACT_APP_API_URL}/quote/subscribe`, objectToFormData(cleanDeep(data), {
        indices: true
      }, values))
        .then((response) => {
          setSubmited(true)
          trackEvent('Subscription completed')
          history.push(`/${currentLocale}/subscribe/success`, { data: { ...data, ...response }, offer: offer })
        }).catch(err => {
          if (err.response) {
            message.error(err.response.data.message, 2)
          }
        })
    },
    [
      trackEvent,
      currentLocale,
      history
    ]
  )

  const generateSteps = useCallback(
    (offer) => {
      setSteps([
        {
          name: 'recap',
          content: <SubscriptionRecap offer={offer} plan={selectedPlan} handleNextStep={handleNextStep} />
        },
        {
          name: 'personalDetails',
          content: <SubscriptionPersonalDetails insuree={1} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        },
        ...(offer.recap.additionalInsuree ? [{
          name: 'personalDetailsBis',
          content: <SubscriptionPersonalDetailsBis insuree={2} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        }] : []),
        {
          name: 'loanDetails',
          content: <SubscriptionLoanDetails handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        },
        {
          name: 'goodHealthDeclaration',
          content: <SubscriptionGoodHealthDeclaration handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        },
        ...(offer.medicalDeclarations.policyHolder ? [{
          name: 'medicalDeclaration',
          content: <SubscriptionMedicalDeclaration insuree={1} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        }] : []),
        ...((offer.recap.additionalInsuree && offer.medicalDeclarations.additionalInsuree) ? [{
          name: 'medicalDeclarationBis',
          content: <SubscriptionMedicalDeclarationBis insuree={2} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
        }] : []),
        {
          name: 'lastStep',
          content: <SubscriptionLastStep offer={offer} offerId={offerId} handlePreviousStep={handlePreviousStep} handleProceedSubscription={handleProceedSubscription} />
        }
      ].filter(x => x !== null))
    },
    [
      setSteps,
      handleNextStep,
      handlePreviousStep,
      handleProceedSubscription,
      offerId,
      selectedPlan
    ]
  )

  useEffect(() => {
    const getOffer = () => {
      setTimeout(() => {
        postCall(`${process.env.REACT_APP_API_URL}/quote/find`, {
          offerId: offerId
        })
          .then(offer => {
            setOffer(offer)
            generateSteps(offer)
            trackEvent('Subscription - Started', { offerId: offerId, plan: selectedPlan })
            setSubscribeFormData(
              generateSubscribeData({
                ...offer.recap,
                medicalDeclarations: { ...offer.medicalDeclarations },
                offerId: offerId,
                plan: selectedPlan
              })
            )
          }).catch(err => {
            if (err.response) {
              message.error(err.response.data.message, 2)
            }
            setTimeout(() => {
              setCallError(true)
              history.push(`/${currentLocale}/quote`)
            }, 200)
          })
      }, 1000)
    }

    if (!Object.keys(offer).length) {
      getOffer()
    }

    return (
      () => {
        setCallError(false)
      }
    )
  }, [
    trackEvent,
    offerId,
    offer,
    currentLocale,
    history,
    selectedPlan,
    setSubscribeFormData,
    generateSteps,
    setSteps
  ])

  const resetForm = useCallback(() => {
    setSubscribeFormData({})
    reset()
  }, [])

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (!submited) {
        event.preventDefault()
        event.returnValue = t('subscribe.unprocessedSubscription')
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)

    return (
      () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
      }
    )
  }, [
    submited
  ])

  useEffect(() => {
    let unblock = null
    let unlisten = null
    if (!submited) {
      unlisten = history.listen((location) => {
        if (!location.pathname.includes('/subscribe/')) {
          unblock = history.block()
        }
      })
    }

    return (
      () => {
        if (!submited) {
          if (unlisten) {
            unlisten()
            unlisten = null
          }
          if (unblock) {
            unblock()
            unblock = null
          }
          resetForm()
        }
      }
    )
  }, [
    submited,
    history
  ])

  return (
    <>
      <Seo pageName='subscribe' />
      <section ref={topEl} className='pb-0'>
        {!submited && !localeSwitching && !callError && (
          <Prompt message={t('subscribe.unprocessedSubscription')} />
        )}
        <div className='container'>
          {steps.length > 0 && (
            <>
              <Progress percent={progress} showInfo={false} />
              <Row type='flex' justify='center'>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                  <span className='upper-title'>{currentStep + 1}/{steps.length}</span>
                  <Title>{t(`forms.steps.subscribe.${steps[currentStep].name}.title`)}</Title>
                  <p>{t(`forms.steps.subscribe.${steps[currentStep].name}.subtitle`)}</p>
                  <div className='frame pb-5'>
                    <div className='frame-inner'>
                      {steps[currentStep].content}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
        {process.env.NODE_ENV === 'development' && (
          <>
            <CodeHighlight code={subscribeFormData} />
          </>
        )}
      </section>
    </>
  )
}

export default Subscription
