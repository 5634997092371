import { useState } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import constate from 'constate'

function useTracker () {
  const [tracker, setTracker] = useState(null)

  const initTracker = (appInsights) => {
    setTracker(appInsights)
  }

  const trackException = (error, properties = {}) => {
    if (tracker) {
      error = error instanceof Error ? error : new Error(error)
      tracker.trackException({ error: error, severityLevel: SeverityLevel.Error }, { ...properties })
    }
  }

  const trackTrace = (message, properties = {}) => {
    if (tracker) {
      tracker.trackTrace({ message: message, severityLevel: SeverityLevel.Information }, { ...properties })
    }
  }

  const trackEvent = (event, properties = {}) => {
    if (tracker) {
      tracker.trackEvent({ name: event }, { ...properties })
    }
  }

  return { initTracker, trackException, trackTrace, trackEvent }
}

export const [TrackerProvider, useTrackerContext] = constate(useTracker)
