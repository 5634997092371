import { isAuthenticated, getToken } from './AuthenticationUtil'

const Api = {
  get: (url) => {
    return Api.request(url, 'GET', undefined)
  },
  post: (url, data, customOptions) => {
    return Api.request(url, 'POST', data, customOptions)
  },
  put: (url, data) => {
    return Api.request(url, 'PUT', data)
  },
  request: (url, method, data, customOptions = {}) => {
    return request(url, data, {
      method
    }, customOptions)
  },
  getBearerToken: () => {
    return isAuthenticated ? { Authorization: `Bearer ${getToken().token}` } : null
  }
}

export default Api

function request (url, data, options, customOptions) {
  url = (url + '').startsWith('/') ? url.slice(1) : url + ''

  options = options || {}

  if (url.startsWith('admin') && isAuthenticated) {
    options.headers = options.headers || {}
    options.headers.Authorization = `Bearer ${getToken().token}`
  }

  if (data !== undefined) {
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json'
  }

  return window.fetch(getApiUrl() + url, options)
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response)
      }
      if (response.status === 204) {
        return null
      }

      return customOptions.isBlob ? response.blob() : response.json()
    })
}

function getApiUrl () {
  return (process.env.REACT_APP_API_URL + '').endsWith('/') ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL + '/'
}
