var civilStatus = Object.freeze({
  single: 'single',
  married: 'married',
  partnered: 'partnered',
  divorced: 'divorced',
  widowed: 'widowed'
});
var gender = {
  male: 'male',
  female: 'female'
};
module.exports = {
  civilStatus: civilStatus,
  civilStatuses: Object.freeze(Object.keys(civilStatus)),
  gender: gender,
  genders: Object.freeze(Object.keys(gender))
};