import React, { useState, useEffect } from 'react'
import { AutoComplete, Button, Typography, Form, Radio, Input, Row, Col, Switch, Icon, Spin } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { t, MarkupEscape } from './../../../utils/helpers'
import ErrorMessage from './../common/ErrorMessage'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from './../../../utils/subscribeFieldsModel'
const validationSchema = require('./../../../utils/validation')

const SubscriptionLoanDetails = ({ handlePreviousStep, handleNextStep }) => {
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const [loading, setLoading] = useState(true)
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />
  const { Title } = Typography

  const defaultValues = {
    additionalInsuree: subscribeFormData.additionalInsuree,
    ...generateStepData('loanDetails', subscribeFormData)
  }

  const stepValidationSchema = validationSchema.subscribeStep4Validation

  const { watch, control, setValue, handleSubmit, errors } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema: stepValidationSchema,
    defaultValues: defaultValues
  })

  const values = watch({ nest: true })

  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [])

  const source = [
    {
      bank: 'Banque de Luxembourg',
      address: '14, Boulevard Royal / L-2449 Luxembourg'
    },
    {
      bank: 'BCEE',
      address: '1, Place de Metz / L-2954 Luxembourg'
    },
    {
      bank: 'BGL BNP Paribas',
      address: '50, Avenue J.F Kennedy / L-2951 Luxembourg'
    },
    {
      bank: 'BIL',
      address: "69, Route d'Esch / L - 2953 Luxemburg"
    },
    {
      bank: 'Deutsche Bank',
      address: '2, Boulevard Konrad Adenauer / L-1115 Luxembourg'
    },
    {
      bank: 'HSBC',
      address: "16, Boulevard d'Avranches / L-1160 Luxembourg"
    },
    {
      bank: 'ING',
      address: '26, Place de la Gare / L-2965 Luxembourg'
    },
    {
      bank: 'Raiffeisen',
      address: '4, rue Léon Laval / L-3372 Leudelange'
    },
    {
      bank: 'Société Générale',
      address: '11, Avenue Emile Reuter / L-2420 Luxembourg'
    }
  ]

  const handleBankSelect = (e) => {
    const [selectedBank] = source.filter(item => item.bank === e)
    if (selectedBank) {
      setValue('benefitTransferAddress', selectedBank.address, true)
    }
  }

  const generateField = (propertyName, customOptions = {}) => {

    const options = {
      label: customOptions.label || true,
      type: customOptions.type || 'text'
    }

    return (
      <>
        <Form.Item label={options.label ? t(`forms.fields.${propertyName}.label`) : null}>
          <Controller
            as={Input}
            value={values[propertyName]}
            name={propertyName}
            control={control}
            type={options.type}
          />
          <ErrorMessage className='mt-3' errors={errors} name={propertyName} />
        </Form.Item>
      </>
    )
  }

  const toggle = (field, value = null, event = null) => {
    const newValue = value !== null ? value : !values[field]

    if (event && event.target.tagName !== 'A') {
      setValue(field, newValue, true)
    }
  }

  const generateSwitch = (propertyName, options = null) => {
    if (!options) {
      options = {
        customLabel: null
      }
    }
    return (
      <Row className='mb-4' type='flex' align='middle'>
        <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            as={Switch}
            name={propertyName}
            control={control}
            defaultChecked={defaultValues[propertyName]}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            valueName='checked'
          />
          <p className='mb-0 ml-3 primary text-small' onClick={(e) => toggle(propertyName, null, e)} style={{ cursor: 'pointer' }}>{options.customLabel ? options.customLabel : MarkupEscape(`forms.fields.${propertyName}.label`)}</p>
        </Col>
        <ErrorMessage className='mb-1' errors={errors} name={propertyName} />
      </Row>
    )
  }

  const radioStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  }

  const onSubmit = (values) => {
    setLoading(true)
    if (values.existingContract === false) {
      values.existingContractPolicyNumber = ''
    }
    if (subscribeFormData.additionalInsuree && values.existingContract2 === false) {
      values.existingContract2PolicyNumber = ''
    }
    if (values.beneficiary !== 'other') {
      values.beneficiaryOther = ''
    }
    setSubscribeFormData({
      ...subscribeFormData,
      ...values
    })
    handleNextStep()
  }

  return (
    <form className='SubscribeLoanDetails' onSubmit={handleSubmit(onSubmit)}>
      <div className='frame-content'>
        {loading ? (
          <div className='container loader-wrapper'>
            <Row type='flex' justify='center' align='middle'>
              <Spin indicator={icon} tip={t('global.loading')} />
            </Row>
          </div>
        ) : (
            <>
              <div className='frame-section'>
                <Title level={4}>{t('forms.steps.subscribe.loanDetails.sections.beneficiary.title')}</Title>
                <Row type='flex' align='top'>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item>
                      <Controller
                        as={(<Radio.Group buttonStyle='solid' size='small'>
                          <Radio style={radioStyle} value='by-law'>
                            {t('forms.fields.beneficiary.options.byLaw')}
                          </Radio>
                          <Radio style={radioStyle} value='other'>
                            {t('forms.fields.beneficiary.options.other')}
                          </Radio>
                        </Radio.Group>)}
                        value={values.beneficiary}
                        name='beneficiary'
                        onChange={([e]) => e.target.value}
                        control={control}
                      />
                      <ErrorMessage className='mt-3' errors={errors} name='beneficiary' />
                    </Form.Item>
                    {values.beneficiary === 'other' && generateField('beneficiaryOther')}
                  </Col>
                </Row>
              </div>
              <div className='frame-section'>
                <Title level={4}>{t('forms.steps.subscribe.loanDetails.sections.profitTransfer.title')}</Title>
                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label={t('forms.fields.benefitTransferInstitution.label')}>
                      <Controller
                        as={AutoComplete}
                        value={values.benefitTransferInstitution}
                        name='benefitTransferInstitution'
                        onSelect={handleBankSelect}
                        dataSource={source.map(item => item.bank)}
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={([e]) => e}
                        control={control}
                      />
                      <ErrorMessage className='mt-3' errors={errors} name='benefitTransferInstitution' />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('benefitTransferFileNumber')}
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {generateField('benefitTransferAddress')}
                  </Col>
                </Row>
              </div>
              <div className='frame-section'>
                <Title level={4}>{t('forms.steps.subscribe.loanDetails.sections.existingContract.title', { count: subscribeFormData.additionalInsuree ? 2 : 1 })}</Title>
                <Row className='mt-3' type='flex' align='top'>
                  <Col style={{ display: 'flex', flexDirection: 'row' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    {generateSwitch('existingContract')}
                  </Col>
                  {values.existingContract && (
                    <Col className='mt-3' xs={12} sm={12} md={6} lg={6} xl={6}>
                      {generateField('existingContractPolicyNumber')}
                    </Col>
                  )}
                </Row>
                {subscribeFormData.additionalInsuree && (
                  <Row className='mt-3' type='flex' align='middle'>
                    <Col style={{ display: 'flex', flexDirection: 'row' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                      {generateSwitch('existingContract2')}
                    </Col>
                    {values.existingContract2 && (
                      <Col className='mt-3' xs={12} sm={12} md={6} lg={6} xl={6}>
                        {generateField('existingContract2PolicyNumber')}
                      </Col>
                    )}
                  </Row>
                )}
              </div>
              <div className='frame-section'>
                <Title level={4}>{t('forms.steps.subscribe.loanDetails.sections.bank.title')}</Title>
                <p className='mb-4'>{t('forms.steps.subscribe.loanDetails.sections.bank.subtitle')}</p>

                <Row type='flex' align='top'>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    {generateField('bankAccountHolder')}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Row type='flex' align='top'>
                      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                        {generateField('bankAccountIban')}
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                        {generateField('bankAccountBic')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button onClick={handlePreviousStep}>{t('global.previous')}</Button>
          <Button type='primary' htmlType='submit' style={{ marginLeft: 15 }}>{t('global.next')}</Button>
        </div>
      )}
    </form>
  )
}

export default SubscriptionLoanDetails
