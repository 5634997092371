import React from 'react'
import countries from 'i18n-iso-countries'
import { Select } from 'antd'
import { getCurrentLocale } from 'src/utils/helpers'

const CountrySelect = ({ priority = null, ...props }) => {
  const { Option } = Select
  const currentLocale = getCurrentLocale()
  countries.registerLocale(require(`i18n-iso-countries/langs/${currentLocale}.json`))
  const countryList = countries.getNames(currentLocale)
  const sortedCountries = Object.keys(countryList).sort((a, b) => priority.includes(b) ? 1 : -1)

  const options = sortedCountries.map(key => (
    <Option key={key.toLowerCase()} value={key.toLowerCase()}>{countryList[key]}</Option>
  ))

  return (
    <Select
      showSearch
      filterOption={(value, option) => option.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0}
      style={{ width: '100%' }}
      {...props}
    >
      {options}
    </Select>
  )
}

export default CountrySelect
