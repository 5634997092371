const QuoteCalculateModel = require('simplomus.contracts/QuoteCalculateModel')
const QuoteSubscribeModel = require('simplomus.contracts/QuoteSubscribeModel')
const QuoteSubscribePersonalModel = require('simplomus.contracts/schemas/PersonalDetails')
const QuoteSubscribeMedicalDeclarationModel = require('simplomus.contracts/schemas/MedicalDeclaration')
const rules = require('simplomus.contracts/rules')
const Model = require('simplomus.contracts/model')
const moment = require('moment')
const iban = require('iban')
const yup = require('yup')

const { isValidMinAge, isValidMaxAge, isFuture, isTodayOrFuture, isValidEffectiveDate, isMaxDuration } = rules.moment(moment)
const { isValidIban } = rules.iban(iban)
const { isValidFile, isValidFileSize, isValidMimeType } = rules.file()

const dateFormat = 'DD/MM/YYYY'

const quoteDefinition = Object.assign({}, QuoteCalculateModel.definition, {
  dateOfBirth: QuoteCalculateModel.definition.dateOfBirth
    .transform(function (_value, originalValue) {
      if (this.isType(originalValue)) {
        return originalValue
      }

      return moment(originalValue, dateFormat).utc(true).toDate()
    })
    .test('age.min', { key: 'age.min' }, isValidMinAge)
    .test('age.max', { key: 'age.max' }, isValidMaxAge),
  dateOfBirth2: QuoteCalculateModel.definition.dateOfBirth2
    .transform(function (_value, originalValue) {
      if (this.isType(originalValue)) {
        return originalValue
      }

      return moment(originalValue, dateFormat).utc(true).toDate()
    })
    .test('age.min', { key: 'age.min' }, isValidMinAge)
    .test('age.max', { key: 'age.max' }, isValidMaxAge),
  effectiveDate: QuoteCalculateModel.definition.effectiveDate
    .transform(function (_value, originalValue) {
      if (this.isType(originalValue)) {
        return originalValue
      }

      return moment(originalValue, dateFormat).utc(true).toDate()
    })
    .test('date.min', { key: 'date.min' }, isTodayOrFuture)
    .test('date.max', { key: 'date.max' }, isValidEffectiveDate),
  duration: QuoteCalculateModel.definition.duration
    .transform(function (value, originalValue) {
      let duration = value
      duration = originalValue * 12 // Years to months
      return duration
    })
    .when(['dateOfBirth', 'dateOfBirth2', 'effectiveDate'], isMaxDuration)
})

const subscribeRecapDefinition = {
  dataVerifiedConsent: QuoteSubscribeModel.definition.dataVerifiedConsent,
  confirmedIpid: QuoteSubscribeModel.definition.confirmedIpid
}

// const subscribeStepToFollowDefinition = {
//   confirmedIpid: yup.bool().default(false).oneOf([true], ({ path }) => ({ key: 'consent', field: path }))
// }

const subscribePersonalDetailsDefinition = Object.assign({}, QuoteSubscribePersonalModel.definition, {
  personalIdValidUntil: QuoteSubscribePersonalModel.definition.personalIdValidUntil
    .test('date.min', { key: 'date.future' }, isFuture),
  personalIdFile: yup.mixed().default('').required()
    .test('file.typeError', { key: 'file.typeError' }, isValidFile)
    .test('file.max', { key: 'file.max' }, isValidFileSize)
    .test('file.extension', { key: 'file.extension' }, isValidMimeType),
  futureAddressAsOf: QuoteSubscribePersonalModel.definition.futureAddressAsOf
    .test('date.min', { key: 'date.future' }, isFuture)
})

const subscribePersonalDetailsBisDefinition = Object.assign({}, subscribePersonalDetailsDefinition)
delete subscribePersonalDetailsBisDefinition.isMoving
delete subscribePersonalDetailsBisDefinition.futureAddressAsOf
delete subscribePersonalDetailsBisDefinition.futureAddress

const subscribeLoanDetailsDefinition = {
  beneficiary: QuoteSubscribeModel.definition.beneficiary,
  beneficiaryOther: QuoteSubscribeModel.definition.beneficiaryOther,
  benefitTransferInstitution: QuoteSubscribeModel.definition.benefitTransferInstitution,
  benefitTransferFileNumber: QuoteSubscribeModel.definition.benefitTransferFileNumber,
  benefitTransferAddress: QuoteSubscribeModel.definition.benefitTransferAddress,
  bankAccountHolder: QuoteSubscribeModel.definition.bankAccountHolder,
  bankAccountIban: QuoteSubscribeModel.definition.bankAccountIban
    .test('iban', { key: 'iban.invalid' }, isValidIban),
  bankAccountBic: QuoteSubscribeModel.definition.bankAccountBic,
  existingContract: QuoteSubscribeModel.definition.existingContract,
  existingContractPolicyNumber: QuoteSubscribeModel.definition.existingContractPolicyNumber,
  existingContract2: yup.bool().when('additionaInsuree', (v, s) => v ? s.required() : s),
  existingContract2PolicyNumber: QuoteSubscribeModel.definition.existingContract2PolicyNumber,
}

const subscribeGoodHealthDeclarationDefinition = {
  additionalInsuree: QuoteSubscribeModel.definition.additionalInsuree,
  goodHealthDeclaration: QuoteSubscribeModel.definition.goodHealthDeclaration
}

const subscribeMedicalDeclarationDefinition = Object.assign({}, QuoteSubscribeMedicalDeclarationModel.definition)

const subscribeLastStepDefinition = {
  cancelsOtherInsurerContract: QuoteSubscribeModel.definition.cancelsOtherInsurerContract,
  guaranteesMortgageLoan: QuoteSubscribeModel.definition.guaranteesMortgageLoan,
  correctDataCheck: QuoteSubscribeModel.definition.correctDataCheck,
  termsCheck: QuoteSubscribeModel.definition.termsCheck,
  dataProtectionCheck: QuoteSubscribeModel.definition.dataProtectionCheck,
  dataProcessingCheck: QuoteSubscribeModel.definition.dataProcessingCheck,
  intermediaryMandateCheck: QuoteSubscribeModel.definition.intermediaryMandateCheck,
  emailCommunicationCheck: QuoteSubscribeModel.definition.emailCommunicationCheck
}

module.exports = {
  quoteValidation: new Model(quoteDefinition),
  subscribeStep1Validation: new Model(subscribeRecapDefinition),
  // subscribeStep2Validation: new Model(subscribeStepToFollowDefinition),
  subscribeStep3Validation: new Model(subscribePersonalDetailsDefinition),
  subscribeStep3BisValidation: new Model(subscribePersonalDetailsBisDefinition),
  subscribeStep4Validation: new Model(subscribeLoanDetailsDefinition),
  subscribeStep5Validation: new Model(subscribeGoodHealthDeclarationDefinition),
  subscibeStep6Validation: new Model(subscribeMedicalDeclarationDefinition),
  subscibeStep7Validation: new Model(subscribeLastStepDefinition)
}
