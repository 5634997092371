import axios from 'axios'
import { t, getCurrentLocale } from './helpers'
import { Modal } from 'antd'

export const getCall = (url, data) => {
  return new Promise((resolve, reject) => {
    axios.get(url, data, {
      headers: {
        'Accept-Language': getCurrentLocale()
      }
    }).then(res => {
      return resolve(res.data)
    }).catch(err => {
      generateError(err)
      return reject(err)
    })
  })
}

export const postCall = (url, data) => {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: {
        'Accept-Language': getCurrentLocale()
      }
    }).then(res => {
      return resolve(res.data)
    }).catch(err => {
      generateError(err)
      return reject(err)
    })
  })
}

const generateError = (error) => {
  const status = null || error.response?.status || error.request?.status
  if (status === 0) {
    Modal.error({
      title: t(`errors.status.0.title`),
      content: t(`errors.status.0.text`)
    })
  }
  else if (status >= 400) {
    Modal.error({
      title: t(`errors.status.400.title`),
      content: t(`errors.status.400.text`)
    })
  }
  else if (status >= 500) {
    Modal.error({
      title: t(`errors.status.500.title`),
      content: t(`errors.status.500.text`)
    })
  }
}
