import React, { useState, useEffect } from 'react'
import * as QuoteSaveModel from 'simplomus.contracts/QuoteSaveModel'
import { Checkbox, Form, Button, Input, Modal, message } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { postCall } from './../../../utils/ApiCall'
import { t, MarkupEscape } from './../../../utils/helpers'
import ErrorMessage from './../common/ErrorMessage'
import { useTrackerContext } from './../../../context/TrackerContext'

const SendQuoteByEmailForm = ({ values, offers }) => {
  const { trackEvent } = useTrackerContext()
  const [showModal, setShowModal] = useState(false)
  const [hasConsent, setHasConsent] = useState(false)
  const [loading, setLoading] = useState(false)
  const defaultValues = {
    firstName: '',
    firstName2: '',
    lastName: '',
    lastName2: '',
    email: '',
    dataProcessingCheck: false
  }

  const { register, setValue, handleSubmit, errors, setError, reset, watch, control } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    validationSchema: QuoteSaveModel,
    defaultValues: defaultValues
  })

  const needsAdditionalFields = values.additionalInsuree
  const formValues = watch()

  const onSubmit = data => {
    if (!loading) {
      setLoading(true)
      const dataObj = {
        offerId: offers.offerId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        additionalInsuree: needsAdditionalFields,
        dataProcessingCheck: data.dataProcessingCheck
      }

      if (needsAdditionalFields) {
        dataObj.firstName2 = data.firstName2
        dataObj.lastName2 = data.lastName2
      }
      postCall(`${process.env.REACT_APP_API_URL}/quote/save`, dataObj)
        .then(() => {
          message.success(t('modals.sendByEmail.notifications.success'))
          trackEvent('Offer requested')
          setTimeout(() => {
            handleModalToggle(false)
          }, 1000)
        }).catch(error => {
          if (error.response.status === 400) {
            if (error.response.data.validationState !== undefined && error.response.data.validationState !== null) {
              Object.entries(error.response.data.validationState).forEach(([key, value]) => {
                setError(key, value.message)
              })
            }
          }
        }).finally(() => {
          setLoading(false)
        })
    }
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleModalToggle = (action) => {
    if (action === 'show' || action === true) {
      setShowModal(true)
    } else if (action === 'hide' || action === false) {
      reset()
      setShowModal(false)
    }
  }

  useEffect(() => {
    // Set validation dependency fields
    register({ name: 'offerId' })
    setValue('offerId', offers.offerId, false)
  }, [
    offers.offerId,
    register,
    setValue
  ])

  useEffect(() => {
    setHasConsent(formValues.dataProcessingCheck)
  }, [formValues.dataProcessingCheck])

  const generateField = (propertyName, customOptions = {}) => {
    const options = {
      label: customOptions.label || true,
      type: customOptions.type || 'text'
    }

    return (
      <>
        <Form.Item label={options.label ? t(`forms.fields.${propertyName}.label`) : null}>
          <Controller
            as={Input}
            defaultValue={defaultValues[propertyName]}
            value={formValues[propertyName]}
            name={propertyName}
            control={control}
            type={options.type}
            required
          />
          <ErrorMessage className='mt-3' errors={errors} name={propertyName} />
        </Form.Item>
      </>
    )
  }

  const generateCheckboxField = (propertyName) => {
    return (
      <>
        <Form.Item>
          <Controller
            as={Checkbox}
            defaultValue={defaultValues[propertyName]}
            value={formValues[propertyName]}
            name={propertyName}
            control={control}
            onChange={([e]) => {
              return e.target.checked
            }}
            required
          >
            {MarkupEscape(`forms.fields.${propertyName}.label`)}
          </Controller>
          <ErrorMessage errors={errors} name={propertyName} />
        </Form.Item>
      </>
    )
  }

  return (
    <>
      <Button className='ml-auto' type='primary' onClick={handleShowModal}>{t('quote.sendByEmail')}</Button>
      <Modal
        title={t('modals.sendByEmail.title')}
        visible={showModal}
        footer={null}
        onCancel={() => handleModalToggle('hide')}
      >
        <form className='SendQuoteForm' onSubmit={handleSubmit(onSubmit)}>
          {generateField('firstName')}
          {generateField('lastName')}
          {generateField('email', { type: 'email' })}
          {needsAdditionalFields && (
            <>
              {generateField('firstName2')}
              {generateField('lastName2')}
            </>
          )}
          {generateCheckboxField('dataProcessingCheck')}
          <Button className='mt-4' type='primary' htmlType='submit' disabled={!hasConsent || loading} loading={loading}>{!loading ? t('modals.sendByEmail.form.button.submit') : t('modals.sendByEmail.form.button.submitting')}</Button>
        </form>
      </Modal>
    </>
  )
}

export default SendQuoteByEmailForm
