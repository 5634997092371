import React, { useState, useEffect } from 'react'
import { Button, Typography, Row, Col, Switch, Icon, Spin } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import { t, MarkupEscape, getCurrentLocale } from './../../../utils/helpers'
import ErrorMessage from './../common/ErrorMessage'
import { useSubscribeFormContext } from '../../../context/SubscribeContext'
import { generateStepData } from '../../../utils/subscribeFieldsModel'
const validationSchema = require('./../../../utils/validation')

const SubscriptionRecap = ({ offer, plan, handleNextStep }) => {
  const { subscribeFormData, setSubscribeFormData } = useSubscribeFormContext()
  const { Title } = Typography
  const [loading, setLoading] = useState(true)
  const [hasConsent, setHasConsent] = useState(false)
  const defaultValues = generateStepData('recap', subscribeFormData)
  const icon = <Icon type='loading' style={{ fontSize: 24 }} spin />
  const currentLocale = getCurrentLocale()

  const stepValidationSchema = validationSchema.subscribeStep1Validation

  const { watch, control, setValue, handleSubmit, errors } = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    validationSchema: stepValidationSchema,
    defaultValues: defaultValues
  })

  const values = watch({ nest: true })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      setLoading(true)
    }
  }, [])

  const toggle = (field, value = null, event = null) => {
    const newValue = value !== null ? value : !values[field]

    if (event && event.target.tagName !== 'A') {
      setValue(field, newValue, false)
    }
  }

  const onSubmit = (data) => {
    setSubscribeFormData({
      ...subscribeFormData,
      ...data
    })
    handleNextStep()
  }

  useEffect(() => {
    if (!Object.values(values).includes(false)) {
      setHasConsent(true)
    } else {
      setHasConsent(false)
    }
  }, [values])

  const generateSwitch = (propertyName, options = null) => {
    if (!options) {
      options = {
        customLabel: null
      }
    }
    return (
      <Row className='mb-4' type='flex' align='middle'>
        <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            as={Switch}
            name={propertyName}
            control={control}
            defaultChecked={defaultValues[propertyName]}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            valueName='checked'
          />
          <p className='mb-0 ml-3 primary text-small' onClick={(e) => toggle(propertyName, null, e)} style={{ cursor: 'pointer' }}>{options.customLabel ? options.customLabel : MarkupEscape(`forms.steps.subscribe.recap.sections.disclaimer.${propertyName}`)}</p>
        </Col>
        <ErrorMessage className='mb-1' errors={errors} name={propertyName} />
      </Row>
    )
  }

  return (
    <form className='SubscribeRecapForm' onSubmit={handleSubmit(onSubmit)}>
      <div className='frame-content'>
        {loading ? (
          <div className='container loader-wrapper'>
            <Row type='flex' justify='center' align='middle'>
              <Spin indicator={icon} tip={t('global.loading')} />
            </Row>
          </div>
        ) : (
            <>
              <div className='frame-section'>
                <Title level={3}>{t('forms.steps.subscribe.recap.sections.aboutMortgage')}</Title>
                <Row className='mt-4' type='flex' align='middle'>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.capital.label')}</h6>
                    <p>{offer.recap.capital} &euro;</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.interestRate.label')}</h6>
                    <p>{offer.recap.interestRate}%</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.duration.label')}</h6>
                    <p>{offer.recap.durationInYears} {t('global.year', { count: offer.recap.durationInYears })}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.interval.label')}</h6>
                    <p>{t(`forms.fields.interval.options.${offer.recap.interval}`)}</p>
                  </Col>
                </Row>
              </div>

              <div className='frame-section'>
                <Title level={3}>{t('forms.steps.subscribe.recap.sections.aboutFirstInsuree')}</Title>
                <Row className='mt-4' type='flex' align='middle'>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.dateOfBirth.label')}</h6>
                    <p>{moment(offer.recap.dateOfBirth).format('DD/MM/YYYY')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.isSmoker.label')}</h6>
                    {offer.recap.isSmoker ? (
                      <p>{t('global.yes')}</p>
                    ) : (
                        <p>{t('global.no')}</p>
                      )}
                  </Col>
                </Row>
                {offer.recap.additionalInsuree && (
                  <div className='mt-4'>
                    <Title level={3}>{t('forms.steps.subscribe.recap.sections.aboutSecondInsuree')}</Title>
                    <Row type='flex' align='middle'>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h6>{t('forms.fields.dateOfBirth.label')}</h6>
                        <p>{moment(offer.recap.dateOfBirth2).format('DD/MM/YYYY')}</p>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h6>{t('forms.fields.isSmoker.label')}</h6>
                        {offer.recap.isSmoker2 ? (
                          <p>{t('global.yes')}</p>
                        ) : (
                            <p>{t('global.no')}</p>
                          )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>

              <div className='frame-section'>
                <Title level={3}>{t('forms.steps.subscribe.recap.sections.aboutInsurance')}</Title>
                <Row className='mt-4' type='flex' align='middle'>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.insuranceCompany.label')}</h6>
                    <p>{t('forms.fields.insuranceCompany.value')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.broker.label')}</h6>
                    <p>{t('forms.fields.broker.value')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.product.label')}</h6>
                    <p>{offer.recap.product}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.guarantee.label')}</h6>
                    <p>{t('forms.fields.guarantee.value')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.premiumType.label')}</h6>
                    <p>{t('forms.fields.premiumType.value')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.contractDuration.label')}</h6>
                    <p>{offer.recap.durationInYears} {t('global.year', { count: offer.recap.durationInYears }).toLowerCase()}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.insuredDebtBalanceCapital.label')}</h6>
                    <p>{offer.recap.capital} &euro;</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.premium.label')}</h6>
                    <p>{t(`plans.${plan}.price`, { price: offer.offers.filter(offer => offer.label === plan)[0].premium }).replace(/(<([^>]+)>)/ig, '')}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.premiumPaymentInterval.label')}</h6>
                    <p>{t(`plans.intervals.${plan}`)}</p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>{t('forms.fields.premiumPaymentDuration.label')}</h6>
                    <p>{offer.recap.paymentDuration} {t('global.year', { count: offer.recap.paymentDuration }).toLowerCase()}</p>
                  </Col>
                </Row>
              </div>

              <div className='frame-section'>
                <Title className='mb-4 ' level={4}>{t('forms.steps.subscribe.recap.sections.disclaimer.title')}</Title>
                {generateSwitch('dataVerifiedConsent')}
                {generateSwitch('confirmedIpid', {
                  customLabel: MarkupEscape(`forms.steps.subscribe.recap.sections.disclaimer.confirmedIpid`, { fileUrl: `${process.env.PUBLIC_URL}/pdf/simplomus_ipid_${currentLocale || 'en'}.pdf` })
                })}
              </div>
            </>
          )}
      </div>
      {!loading && (
        <div className='frame-footer'>
          <Button type='primary' htmlType='submit' disabled={!hasConsent}>{t('global.next')}</Button>
        </div>
      )}
    </form>
  )
}

export default SubscriptionRecap
