import * as auth from '../utils/AuthenticationUtil'
import React from 'react'
import { Route } from 'react-router-dom'
import PrivateLayout from '../components/backend/common/AppLayout'

/*

        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URL + `?returnUrl=${window.location.pathname}`}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE_URL}
        responseType='id_token token'
        onRedirectCallback={onRedirectCallback}
*/

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  if (!auth.isAuthenticated()) {
    auth.signIn()
    return (<></>)
  }

  const render = props => (<PrivateLayout><Component {...props} /></PrivateLayout>)

  return (
    <Route path={path} render={render} {...rest} />
  )
}

export default PrivateRoute
