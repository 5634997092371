import React from 'react'
import { Row, Col, Typography, Card } from 'antd'
import { t } from './../../../utils/helpers'

function AdminDashboardPage () {
  const { Title } = Typography
  const data = null

  return (
    <section>
      <div className='container'>
        <Title>{t('admin.dashboard.title')}</Title>

        {data
          ? (
            <>
              <Row className='mt-5'>
                <Col xs={12} md={6}>
                  <Card title='Card title' bordered={false}>
                    <p>Card content</p>
                    <p>Card content</p>
                    <p>Card content</p>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card title='Card title' bordered={false}>
                    <p>Card content</p>
                    <p>Card content</p>
                    <p>Card content</p>
                  </Card>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col span={12}>
                  <Card title='Card title' bordered={false}>
                    <p>Card content</p>
                    <p>Card content</p>
                    <p>Card content</p>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Title level={4}>{t('admin.dashboard.noContent')}</Title>
          )}
      </div>
    </section>
  )
}

export default AdminDashboardPage
